import { Link, useLocation } from "react-router-dom";
import { checkToken, fileUrl, language, useFETCH } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import Pagination from "../../Tools/Pagination";
import { MembersCard, TitlePage } from "../../components";
import { Ads } from "../../layout/SideBarUser/SideBarUser";
import React from "react";
import NotFound from "../../components/NotFound/NotFound";
import Loading from "../../Tools/Loading";
import { content } from "../../Context/translate";
import { FaArrowRightLong, FaArrowLeft } from "../../Context/exportIcons";
import { avatars } from "../../Context/lists";

function SearchUser() {
  const { search } = useLocation();

  const { data, isLoading } = useFETCH(`v1/users${search}`);

  if (isLoading) return <Loading />;

  return (
    <Col md={11} className="mx-auto mt-3">
      <div className="flex justify-between items-center">
        <TitlePage title="SearchResults" desc={data?.data?.data?.total} />
        <Link
          to="/search/"
          className="text-lg flex items-center gap-1 text-Secondary hover:underline">
          {language === "ar" ? (
            <FaArrowRightLong
              size={20}
              className="hover:translate-x-2 transition-all duration-200"
            />
          ) : (
            <FaArrowLeft
              size={20}
              className="hover:-translate-x-2 transition-all duration-200"
            />
          )}

          {content.GoBack}
        </Link>
      </div>
      {data?.data?.data?.total === 0 ? (
        <NotFound text={content.NoUsers} />
      ) : (
        <div className="py-4 min-h-[50vh]">
          <>
            <Pagination
              pageCount={Math.ceil(
                data?.data?.data?.total / data?.data?.data?.per_page
              )}>
              <Row>
                {(!data?.data?.data?.total
                  ? [1, 2, 3, 4, 5, 6]
                  : data?.data?.data?.data
                )?.map((e, i) => (
                  <React.Fragment key={i}>
                    <Col md={6} col={12} key={e.id} className="!p-3 mx-auto">
                      <React.Fragment>
                        <MembersCard
                          isActive={e?.is_active}
                          isOnline={e?.is_online}
                          id={e?.login_details?.id}
                          image={
                            e?.images?.length > 0
                              ? fileUrl + e.images[e?.images?.length - 1].image
                              : e?.avatar_id
                              ? avatars.filter(
                                  (element) => element.id === e?.avatar_id
                                )[0]?.img
                              : ""
                          }
                          isCaring={e?.is_caring}
                          name={e?.login_details?.username}
                          age={e.social_status?.age}
                          status={e.social_status?.social_status?.name}
                          city={e.nationality_residence?.city?.name}
                          job={e.study_work?.job?.name}
                          country={e?.nationality_residence?.country?.name}
                        />
                      </React.Fragment>
                    </Col>
                    {(data?.data?.data?.length < 2 || i === 1) && (
                      <Ads posistion="between_users" className="h-[50vh]" />
                    )}
                  </React.Fragment>
                ))}
              </Row>
            </Pagination>
          </>
        </div>
      )}
    </Col>
  );
}
export default SearchUser;
