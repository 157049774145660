import { content } from "../../Context/translate";
import { language, useFETCH } from "../../Tools/APIs";
import { Container } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { PrintHTML } from "../../components";

function PrivacyPolicy() {
  const { data, isLoading } = useFETCH("v1/info/all");

  const privacyContentArabic = data?.data?.data?.privacy_policy_ar;
  const privacyContentEnglish = data?.data?.data?.privacy_policy_en;

  if (isLoading) return <Loading />;

  return (
    <div>
      <Container>
        <div className="flex justify-center">
          <h1 className="text-Main font-bold text-3xl">
            {content.PrivacyPolicy}
          </h1>
        </div>
        <PrintHTML
          text={
            language === "ar" ? privacyContentArabic : privacyContentEnglish
          }
        />
      </Container>
    </div>
  );
}
export default PrivacyPolicy;
