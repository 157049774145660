import { Link } from "react-router-dom";
import { useContextHook } from "../../Context/ContextOPen";
import { language } from "../../Tools/APIs";
import { useContextChat } from "../../Context/ContextChat";

function Notification({
  title,
  text,
  features,
  time,
  children,
  userId,
  description,
  hasRead,
  state,
}) {
  const { setShowPopUpUser, setShowBottomSheet, setMessageActivate } =
    useContextHook();

  const { isActive } = useContextChat();

  return (
    <div
      className="cursor-pointer"
      onClick={() =>
        isActive
          ? state === 5
            ? setShowBottomSheet([userId, true])
            : setShowPopUpUser([userId, true])
          : setMessageActivate(true)
      }>
      <div
        className={` max-md:flex-col border-b pb-2 pr-5 pl-5 pt-2 mt-1 ${
          !hasRead ? "bg-blue-100" : ""
        }`}>
        <div
          className={`flex flex-row-reverse justify-between items-center gap-3`}>
          {children}
          <div className="">
            <p className=" font-semibold">
              {title}
              <span className="sm:text-lg font-mono pr-3">{features}</span>
            </p>
            {description && (
              <p className="font-medium text-sm">{description}</p>
            )}
            <p className={`text-gray-400 text-sm `}>{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Notification;
