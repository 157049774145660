import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useContextHook } from "../../Context/ContextOPen";
import { useContextChat } from "../../Context/ContextChat";
export const baseUrl = "https://backend.qiranbook.com/api/";
export const fileUrl = "https://backend.qiranbook.com/storage/";
// export const baseUrl = "https://k-book.icrcompany.net/api/";
// export const fileUrl = "https://k-book.icrcompany.net/storage/";

export const gender = sessionStorage.getItem("gender");
export const browserName = navigator.appName;
export const browserVersion = navigator.appVersion;
export const language = localStorage.getItem("language") || "en";
export let checkToken = localStorage.getItem("token");
export const checkLanguageEn = language === "en";
export let direction = { direction: language === "ar" ? "rtl" : "ltr" };
export let ltrDirection = { direction: "ltr" };

//!=============================================================> post data login
export const useLOGIN = (initialState) => {
  const [formData, setFormData] = useState(initialState); // data
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const { setListError } = useContextHook();
  const handleSubmit = async (url) => {
    setLoading(true);
    setError("");
    const formDataToSend = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      formDataToSend.append(key, value);
    }
    axios
      .post(`${baseUrl}${url}`, formDataToSend, {
        headers: {
          Authorization: "Bearer " + checkToken,
          finger_print: browserName + " " + browserVersion,
          locale: language,
        },
      })
      .then((req) => {
        setLoading(false);
        setError("");
        localStorage.setItem("token", req?.data?.data?.token);
        // navigate("/");
        window.location.reload(false);
      })
      .catch((e) => {
        setError(e.response?.data?.message);
        setListError([...e.response?.data?.errors]);
        setLoading(false);
      });
  };
  //
  return {
    formData,
    handleChange,
    loading,
    error,
    handleSubmit,
    setFormData,
    setError,
  };
};

// !======================================================================================
export const usePOST = (initialState, isJson) => {
  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [viewFile, setViewFile] = useState("");
  const [viewImages, setViewImages] = useState([]);
  const [images, setImages] = useState({});
  const [checkArray, setCheckArray] = useState({});
  const [successfulPost, setSuccessfulPost] = useState(0);
  const [counter, setCounter] = useState(0);
  const naviget = useNavigate();
  //=====================================================> function change   data all
  const handleChangeInput = (event) => {
      const { name, value, type, files, checked } = event.target;
      const newValue =
        type === "file" ? files[0] : type === "checkbox" ? checked : value;
      type === "file" && setViewFile(URL.createObjectURL(newValue));
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: newValue === true ? 1 : newValue === false ? 0 : newValue,
      }));
  };
  const handleChangeSelect = (selected, active) => {
    setFormData({
      ...formData,
      ["option_" + active.name]: selected,
      [active.name]: Array.isArray(selected)
        ? selected.map((e) => e.value)
        : selected.value,
    });
  };
  //=====================================================>  function change  array images
  const handleChangeArrayImages = (event) => {
    const files = event.target.files;
    const newFilesObject = {};

    // Generate unique keys for new files
    const currentImagesCount = Object.keys(images).length;
    for (let i = 0; i < files.length; i++) {
      newFilesObject[`images[${currentImagesCount + i}]`] = files[i];
    }

    setImages((prevImages) => ({
      ...prevImages,
      ...newFilesObject,
    }));

    const urlImages = [];
    for (let i = 0; i < files.length; i++) {
      if (!files[i]) continue;
      urlImages.push({
        name: files[i].name,
        value: URL.createObjectURL(files[i]),
      });
    }

    setViewImages((prev) => [...prev, ...urlImages]);

    event.target.value = "";
  };

  //=====================================================> function change array checkbox
  const handleCheckedArray = (e) => {
    const { value, checked, name } = e.target;
    if (checked) {
      setCheckArray({ ...checkArray, [name]: [value] });
      if (checkArray[name]) {
        setCheckArray({
          ...checkArray,
          [name]: [...checkArray[name], value],
        });
      }
    } else {
      if (checkArray[name]) {
        setCheckArray({
          ...checkArray,
          [name]: checkArray[name].filter((p) => p !== value),
        });
      }
    }
  };
  const { showMessagesSign } = useContextHook();
  const { setListError } = useContextHook();
  //=====================================================> post data
  const handleSubmit = async (url, reload, route, noRoute) => {
    setSuccessfulPost(0);
    setLoading(true);
    setError("");
    const formDataToSend = new FormData();
    for (const [key, value] of Object.entries(formData || {})) {
      if (key.substring(0, 7) !== "option_") {
        formDataToSend.append(key, value);
      }
    }
    for (const [key, value] of Object.entries(checkArray)) {
      for (let i = 0; i < value.length; i++) {
        formDataToSend.append(key, value[i]);
      }
    }

    for (const [key, value] of Object.entries(images)) {
      formDataToSend.append(key, value);
    }

    axios
      .post(`${baseUrl}${url}`, isJson ? formData : formDataToSend, {
        headers: {
          Authorization: "Bearer " + checkToken,
          finger_print: browserName + " " + browserVersion,
          locale: language,
        },
      })
      .then((req) => {
        setSuccessfulPost(1);
        setLoading(false);
        setError("");
        route
          ? naviget(route)
          : noRoute
          ? console.log("D")
          : reload
          ? window.location.reload()
          : window.history.go(-1);
      })
      .catch((e) => {
        setError(e.response?.data?.message);
        setListError([...e.response?.data?.errors]);
        setLoading(false);
        if (e.request.status === 401) {
          showMessagesSign();
          localStorage.clear();
        }
        if (e.request.status === 403) {
          naviget("/403");
        }
        if (e.request.status === 500) {
          naviget("/500");
        }
      });
  };

  return {
    formData,
    setFormData,
    viewFile, //  لعرض الصورة او فيديو .....
    setViewFile, // ((local)) لحذف الصورة
    viewImages, // لعرض الصور
    setViewImages, // ((local)) لحذف الصور
    images, //api  لأرسال الصور الى
    setImages, //api  لحذف الصور الى
    error,
    setError,
    loading,
    setLoading,
    handleChangeInput,
    handleChangeSelect,
    handleCheckedArray,
    handleChangeArrayImages,
    handleSubmit,
    successfulPost,
    counter,
    setCounter,
  };
};
export const logout = (url) => {
  axios
    .post(
      `${baseUrl}${url}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + checkToken,
          finger_print: browserName + " " + browserVersion,
          locale: language,
        },
      }
    )
    .then((req) => {
      if (url.includes("ads")) return;
      localStorage.removeItem("token");
      window.location.reload();
    })
    .catch((e) => {});
};

//!=============================================================>start fetch data

export const useFETCH = (
  url,
  urlDelete,
  reload,
  checkDelMessage,
  e403,
  refresh = 1,
  isPolling = false
) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [successfulDelete, setSuccessfulDelete] = useState(0);
  const [isError, setIsError] = useState("");
  const [successfulDeleteIgnore, setsuccessfulDeleteIgnore] = useState(0);
  const [successfulDeleteCare, setsuccessfulDeleteCare] = useState(0);
  const naviget = useNavigate();
  const { showMessagesSign, setListError } = useContextHook();
  const { setMessageDelete } = useContextChat();

  useEffect(() => {
    if (!isPolling) {
      setIsLoading(true);
    }
    if (url) {
      axios
        .get(`${baseUrl}${url}`, {
          headers: {
            Authorization: "Bearer " + checkToken,
            finger_print: browserName + " " + browserVersion,
            locale: language,
          },
        })
        .then((req) => {
          if (req) {
            setData(req);
            setIsLoading(false);
            setIsError("");
          }
        })
        .catch((e) => {
          setIsLoading(false);
          setIsError("خطأ في جلب البيانات");
          // setListError([...e.response.data.errors]);
          if (e.request.status === 401) {
            localStorage.removeItem("token");
            if (reload != -1) {
              showMessagesSign();
              window.location.reload(false);
            }
          }
          if (e.request.status === 403 && e403) {
            naviget("/chat/err/403");
          }
        });
    } else setIsLoading(false);
  }, [url, successfulDelete, refresh]);

  //?=============================================================> deleteItem

  const deleteCare = async (id) => {
    setIsLoading(true);
    setsuccessfulDeleteCare(0);
    axios
      .delete(`${baseUrl}${`v1/care/${id}/delete`}`, {
        headers: {
          Authorization: "Bearer " + checkToken,
          finger_print: browserName + " " + browserVersion,
          locale: language,
        },
      })
      .then((req) => {
        setIsLoading(false);
        setsuccessfulDeleteCare(id);
      })
      .catch((e) => {
        setIsLoading(false);
        setListError([...e.response?.data?.errors]);
        setIsError(" خطأ في الحظر");
      });
  };

  const deleteIgnore = async (id) => {
    setIsLoading(true);
    setsuccessfulDeleteIgnore(0);
    axios
      .delete(`${baseUrl}${`v1/ignore/${id}/delete`}`, {
        headers: {
          Authorization: "Bearer " + checkToken,
          finger_print: browserName + " " + browserVersion,
          locale: language,
        },
      })
      .then((req) => {
        setIsLoading(false);
        setsuccessfulDeleteIgnore(id);
      })
      .catch((e) => {
        setIsLoading(false);
        setListError([...e.response?.data?.errors]);
        setIsError(" خطأ في الحظر");
      });
  };

  const deleteItem = async (id, toDeleteUrl) => {
    setIsLoading(true);
    setSuccessfulDelete(0);
    axios
      .delete(`${baseUrl}${toDeleteUrl}`, {
        headers: {
          Authorization: "Bearer " + checkToken,
          finger_print: browserName + " " + browserVersion,
          locale: language,
        },
      })
      .then((req) => {
        setIsLoading(false);
        setIsError("تم الحذف  بنجاح");
        if (checkDelMessage) {
          setMessageDelete(id);
        } else if (reload) {
          window.location.reload(false);
        } else {
          setSuccessfulDelete(id);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setListError([...e.response?.data?.errors]);
        setIsError(" خطأ في الحذف  حاول مرة آخرى");
      });
  };

  return {
    data,
    isLoading,
    isError,
    deleteItem,
    successfulDelete,
    setSuccessfulDelete,
    deleteIgnore,
    successfulDeleteIgnore,
    deleteCare,
    successfulDeleteCare,
    setData,
    setIsLoading,
  };
};
//=============================================================> end fetch data

//!=============================================================> pagination or filter or search
export const useFilter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const handleParamsClick = (name, value) => {
    if (value == "") {
      handleParamsDelete(name);
    } else {
      searchParams.set(name, value);
      const newUrl = `${location.pathname}?${searchParams.toString()}`;
      navigate(newUrl);
    }
  };
  const handleParamsDelete = (name) => {
    searchParams.delete(name);
    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    navigate(newUrl);
  };
  const handleParamsDeleteAll = (name) => {
    const newUrl = `${location.pathname}`;
    navigate(newUrl);
  };
  const handlePageClick = (event) => {
    handleParamsClick("page", event.selected + 1);
  };
  return {
    searchParams,
    handlePageClick,
    handleParamsClick,
    handleParamsDelete,
    handleParamsDeleteAll,
  };
};
//?=============================================================> close items
export const useClose = () => {
  const [open, setOpen] = useState(false);
  const mouse = useRef();
  useEffect(() => {
    const handler = (e) => {
      if (mouse.current) {
        if (!mouse.current.contains(e.target)) {
          setOpen(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.addEventListener("mousedown", handler);
    };
  }, []);
  return { open, setOpen, mouse };
};
//!=============================================================> عدم دخول من غير تسجيل دخول
export const RequireAuth = () => {
  if (!checkToken) {
    return <Navigate to="/auth/sign-in" />;
  }

  return <Outlet />;
};
//! =============================================================>عدم فتح صفحة تسجيل دخول اذا مسجل مسيقا
export const RequireLogin = () => {
  const { pathname } = useLocation();

  if (checkToken && !pathname.includes("/admin/sign-in/")) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};
//!=============================================================> تكبير الصورة
export const clickZoomInImage = (event) => {
  event.target.requestFullscreen();
};
