import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl, browserName, browserVersion, checkToken, language } from "../Tools/APIs";

const useCitiesData = (name, ids) => {
  const [optionsCities, setOptionsCities] = useState([]);
  useEffect(() => {
    setOptionsCities([]);
    ids?.forEach((e) => {
      axios
        .get(`${baseUrl}v1/lists/${name}?type=*&parent_item_id=${e}`, {
          headers: {
            Authorization: "Bearer " + checkToken,
            finger_print: browserName + " " + browserVersion,
            locale: language,
          },
        })
        .then((req) => {
          if (req) {
            req?.data?.data?.items.map((ep) =>
              setOptionsCities((prevOptionsCities) => [
                ...prevOptionsCities,
                ep,
              ])
            );
          }
        });
    });
  }, [ids]);
  return { optionsCities };
};

export default useCitiesData;
