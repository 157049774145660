import { useEffect } from "react";
import { useContextChat } from "../../Context/ContextChat";
import { useContextHook } from "../../Context/ContextOPen";
import * as icons from "../../Context/exportIcons";
import { ageVoid, childrenVoid } from "../../Context/functions";
import { avatars } from "../../Context/lists";
import { content } from "../../Context/translate";
import { checkToken, fileUrl, language, useFETCH } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Ads } from "../../layout/SideBarUser/SideBarUser";
import DataCol from "../Data/DataCol";
import Heart from "../Heart/Heart";
import ImageOff from "../Heart/ImageOff";
import ImageUser from "../ImageUser/ImageUser";
import Forbidden from "./Forbidden";
import PopUpNoUser from "./PopUpNoUser";

function ProfilePopUp() {
  const {
    showPopUpUser,
    setShowPopUpUser,
    showMessagesSign,
    setShowBottomSheet,
  } = useContextHook();

  const { setIsIgnoring, setIsCaring, isActive } = useContextChat();

  const { data, isLoading } = useFETCH(
    showPopUpUser[1] ? "v1/users/show/" + showPopUpUser[0] : ""
  );
  const dataAll = data?.data?.data;

  useEffect(() => {
    setIsCaring(dataAll?.is_caring);
    setIsIgnoring(dataAll?.is_ignoring);
  }, [dataAll]);

  const { setShowUserImages, setMessageActivate } = useContextHook();

  const selectedAvatar = avatars.filter((e) => e.id === dataAll?.avatar_id)[0];

  return (
    <div>
      {showPopUpUser[1] && (
        <>
          <div
            onClick={() => setShowPopUpUser(["", false])}
            className={`${
              showPopUpUser[1] ? "" : "hidden"
            } fixed w-full h-full top-0 left-0 bg-black/60 z-[61] flex justify-center items-center overflow-x-auto`}></div>
          <div>
            <Col
              md={6}
              sm={11}
              className="fixed top-[55%] left-1/2 -translate-x-1/2 -translate-y-1/2 z-[62] bg-white rounded-xl">
              <div className="h-[80vh] bg-white p-3 chat overflow-y-scroll rounded-xl">
                {isLoading && <Loading />}
                {dataAll && !isLoading ? (
                  <>
                    <div
                      onClick={() => setShowPopUpUser(["", false])}
                      className={`w-fit sticky top-0 ${
                        language === "ar" ? "right-0" : "left-0"
                      }  z-30 text-left text-2xl font-semibold px-3 py-1 bg-gray-200 rounded-full hover:text-white cursor-pointer hover:bg-red-600 transition-all duration-300`}>
                      X
                    </div>
                    <div className="flex justify-between items-center max-md:flex-col max-md:gap-5 border-b pb-3 shadow-sm">
                      <div className="flex items-center gap-2 mx-8">
                        <ImageUser
                          image={
                            dataAll?.images && dataAll?.images?.length !== 0
                              ? fileUrl +
                                dataAll?.images[dataAll?.images.length - 1]
                                  .image
                              : dataAll?.avatar_id
                              ? selectedAvatar.img
                              : ""
                          }
                          id={dataAll?.login_details.id}
                          isOnline={dataAll?.isOnline}
                          onClick={() =>
                            dataAll?.images?.length !== 0 &&
                            setShowUserImages([
                              dataAll?.login_details?.id,
                              true,
                            ])
                          }
                          className="w-16 h-16 rounded-full cursor-pointer"
                          imgspa={true}
                        />
                        {/* <img
                          src={dataAll?.image ? fileUrl + dataAll?.image : ddd}
                          alt=""
                          className="w-16 h-16 rounded-full cursor-pointer"
                          onClick={dataAll?.image && clickZoomInImage}
                        /> */}
                        <div>
                          <p className="font-semibold mb-1">
                            {dataAll?.secret_data?.name}
                          </p>
                          <p className="font-semibold text-Main">
                            {ageVoid(dataAll?.social_status?.age)}
                            {" - "}
                            {dataAll?.social_status?.social_status?.name}
                            {" - "}
                            {dataAll?.gender === "FEMALE"
                              ? content.residingInFemale
                              : content.residingInMale}{" "}
                            {dataAll?.nationality_residence?.country?.name}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <Forbidden id={showPopUpUser[0]} />
                        <Heart
                          id={dataAll?.login_details.id}
                          check={dataAll?.is_caring}
                          className={`rounded-full w-10 h-10 hover:bg-Main`}
                        />
                        <ImageOff
                          check={dataAll?.has_profile_pic_privilege}
                          id={showPopUpUser[0]}
                          className="hover:bg-Main rounded-full w-10 h-10 flex items-center justify-center text-white"
                        />
                        <div className="bg-gradient-to-l from-Secondary to-Main rounded-full w-10 h-10 flex items-center justify-center text-white">
                          {checkToken ? (
                            <button
                              onClick={() => {
                                if (!isActive) setMessageActivate(true);
                                else {
                                  setShowBottomSheet([showPopUpUser[0], true]);
                                  setShowPopUpUser(["", false]);
                                }
                              }}>
                              <icons.BsChatText size={25} color="white" />
                            </button>
                          ) : (
                            <div onClick={() => showMessagesSign()}>
                              <icons.BsChatText size={25} color="white" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <Row
                      className="bg-Third !my-2 !mx-2 py-2 px-2 rounded-xl"
                      justify="between">
                      <Col col={5}>
                        <DataCol
                          text={dataAll?.last_active_at}
                          name={content.LastLoginDate}
                        />
                      </Col>
                      <Col col={5}>
                        <DataCol
                          text={dataAll?.registered_since}
                          name={content.RegistrationDate}
                        />
                      </Col>
                    </Row>
                    <div className="flex items-center gap-2 text-lg font-bold text-Main">
                      <icons.IoLocationSharp size={25} />
                      {content.HousingAndMaritalStatus}
                    </div>
                    <Row
                      justify={"between"}
                      gap={2}
                      className="bg-Third !my-2 !mx-2 py-2 px-2 rounded-xl">
                      <Col col={5}>
                        <DataCol
                          text={
                            dataAll?.nationality_residence?.nationality?.name
                          }
                          name={content.Nationality}
                        />
                      </Col>
                      <Col col={5}>
                        <DataCol
                          text={dataAll?.nationality_residence?.city?.name}
                          name={content.City}
                        />
                      </Col>
                      <Col col={5}>
                        <DataCol
                          text={
                            <>
                              {ageVoid(dataAll?.social_status?.age) +
                                " — " +
                                dataAll?.social_status?.social_status?.name}
                              <br />
                              {childrenVoid(+dataAll?.social_status?.children)}
                            </>
                          }
                          name={content.FamilyStatus}
                        />
                      </Col>
                      <Col col={5}>
                        <DataCol
                          text={dataAll?.social_status?.marriage_status?.name}
                          name={content.TypeOfMarriage}
                        />
                      </Col>
                      <Col col={5}>
                        <DataCol
                          text={
                            dataAll?.religious_commitment?.religiosity_status
                              ?.name
                          }
                          name={content.Religious}
                        />
                      </Col>
                      <Col col={5}>
                        <DataCol
                          text={
                            dataAll?.religious_commitment?.prayer_status?.name
                          }
                          name={content.Prayer}
                        />
                      </Col>
                    </Row>
                    <div className="flex items-center gap-2 text-lg font-bold text-Main">
                      <icons.IoMan size={25} />
                      {content.AppearanceAndHealth}
                    </div>
                    <Row
                      className="bg-Third !my-2 !mx-2 py-2 px-2 rounded-xl"
                      justify={"between"}>
                      <Col col={5}>
                        <DataCol
                          text={dataAll?.my_specifications?.skin_color?.name}
                          name={content.SkinColor}
                        />
                      </Col>
                      <Col col={5}>
                        <DataCol
                          text={`${dataAll?.my_specifications?.weight} ${content.kg} ${dataAll?.my_specifications?.height} ${content.cm} `}
                          name={content.HeightAndWeight}
                        />
                      </Col>
                      <Col col={5}>
                        <DataCol
                          text={
                            dataAll?.my_specifications?.physique_status?.name
                          }
                          name={content.Physique}
                        />
                      </Col>
                      <Col col={5}>
                        {dataAll?.gender === "FEMALE" ? (
                          <DataCol
                            text={
                              dataAll?.religious_commitment?.hijab_status?.name
                            }
                            name={content.Hijab}
                          />
                        ) : (
                          <DataCol
                            text={
                              dataAll?.religious_commitment?.beard_status?.name
                            }
                            name={content.Beard}
                          />
                        )}
                      </Col>
                      <Col col={5}>
                        <DataCol
                          text={dataAll?.study_work?.health_status?.name}
                          name={content.HealthStatus}
                        />
                      </Col>
                      <Col col={5}>
                        <DataCol
                          text={
                            dataAll?.religious_commitment?.smoking_status?.name
                          }
                          name={content.Smoking}
                        />
                      </Col>
                    </Row>
                    <div className="flex items-center gap-2 text-lg font-bold text-Main">
                      <icons.BsSuitcaseLg size={25} />
                      {content.EducationAndWork}
                    </div>
                    <Row
                      className="bg-Third !my-2 !mx-2 py-2 px-2 rounded-xl"
                      justify={"between"}>
                      <Col col={5}>
                        <DataCol
                          text={dataAll?.study_work?.educational_level?.name}
                          name={content.Educational}
                        />
                      </Col>
                      <Col col={5}>
                        <DataCol
                          text={dataAll?.study_work?.employment_status?.name}
                          name={content.Employment}
                        />
                      </Col>
                      <Col col={5}>
                        <DataCol
                          text={dataAll?.study_work?.job?.name}
                          name={content.Job}
                        />
                      </Col>
                      <Col col={5}>
                        <DataCol
                          text={dataAll?.study_work?.monthly_income_range?.name}
                          name={content.MonthlyIncome}
                        />
                      </Col>
                      <Col col={5}>
                        <DataCol
                          text={dataAll?.study_work?.financial_status?.name}
                          name={content.FinancialStatus}
                        />
                      </Col>
                    </Row>
                    <div className="flex items-center gap-2 text-lg font-bold text-Main">
                      <icons.BsFillExclamationCircleFill size={25} />
                      {content.MySpecifications}
                    </div>
                    <Row className="bg-Third !my-2 !mx-2 py-2 px-1 rounded-xl">
                      <div>{dataAll?.own_props}</div>
                    </Row>
                    <div className="flex items-center gap-2 text-lg font-bold text-Main">
                      <icons.BsFillExclamationCircleFill size={25} />
                      {content.SpecificationsOfMyLifePartner}
                    </div>
                    <Row className="bg-Third !my-2 !mx-2 py-2 px-1 rounded-xl">
                      <div>{dataAll?.future_wife_props}</div>
                    </Row>
                  </>
                ) : (
                  !isLoading && (
                    <div>
                      {showPopUpUser[0]} {dataAll}
                      <PopUpNoUser
                        title={content.NoUser}
                        onClickFalse={() => setShowPopUpUser(["", false])}
                      />
                    </div>
                  )
                )}
                {!isLoading && (
                  <div className="">
                    <Ads posistion="user_popup" className="h-[30vh]" />
                  </div>
                )}
              </div>
            </Col>
          </div>
        </>
      )}
    </div>
  );
}
export default ProfilePopUp;
