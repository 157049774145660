import { initializeApp } from "firebase/app";

import { getMessaging } from "firebase/messaging/sw";
const firebaseConfig = {
  apiKey: "AIzaSyAjdWcFg4B0n8X4fEslJ5eikpAbKoJpEZI",
  authDomain: "qran-book.firebaseapp.com",
  projectId: "qran-book",
  storageBucket: "qran-book.appspot.com",
  messagingSenderId: "973464917197",
  appId: "1:973464917197:web:4bf871e1627e6945f48584",
  measurementId: "G-C724Y2PP3N",
};

const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);
