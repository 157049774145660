import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

const SliderMUI = ({
  name,
  max,
  min,
  unit,
  title,
  value,
  onChange,
  className,
}) => {
  return (
    <Box className="flex items-center gap-3 mt-6">
      <div className={`px-3 text-Secondary font-semibold text-lg ${className}`}>
        {title}
      </div>
      <div className="mt-3 max-md:w-[80%] md:flex-1">
        <Slider
          valueLabelDisplay="on"
          valueLabelFormat={(value) => value + " " + unit}
          max={max}
          min={min}
          sx={{ color: "#d86aaa" }}
          value={value ? value : null}
          name={name}
          onChange={onChange}
        />
      </div>
    </Box>
  );
};

export default SliderMUI;
