import { useEffect, useState } from "react";
import { useFETCH } from "../Tools/APIs";
import { useLocation } from "react-router-dom";

const useItemsData = (name, id) => {
  const location = useLocation();

  // const { data } = useFetch(
  //   id !== "undefined" && id && name
  //     ? `v1/lists/${name}?type=*&parent_item_id=${id}`
  //     : ""
  // );
  const { data } = useFETCH(
    id !== "undefined" && id && name
      ? `v1/lists/${name}?type=*&parent_item_id=${id}`
      : ""
  );
  const [itemsData, setItemsData] = useState(null);

  useEffect(() => {
    setItemsData(data?.data?.data?.items);
  }, [data?.data]);
  return { itemsData };
};

export default useItemsData;
