import { Link } from "react-router-dom";
import { SectionSwiper } from "../../components";
import NotFound from "../../components/NotFound/NotFound";
import { content } from "../../Context/translate";
import { Col, Row } from "../../Tools/Grid-system";

function UsersSection({ data, title, isLoading, link, category }) {
  if (data?.length === 0 && !isLoading) return null;

  return (
    <Row className="relative min-h-[30vh] !mt-12">
      <Col sm={10} className="md:px-5 mx-auto">
        <div className="flex justify-between gap-4">
          <div className="flex justify-center gap-2">
            <div className="w-1 bg-Main h-full"></div>
            <h1 className="text-3xl max-sm:text-xl font-bold text-Secondary">
              {title}
            </h1>
          </div>
          <Link
            to={`${link}`}
            className="text-lg self-end max-sm:text-sm font-semibold hover:underline text-center hover:text-Main text-Secondary">
            {content.ViewAll}
          </Link>
        </div>

        {data?.length === 0 && !isLoading ? (
          <NotFound />
        ) : (
          <SectionSwiper
            data={data}
            category={category}
            isLoading={isLoading}
          />
        )}
        {/* <Swiper
          spaceBetween={30}
          slidesPerView={3}
          breakpoints={{
            1020: {
              slidesPerView: 3,
            },
            767: {
              slidesPerView: 2,
            },
            0: {
              slidesPerView: 1,
            },
          }}
          className="my-5 !bg-gray-100 rounded-2xl shadow-xl shadow-gray-200 !px-7">
          {(!data ? [1, 2, 3, 4] : data)?.map((e, i) => (
            <SwiperSlide key={i} className="!my-10 ">
              {!isLoading ? (
                <MembersCard
                  isOnline={e?.is_online}
                  id={e?.login_details?.id}
                  image={e.image ? fileUrl + e.image : ""}
                  check={e.is_caring?.cared_id}
                  name={e.secret_data?.name}
                  age={e.social_status?.age}
                  city={e.nationality_residence?.city?.name}
                  job={e.study_work?.job?.name}
                  status={
                    title === content.HealthConditions
                      ? e.study_work?.health_status?.name
                      : e.social_status?.social_status?.name
                  }
                  user={e}
                />
              ) : (
                <LoadingMembersCard />
              )}
            </SwiperSlide>
          ))}
        </Swiper> */}
      </Col>
    </Row>
  );
}
export default UsersSection;
