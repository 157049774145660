import { Ads } from "../../layout/SideBarUser/SideBarUser";

const Promotion = () => {
  return (
    <div>
      <Ads posistion="blog" className="!h-[50vh]" />
    </div>
  );
};

export default Promotion;
