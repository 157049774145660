import React, { useEffect, useState } from "react";
import { MdOutlineReportGmailerrorred } from "../../Context/exportIcons";
import { content, content as contentTrans } from "../../Context/translate";
import { language, usePOST } from "../../Tools/APIs";
import { ButtonMain, Cards, Input } from "../../components";
import { useContextHook } from "../../Context/ContextOPen";

const ReportMessage = ({ messageId, userId }) => {
  const { handleSubmit, setFormData, handleChangeInput, successfulPost } =
    usePOST();
  const [showReport, setShowReport] = useState(false);
  const { setMessagesSuccess } = useContextHook();

  useEffect(() => {
    setFormData({
      accused_id: userId,
      accused_message_id: messageId,
    });
  }, []);

  useEffect(() => {
    if (successfulPost) setMessagesSuccess([content.ReportSuccess]);
  }, [successfulPost]);

  return (
    <>
      <div
        onClick={() => setShowReport(true)}
        className={`hover:bg-Main hover:text-white pb-1 pt-2 flex items-center px-4 gap-1 ${
          language === "ar" ? "flex-row-reverse" : ""
        }`}>
        <div>
          <MdOutlineReportGmailerrorred />
        </div>
        <div>{contentTrans.Report}</div>
      </div>
      {showReport && (
        <>
          <div
            onClick={() => setShowReport(false)}
            className="fixed w-full h-full top-0 left-0 bg-black bg-opacity-50 z-40 "></div>
          <Cards className="!fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-10 z-50">
            <h1 className="text-2xl font-semibold text-center text-black">
              {content.reportMessage}
            </h1>
            <Input
              name="reason"
              onChange={handleChangeInput}
              title={content.ReasonForReporting}
            />
            <div className="flex justify-around gap-5 mt-10 ">
              <ButtonMain
                title={content.yes}
                onClick={() => {
                  handleSubmit("v1/reports", false, false, true);
                  setShowReport(false);
                }}
                className="!px-11"
              />
              <ButtonMain
                title={content.cancel}
                onClick={() => setShowReport(false)}
                className="!px-11 bg-Secondary"
              />
            </div>
          </Cards>
        </>
      )}
    </>
  );
};

export default ReportMessage;
