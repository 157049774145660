import React from "react";
import { useContextHook } from "../../Context/ContextOPen";
import {
  MdOutlineContentCopy,
  VscKebabVertical,
} from "../../Context/exportIcons";
import { content as contentTrans } from "../../Context/translate";
import { language, useClose } from "../../Tools/APIs";
import ReportMessage from "./ReportMessage";
const MessageRecipientOption = ({ content, messageId, userId }) => {
  const { setMessagesSuccess } = useContextHook();
  const { mouse, open, setOpen } = useClose();
  return (
    <>
      <div ref={mouse} className="relative ">
        <VscKebabVertical
          className="cursor-pointer"
          size={22}
          onClick={() => setOpen(!open)}
        />
        {open && (
          <div
            className={`absolute  overflow-hidden bottom-0 ${
              language === "ar" ? "left-3" : "right-3 "
            } rounded-xl  text-center bg-white text-black  border z-40 w-36`}>
            <div
              onClick={() => {
                navigator.clipboard.writeText(content).then(() => {
                  setMessagesSuccess([contentTrans.messageCopiedTheMessage]);
                  setOpen(false);
                });
              }}
              className={`hover:bg-Main hover:text-white pb-1 pt-2 flex items-center px-4 gap-1 ${
                language === "ar" ? "flex-row-reverse" : ""
              }`}>
              <div>
                <MdOutlineContentCopy />
              </div>
              <div>{contentTrans.Copy}</div>
            </div>

            <ReportMessage
              onClick={() => setOpen(false)}
              messageId={messageId}
              userId={userId}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default MessageRecipientOption;
