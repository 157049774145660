

function Cards({ children, className, onClick }) {
  return (
    <div>
      <div
        onClick={onClick}
        className={`bg-white rounded-2xl shadow-md p-2 py-5 md:p-6 shadow-gray-400 ${className} relative`}>
        {children}
      </div>
    </div>
  );
}
export default Cards;
