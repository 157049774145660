import React from "react";
import { NavLink } from "react-router-dom";

const NavSearch = ({ listNav, className }) => {
  return (
    <>
      <div className="flex  navSearch  transition-all rounded-full bg-white border overflow-hidden w-full shadow-md shadow-gray-400 mt-6">
        {listNav.map((e, i) => (
          <NavLink
            key={i}
            to={e.link}
            className={
              className +
              " md:px-8 max-md:flex-1 px-3 py-2 md:text-lg text-sm rounded-full max-sm:h-14 text-center flex justify-center items-center"
            }>
            {e.name}
          </NavLink>
        ))}
      </div>
    </>
  );
};

export default NavSearch;
