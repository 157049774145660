import { useContextHook } from "../../Context/ContextOPen";
import { LuImage, LuImageOff } from "../../Context/exportIcons";
import { content } from "../../Context/translate";
import { checkToken, usePOST } from "../../Tools/APIs";
import { useState } from "react";
const ImageOff = ({ id, check, className }) => {
  const { handleSubmit } = usePOST({});
  const [success, setSuccess] = useState(check);
  const { setMessagesSuccess, showMessagesSign } = useContextHook();
  return (
    <div className={`${success ? "bg-gray-400" : "bg-Main"} ${className}`}>
      {success ? (
        <LuImage
          color="white"
          size={25}
          className="cursor-pointer"
          onClick={() => {
            checkToken
              ? handleSubmit(
                  `v1/users/${id}/profile/hide/picture`,
                  "",
                  true
                ).then(() => {
                  setSuccess(false);
                  setMessagesSuccess([content.MessageBlockedPhoto]);
                })
              : showMessagesSign();
          }}
        />
      ) : (
        <LuImageOff
          size={25}
          color="white"
          className="cursor-pointer"
          onClick={() => {
            checkToken
              ? handleSubmit(
                  `v1/users/${id}/profile/show/picture`,
                  "",
                  true
                ).then(() => {
                  setSuccess(true);
                  setMessagesSuccess([content.MessageAllowedPhoto]);
                })
              : showMessagesSign();
          }}
        />
      )}
    </div>
  );
};

export default ImageOff;
