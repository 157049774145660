import { useState } from "react";
import { GoEye, GoEyeClosed } from "react-icons/go";

function Input({
  text,
  title,
  name,
  value,
  type,
  onChange,
  className,
  containerClass,
  onKeyDown,
  SignIn,
  isPassword,
}) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={`my-1 w-full ${containerClass}`}>
      <div
        className={`${
          SignIn
            ? "text-black text-base lg:mx-12 mt-6"
            : " text-Secondary text-lg font-bold"
        } `}>
        {text}
      </div>
      <div
        className={`${className} flex justify-between items-center border border-[#B3B3B3] focus:outline-Secondary rounded-md mt-2 ${
          isPassword && "px-2"
        }`}>
        <input
          onKeyDown={onKeyDown}
          type={
            isPassword ? (showPassword ? "text" : "password") : type || "text"
          }
          name={name}
          value={value}
          onChange={onChange}
          placeholder={title}
          className={`flex-1 py-4 placeholder:text-black placeholder:opacity-40 w-full h-full outline-none border-none rounded-md`}
        />
        {isPassword ? (
          showPassword ? (
            <GoEye
              className="cursor-pointer"
              onClick={() => setShowPassword(false)}
              size={20}
            />
          ) : (
            <GoEyeClosed
              className="cursor-pointer"
              onClick={() => setShowPassword(true)}
              size={20}
            />
          )
        ) : null}
      </div>
    </div>
  );
}
export default Input;
