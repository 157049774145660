import { useEffect } from "react";
import { Outlet } from "react-router-dom";

function SignUp() {
  
  return (
    <>
      <Outlet />
    </>
  );
}
export default SignUp;
