import { useEffect, useState } from "react";
import { useFETCH } from "../Tools/APIs";

const useAds = (posistion, triggerFetch) => {
  const { data } = useFETCH(posistion && triggerFetch && `v1/ads/${posistion}`);
  const [adsData, setAdsData] = useState(null);

  useEffect(() => {
    setAdsData(data?.data?.data);
  }, [data?.data]);

  return { adsData };
};

export default useAds;
// profile  √
// under_profile  √
// user_popup √
// home  √
// quick_username_search √
// between_users
// blog √
