import { useEffect } from "react";
import { useListPage } from "../../Context/listSignUp";
import { checkToken, useFETCH, usePOST } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";

import Select from "react-select";
import { useContextHook } from "../../Context/ContextOPen";
import { content } from "../../Context/translate";
import Loading from "../../Tools/Loading";
import { ButtonMain, Cards, Input, Selects, TextArea } from "../../components";
import { useNext } from "../Auth/SignUp/Page2";

const UpdateProfile = () => {
  const { data, isLoading } = useFETCH(
    checkToken && "v1/profile",
    // (profileState === "/profile" ? "" : profileState) +
    false,
    false,
    false,
    false
  );
  const dataOld = data?.data?.data;
  const {
    handleChangeInput,
    handleChangeSelect,
    handleSubmit,
    setFormData,
    formData,
    loading,
  } = usePOST({});
  const { updateProfile, listAges, listChildren, listHeight, listWeight } =
    useListPage();
  const { setListError } = useContextHook();
  const handleSubmitMain = (e) => {
    handleSubmit(`v1/profile/update`);
  };
  const { error } = useNext(formData, 1);
  useEffect(() => {
    const gender = dataOld?.gender === "FEMALE";
    setFormData({
      _method: "PUT",
      name: dataOld?.secret_data?.name,
      username: dataOld?.login_details?.username,
      email: dataOld?.login_details?.email,
      phone_number: dataOld?.secret_data?.phone_number,
      height: dataOld?.my_specifications?.height,
      age: dataOld?.social_status?.age,
      children: dataOld?.social_status?.children,
      weight: dataOld?.my_specifications?.weight,
      nationality_id: dataOld?.nationality_residence?.nationality?.id,
      country_id: dataOld?.nationality_residence?.country?.id,
      city_id: dataOld?.nationality_residence?.city?.id,
      marriage_status_id: dataOld?.social_status?.marriage_status?.id,
      [gender ? "hijab_status_id" : "beard_status_id"]: gender
        ? dataOld?.religious_commitment?.hijab_status?.id
        : dataOld?.religious_commitment?.beard_status?.id,
      prayer_status_id: dataOld?.religious_commitment?.prayer_status?.id,
      employment_status_id: dataOld?.study_work?.employment_status?.id,
      job_id: dataOld?.study_work?.job?.id,
      social_status_id: dataOld?.social_status?.social_status?.id,
      smoking_status_id: dataOld?.religious_commitment?.smoking_status?.id,
      religiosity_status_id:
        dataOld?.religious_commitment?.religiosity_status?.id,
      skin_color_id: dataOld?.my_specifications?.skin_color?.id,
      health_status_id: dataOld?.study_work?.health_status?.id,
      educational_level_id: dataOld?.study_work?.educational_level?.id,
      monthly_income_range_id: dataOld?.study_work?.monthly_income_range?.id,
      physique_status_id: dataOld?.my_specifications?.physique_status?.id,
      financial_status_id: dataOld?.study_work?.financial_status?.id,
      own_props: dataOld?.own_props,
      future_wife_props: dataOld?.future_wife_props,
    });
  }, [dataOld]);

  return (
    <>
      <Col md={11} className="mx-auto">
        <Cards className="border-2 bg-white border-Secondary rounded-2xl p-10 mx-auto">
          {loading && <Loading />}
          <Row className="items-start">
            <Col md={6} lg={6}>
              <Input
                text={content.Username}
                name="username"
                value={formData?.username}
                onChange={handleChangeInput}
                title={content.Username}
              />
            </Col>
            <Col md={6} lg={6}>
              <Input
                text={content.Email}
                title={content.Email}
                name="email"
                value={formData?.email}
                onChange={handleChangeInput}
              />
            </Col>
            <Col md={6} lg={6}>
              <Input
                text={content.FullName}
                name="name"
                value={formData?.name}
                onChange={handleChangeInput}
                title={content.FullName}
              />
            </Col>
            <Col md={6}>
              <Input
                text={content.PhoneNumber}
                name="phone_number"
                type="number"
                value={formData?.phone_number}
                onChange={handleChangeInput}
                title={content.PhoneNumber}
              />
            </Col>
            <Col md={6}>
              <div className="my-2">
                <div className="text-xl font-semibold text-Secondary border-Main">
                  {content.Age}
                </div>
                <Select
                  className="w-full bg-[#E7E7E7] mt-2 rounded-xl text-center text-Secondary font-semibold"
                  placeholder={content.Select}
                  options={listAges}
                  name="age"
                  onChange={handleChangeSelect}
                  value={
                    (formData?.age &&
                      listAges
                        ?.filter((e) => `${e.value}` === `${formData?.age}`)
                        ?.map((q) => {
                          return { value: q.value, label: q.label };
                        })[0]) ||
                    null
                  }
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="my-2">
                <div className="text-xl font-semibold text-Secondary border-Main">
                  {content.Weight}
                </div>
                <Select
                  className="w-full bg-[#E7E7E7] mt-2 rounded-xl text-center text-Secondary font-semibold"
                  placeholder={content.Select}
                  options={listWeight}
                  name="weight"
                  onChange={handleChangeSelect}
                  value={
                    (formData?.weight &&
                      listWeight
                        ?.filter((e) => `${e.value}` === `${formData?.weight}`)
                        ?.map((q) => {
                          return { value: q.value, label: q.label };
                        })[0]) ||
                    null
                  }
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="my-2">
                <div className="text-xl font-semibold text-Secondary border-Main">
                  {content.Height}
                </div>
                <Select
                  className="w-full bg-[#E7E7E7] mt-2 rounded-xl text-center text-Secondary font-semibold"
                  placeholder={content.Select}
                  options={listHeight}
                  name="height"
                  onChange={handleChangeSelect}
                  value={
                    (formData?.height &&
                      listHeight
                        ?.filter((e) => e.value === formData?.height)
                        ?.map((q) => {
                          return { value: q.value, label: q.label };
                        })[0]) ||
                    null
                  }
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="my-2">
                <div className="text-xl font-semibold text-Secondary border-Main">
                  {content.Children}
                </div>
                <Select
                  className="w-full bg-[#E7E7E7] mt-2 rounded-xl text-center text-Secondary font-semibold"
                  placeholder={content.Select}
                  options={listChildren}
                  name="children"
                  onChange={handleChangeSelect}
                  value={
                    ((formData?.children || formData?.children === 0) &&
                      listChildren
                        ?.filter(
                          (e) => `${e.value}` === `${formData?.children}`
                        )
                        ?.map((q) => {
                          return { value: q.value, label: q.label };
                        })[0]) ||
                    null
                  }
                />
              </div>
            </Col>
            {/* <Col md={6} lg={6}>
              <SliderMUI
                title={content.Age}
                unit={content.years}
                max={70}
                min={18}
                name="age"
                value={formData?.age}
                onChange={handleChangeInput}
              />
            </Col>
            <Col md={6} lg={6}>
              <SliderMUI
                title={content.Weight}
                unit={content.kg}
                max={200}
                min={18}
                value={formData?.weight}
                onChange={handleChangeInput}
                name="weight"
              />
            </Col>
            <Col md={6} lg={6}>
              <SliderMUI
                title={content.Height}
                unit={content.cm}
                max={220}
                min={100}
                value={formData?.length}
                onChange={handleChangeInput}
                name="length"
              />
            </Col>
            <Col md={6} lg={6} className="mx-auto">
              <SliderMUI
                title={content.Children}
                unit=" "
                max={12}
                name="children"
                value={formData?.children}
                onChange={handleChangeInput}
              />
            </Col> */}

            {updateProfile?.map((e) => (
              <Col md={6}>
                <Selects
                  name={e.name}
                  text={e.title}
                  value={formData?.[e.name]}
                  onChange={handleChangeSelect}
                  options={e.options}
                />
              </Col>
            ))}

            <Col className="mx-auto">
              <TextArea
                text={content.MySpecifications}
                name="own_props"
                value={formData?.own_props}
                onChange={handleChangeInput}
                title="my specifications"
              />
            </Col>
            <Col className="">
              <TextArea
                text={content.MyPartnerSpecifications}
                name="future_wife_props"
                value={formData?.future_wife_props}
                onChange={handleChangeInput}
                title={content.MyPartnerSpecifications}
              />
            </Col>
            {/* <Col>
              <AddFile
                type="IMAGES"
                setFormData={setFormData}
                formData={formData}
                name="images"
                title={content.imageOptional}
                newImages={viewImages}
                setImages={setImages}
                setViewImages={setViewImages}
                images={images}
                oldImage={
                  !viewFile && dataOld?.image && fileUrl + dataOld?.image
                }
                onChange={handleChangeArrayImages}
                isShown={isShown}
              />
            </Col> */}
            <Col md={6} lg={2} className=" !mt-8 flex justify-end ">
              <ButtonMain
                onClick={() => {
                  if (error.length > 0) {
                    setListError([...error]);
                  } else {
                    handleSubmitMain();
                  }
                }}
                className="w-[150px] "
                title={content.Save}
              />
            </Col>
          </Row>
        </Cards>
      </Col>
    </>
  );
};

export default UpdateProfile;
