import Select from "react-select";
import { checkIsSearchSelect } from "../../Context/functions";
import { content } from "../../Context/translate";

const Selects = ({ text, title, value, onChange, name, isSearch, options }) => {
  return (
    <div className="my-1">
      <div className="text-xl font-semibold text-Secondary border-Main">
        {text || title}
      </div>
      <Select
        placeholder={content.Select}
        value={
          (value &&
            options
              ?.filter((e) => `${e.id}` === `${value}`)
              ?.map((q) => {
                return { value: q.id, label: q.name };
              })[0]) ||
          null
        }
        isSearchable={checkIsSearchSelect(name)}
        className="w-full bg-[#E7E7E7] mt-2 rounded-xl text-center text-Secondary font-semibold  "
        onChange={onChange}
        name={name}
        options={options?.map((e) => {
          return { value: e.id, label: e.name };
        })}
      />
    </div>
  );
};

export default Selects;
