import { Col } from "../../Tools/Grid-system";
import NavImages from "./NavImages";
import { Outlet } from "react-router-dom";

const UsersImages = () => {
  return (
    <Col md={11} className="mx-auto space-y-4 ">
      <NavImages />
      <Outlet />
    </Col>
  );
};

export default UsersImages;
