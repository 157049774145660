import React from "react";
import QuickSearchHome from "../Home/QuickSearchHome";
import { Ads } from "../../layout/SideBarUser/SideBarUser";

const QuickSearch = () => {
  return (
    <>
      <QuickSearchHome className="pt-5 mx-auto w-full" />
      <Ads posistion="quick_username_search" className="!h-[50vh] mt-10" />
    </>
  );
};

export default QuickSearch;
