import { Link } from "react-router-dom";
import { Col } from "../../Tools/Grid-system";

import healthCases from "../../images/health-cases.svg";
import newMembers from "../../images/new.svg";
import onlinenow from "../../images/online.svg";
import qiranBlog from "../../images/blog.svg";
import active from "../../images/undraw_icon_design_re_9web (1).svg";
import freeSignup from "../../images/sign-up.svg";

import { content } from "../../Context/translate";

const RoutsSection = () => {
  const elements = [
    {
      title: content.OnlineNow,
      link: "/online",
      image: onlinenow,
    },
    {
      title: content.ActiveMembers,
      link: "/active",
      image: active,
    },
    {
      title: content.NewMembers,
      link: "/new",
      image: newMembers,
    },
    {
      title: content.HealthCases,
      link: "/health-conditions",
      image: healthCases,
    },
  ];
  const elementshome = [
    {
      title: content.QiranBookBlog,
      link: "/blog",
      image: qiranBlog,
    },
    {
      title: content.FreeSignUp,
      link: "/auth/sign-up",
      image: freeSignup,
    },
  ];

  return (
    <>
      <Col col={11} className="mx-auto">
        <div className="p-6 rounded-lg border-[#D86Aaa99] border overflow-hidden mx-auto my-4 flex gap-8 items-center  sm:justify-center md:justify-center lg:justify-between flex-wrap shadow-lg shadow-gray-400 ">
          {elements.map((element, i) => {
            return (
              <div
                key={i}
                className="col-lg-2  col-sm-5  cursor-pointer mx-auto">
                <Link to={element.link}>
                  <div>
                    <div className="flex flex-col  justify-center items-center gap-8 my-component max-md:text-sm ">
                      <div className="h-[120px] w-[120px] max-md:!h-[60px] max-md:!w-[60px] ">
                        <img
                          src={element.image}
                          alt={element.title}
                          className=" object-contain"
                        />
                      </div>
                      <div className="text-normal font-semibold text-gray-900 !text-center mt-4 max-md:mt-1 ">
                        {element.title}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </Col>
      <Col lg={5} md={11} sm={11} className="mx-auto pt-4">
        <div className="p-6 rounded-lg border-[#D86Aaa99] border overflow-hidden mx-auto my-4 flex gap-8 items-center  sm:justify-center md:justify-center lg:justify-between flex-wrap shadow-lg shadow-gray-400 ">
          {elementshome.map((element, i) => {
            return (
              <div
                key={i}
                className="col-lg-3  col-sm-4  cursor-pointer mx-auto max-md:text-sm">
                <Link to={element.link}>
                  <div>
                    <div className="flex flex-col gap-8 justify-between items-center my-component ">
                      <div className="h-[120px] w-[120px] max-md:!h-[60px] max-md:!w-[60px] ">
                        <img
                          src={element.image}
                          alt={element.title}
                          className=" object-cover"
                        />
                      </div>
                      <div className="text-normal font-semibold text-gray-900 !text-center ">
                        {element.title}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </Col>
    </>
  );
};

export default RoutsSection;
