import { Link } from "react-router-dom";

const ButtonMain = ({ title, className, link, onClick }) => {
  return (
    <>
      {link ? (
        <div
          onClick={onClick}
          className={` ${className} border-2 text-black transition-all duration-300 font-semibold  cursor-pointer  flex items-center justify-center  rounded-lg gap-2  `}>
          <Link to={link || ""} className={`w-full text-center px-4 py-2 `}>
            <div>{title}</div>
          </Link>
        </div>
      ) : (
        <div
          onClick={onClick}
          className={` ${className}  transition-all font-semibold px-4 py-2 text-white cursor-pointer bg-Main flex items-center justify-center  rounded-lg gap-2  `}>
          <div>{title}</div>
        </div>
      )}
    </>
  );
};

export default ButtonMain;
