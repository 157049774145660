import { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import useCitiesData from "../../Context/citiesData";
import { checkIsSearchSelect } from "../../Context/functions";
import { useListPage } from "../../Context/listSignUp";
import { content } from "../../Context/translate";
import { Col, Row } from "../../Tools/Grid-system";
import { Cards, SliderMUI } from "../../components";
import { useSearch } from "../Home/QuickSearchHome";
import { checkToken } from "../../Tools/APIs";
const AdvancedSearch = () => {
  const { queryString, dataSearch, setDataSearch } = useSearch({});
  const [searchFor, setSearchFor] = useState(sessionStorage.getItem("gender"));

  const [valueSearch, setValueSearch] = useState({
    age: [18, 70],
    weight: [45, 120],
    height: [150, 190],
    children: "",
  });
  const { optionsCities } = useCitiesData("cities", dataSearch?.country_id);
  const { optionsCities: optionsJobs } = useCitiesData(
    "jobs",
    dataSearch?.employment_status_id
  );
  const { advancedSearch } = useListPage(true, optionsCities, optionsJobs);
  const handleChangeRange = (event, newValue) => {
    setValueSearch({
      ...valueSearch,
      [event.target.name]: newValue,
    });
  };

  const handleChangeMultiSelect = (selected, active) => {
    setDataSearch({
      ...dataSearch,
      [active.name]: Array.isArray(selected)
        ? selected.map((e) => e.value)
        : selected.value,
    });
  };

  return (
    <Col md={11} className="py-5 mx-auto">
      <>
        <form>
          <Row justify="center">
            <Col>
              <Cards>
                {checkToken ? (
                  ""
                ) : (
                  <div className="rounded-xl text-sm text-center overflow-hidden w-fit mx-auto my-4 space-x-3">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        sessionStorage.setItem("gender", "FEMALE");
                        setDataSearch({
                          ...dataSearch,
                          gender: "FEMALE",
                        });
                        setSearchFor("FEMALE");
                      }}
                      className={`${
                        searchFor === "FEMALE"
                          ? "text-white bg-Secondary"
                          : "text-Secondary bg-white"
                      } transition-all duration-300 font-semibold rounded-xl p-3 text-center`}
                    >
                      {content.forHusband}
                    </button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        sessionStorage.setItem("gender", "MALE");
                        setDataSearch({
                          ...dataSearch,
                          gender: "MALE",
                        });
                        setSearchFor("MALE");
                      }}
                      className={`${
                        searchFor === "MALE"
                          ? "text-white bg-Main"
                          : "text-Main bg-white"
                      } transition-all duration-300 font-semibold rounded-xl p-3 text-center`}
                    >
                      {content.forWife}
                    </button>
                  </div>
                )}
                <Row>
                  {advancedSearch?.map((e, i) => (
                    <Col md={6} key={i}>
                      <div className=" font-semibold text-Secondary text-lg">
                        {e.title}
                      </div>
                      <Select
                        placeholder={content.Select}
                        className="w-full bg-[#E7E7E7]  mt-2  rounded-lg text-center"
                        isMulti
                        onChange={handleChangeMultiSelect}
                        isSearchable={checkIsSearchSelect(e.name)}
                        name={e.name}
                        options={e.options?.map((e) => {
                          return { value: e.id, label: e.name };
                        })}
                      />
                    </Col>
                  ))}
                  <Col md={6}>
                    <div className=" font-semibold text-Secondary text-lg">
                      {content.SortBy}
                    </div>
                    <Select
                      placeholder={content.Select}
                      isSearchable={false}
                      className="w-full bg-[#E7E7E7]  mt-2  rounded-lg text-center"
                      onChange={handleChangeMultiSelect}
                      name="sort_by"
                      options={[
                        { value: "age", label: content.Age },
                        { value: "last_active_at", label: content.LastSeen },
                        {
                          value: "created_at",
                          label: content.DateOfRegistration,
                        },
                      ]}
                    />
                  </Col>
                  <Col md={6} col={11}>
                    <SliderMUI
                      className="text-Secondary text-lg font-semibold"
                      title={content.Children}
                      unit=" "
                      max={12}
                      value={valueSearch?.children}
                      onChange={handleChangeRange}
                      name="children"
                    />
                  </Col>
                  <Col md={6} col={11}>
                    <SliderMUI
                      className="text-Secondary text-lg font-semibold"
                      title={content.Age}
                      unit={content.years}
                      max={70}
                      min={18}
                      value={valueSearch?.age}
                      onChange={handleChangeRange}
                      name="age"
                    />
                  </Col>
                  <Col md={6} col={11}>
                    <SliderMUI
                      className="text-Secondary text-lg font-semibold"
                      title={content.Weight}
                      unit={content.kg}
                      max={200}
                      min={18}
                      value={valueSearch?.weight}
                      onChange={handleChangeRange}
                      name="weight"
                    />
                  </Col>
                  <Col md={6} col={11}>
                    <SliderMUI
                      className="text-Secondary text-lg font-semibold"
                      title={content.Height}
                      unit={content.cm}
                      max={220}
                      min={100}
                      value={valueSearch?.height}
                      onChange={handleChangeRange}
                      name="height"
                    />
                  </Col>
                </Row>
                <Col className="w-full flex justify-center">
                  <Link
                    className="w-1/3 max-sm:w-1/2"
                    to={
                      "/search/users?" +
                      queryString +
                      (valueSearch.age[0]
                        ? "&age_more_than=" + valueSearch.age[0]
                        : "") +
                      (valueSearch.age[1]
                        ? "&age_less_than=" + valueSearch.age[1]
                        : "") +
                      (valueSearch.weight[0]
                        ? "&weight_more_htan=" + valueSearch.weight[0]
                        : "") +
                      (valueSearch.weight[1]
                        ? "&weight_less_than=" + valueSearch.weight[1]
                        : "") +
                      (valueSearch.height[0]
                        ? "&height_more_than=" + valueSearch.height[0]
                        : "") +
                      (valueSearch.height[1]
                        ? "&height_less_than=" + valueSearch.height[1]
                        : "")
                    }
                  >
                    <input
                      type="submit"
                      value={content.Search}
                      className={`w-full mt-4 font-semibold py-2 text-white cursor-pointer bg-Main rounded-lg  gap-2  `}
                    />
                  </Link>
                </Col>
              </Cards>
            </Col>
          </Row>
        </form>
      </>
    </Col>
  );
};

export default AdvancedSearch;
