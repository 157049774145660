import React from "react";

const LoadingMembersCard = () => {
  return (
    <div className="w-full h-fit rounded-2xl pt-5 bg-white border">
      <div className="flex px-5">
        <div className="bg-slate-300 w-20 h-20 rounded-full"></div>
        <div>
          <div className="bg-slate-300 w-20 h-4 m-2"></div>
          <div className="bg-slate-300 w-24 h-4 m-2"></div>
        </div>
      </div>
      <div className="flex justify-between items-center mt-4 px-5">
        <div className="bg-slate-300  w-24 h-6 m-2"></div>
        <div className="bg-slate-300  w-24 h-6 m-2"></div>
      </div>
      <div className="bg-slate-300 w-full h-10 rounded-lg"></div>
    </div>
  );
};

export default LoadingMembersCard;
