import React, { useState } from "react";
import { useContextHook } from "../../Context/ContextOPen";
import {
  MdDelete,
  MdOutlineContentCopy,
  VscKebabVertical,
} from "../../Context/exportIcons";
import { content as contentTrans } from "../../Context/translate";
import { language, useClose } from "../../Tools/APIs";
import PopUp from "../../components/PopUps/PopUp";

const MessageSenderOption = ({ content, deleteMessage, successfulDelete }) => {
  const { setMessagesSuccess } = useContextHook();
  const { mouse, open, setOpen } = useClose();
  const [showDelete, setShowDelete] = useState(false);
  return (
    <>
      <div ref={mouse} className="relative ">
        <VscKebabVertical
          className="cursor-pointer"
          size={22}
          onClick={() => setOpen(!open)}
        />
        {open && (
          <div
            className={`absolute overflow-hidden bottom-0 ${
              language === "ar" ? "right-3" : "left-3"
            } rounded-xl text-center bg-white text-black border z-20 w-36 `}>
            <div
              onClick={() => {
                navigator.clipboard.writeText(content).then(() => {
                  setMessagesSuccess([contentTrans.messageCopiedTheMessage]);
                  setOpen(false);
                });
              }}
              className={`hover:bg-Main hover:text-white pb-1 pt-2 flex items-center px-4 gap-1 ${
                language === "ar" ? "flex-row-reverse" : ""
              }`}>
              <div>
                <MdOutlineContentCopy />
              </div>
              <div>{contentTrans.Copy}</div>
            </div>
            <div
              onClick={() => {
                setShowDelete(true);
                setOpen(false);
              }}
              className={`hover:bg-Main hover:text-white pb-1 pt-2 flex items-center px-4 gap-1 ${
                language === "ar" ? "flex-row-reverse" : ""
              }`}>
              <div>
                <MdDelete />
              </div>
              <div>{contentTrans.Delete}</div>
            </div>
          </div>
        )}
      </div>
      <PopUp
        onClickFalse={() => setShowDelete(false)}
        onClick={deleteMessage}
        show={showDelete && !successfulDelete}
        title={contentTrans.messageDeleteTheMessage}
      />
    </>
  );
};

export default MessageSenderOption;
