import "./Loading.css";

const Loading = ({ className }) => {
  return (
    <div
      className={`absolute flex justify-center items-center w-full h-full bg-[#ffffff77] top-0 left-0 z-[70] ${className}`}>
      <div className="loader"></div>
    </div>
  );
};

export default Loading;
