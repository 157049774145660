import { content } from "../../Context/translate";
import { NavFilterSearch, TitlePage } from "../../components";

const NavSearch = () => {
  return (
    <div>
      <TitlePage title="Search" className="text-start text-4xl" />
      <NavFilterSearch
        listNav={[
          { name: content.Username, link: "/search/" },
          { name: content.QuickSearch, link: "quick" },
          { name: content.AdvancedSearch, link: "advanced" },
        ]}
      />
    </div>
  );
};

export default NavSearch;
