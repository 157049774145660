import { content } from "../../Context/translate";

function DataCol({ name, text, className }) {
  return (
    <div className={`  font-bold mt-2 w-full my-6  ${className}`}>
      <div className="text-lg text-Secondary w-full ">{name}</div>
      <div className=" font-normal flex-1 w-full">
        {text || content.nothing}
      </div>
    </div>
  );
}
export default DataCol;
