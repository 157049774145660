import { Link, useLocation } from "react-router-dom";
import { fileUrl, useFETCH } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import Pagination from "../../Tools/Pagination";
import Loading from "../../Tools/Loading";
import NotFound from "../../components/NotFound/NotFound";
import { content } from "../../Context/translate";

function QuestionCategories({ type, title }) {
  const { pathname, search } = useLocation();

  const { data, isLoading } = useFETCH(
    `v1/faqs-sections?type=${type}${search ? "&" + search.substring(1) : ""}`
  );

  if (isLoading) return <Loading />;

  return (
    <Row justify="center">
      <Col md={11} className="relative min-h-[60vh]">
        <h1 className="text-3xl font-bold text-Main  text-center mb-12 mt-12">
          {title}
        </h1>
        {data?.data?.data?.data?.length === 0 ? (
          <NotFound
            text={
              pathname === "/faqs"
                ? content.FaqsNoResults
                : content.HelpNoResults
            }
          />
        ) : (
          <>
            {isLoading ? (
              <Loading />
            ) : (
              <Pagination
                pageCount={Math.ceil(
                  data?.data?.data?.total / data?.data?.data?.per_page
                )}>
                <Row className="space-y-8">
                  {data?.data?.data?.data?.map((e) => (
                    <Col
                      key={e.id}
                      md={12}
                      className="mx-auto shadow-md shadow-Main rounded-2xl">
                      <div className=" py-6 px-5  ">
                        <div className="flex items-center gap-4">
                          <img
                            src={fileUrl + e.image}
                            alt=""
                            className="w-16 h-16 rounded-full"
                          />
                          <h2 className="text-xl text-Secondary font-bold">
                            {e.name}
                          </h2>
                        </div>
                        <Row className="!mt-4 justify-center">
                          <Questions sectionId={e.id} pathname={pathname} />
                        </Row>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Pagination>
            )}
          </>
        )}
      </Col>
    </Row>
  );
}
export default QuestionCategories;

const Questions = ({ sectionId, pathname }) => {
  const { data } = useFETCH(`v1/faqs-sections/${sectionId}/faqs?paginate=0`);

  return (
    <Col md={12} className="relative min-h-[30vh]">
      {data?.data?.data?.map((e, i) => (
        <Link
          to={`${pathname}/${sectionId}/${e.id}`}
          className={`${
            data?.data?.data?.length === i + 1 ? "" : "border-b"
          } text-lg hover:text-Secondary  pb-3 transition-all py-4 block`}>
          <p>{e.name}</p>
        </Link>
      ))}
    </Col>
  );
};
