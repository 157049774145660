import { useContextHook } from "../../../Context/ContextOPen";
import { content } from "../../../Context/translate";
import { usePOST } from "../../../Tools/APIs";
import { Col, Container, Row } from "../../../Tools/Grid-system";
import { ButtonMain, Cards } from "../../../components";
import Input from "../../../components/Input/Input";
import { useNext } from "../SignUp/Page2";
function ForgetPassword() {
  const { setListError } = useContextHook();
  const { handleSubmit, handleChangeInput, formData, loading } = usePOST({
    email: "",
  });
  const { error } = useNext(formData);
  return (
    <Container className="img mb-12">
      <Row>
        <Col md={8} className="mx-auto">
          <Cards isLoading={loading} className="mt-10">
            <Col md={8} className="mx-auto my-6">
              <h1 className="text-2xl font-semibold max-xsm:text-lg text-Main text-center mb-8">
                {content.ForgotPassword}
              </h1>
              <Input
                title={content.Email}
                onChange={handleChangeInput}
                name="email"
                className="my-3 mb-8"
              />
              <div className="w-[60%] mx-auto">
                <ButtonMain
                  onClick={() => {
                    if (error.length > 0) {
                      return setListError([...error]);
                    }
                    handleSubmit(
                      "v1/send/verification-code",
                      "",
                      "/auth/verification-code"
                    );
                  }}
                  title={content.Next}
                />
              </div>
            </Col>
          </Cards>
        </Col>
      </Row>
    </Container>
  );
}
export default ForgetPassword;
