import { useEffect, useState } from "react";
import { MdBlockFlipped } from "../../Context/exportIcons";
import { checkToken, useFETCH, usePOST } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import { useContextHook } from "../../Context/ContextOPen";
import { content } from "../../Context/translate";
import { useContextChat } from "../../Context/ContextChat";

function Forbidden({ id, chat, className, size }) {
  const { isIgnoring, setIsIgnoring } = useContextChat();
  const [sure, setSure] = useState(false);
  const { deleteIgnore, isLoading, successfulDeleteIgnore } = useFETCH(
    "",
    `v1/ignore/${id}/delete`
  );
  const { handleSubmit, loading, successfulPost } = usePOST({});
  const { showMessagesSign, setMessagesSuccess } = useContextHook();

  useEffect(() => {
    if (successfulPost) {
      setIsIgnoring(true);
      setMessagesSuccess([content.MessageBlocked]);
    }
  }, [successfulPost]);

  useEffect(() => {
    if (successfulDeleteIgnore) {
      setIsIgnoring(false);
      setMessagesSuccess([content.MessageUnblocked]);
    }
  }, [successfulDeleteIgnore]);

  return (
    <div>
      {loading || isLoading ? <Loading /> : ""}
      {sure && (
        <>
          <div
            onClick={() => setSure(false)}
            className={`${
              sure ? "" : "hidden"
            } fixed w-full h-full top-0 left-0 bg-black/60 z-[61] flex justify-center items-center overflow-x-auto`}></div>
          <div>
            <div className=" fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white z-[62] rounded-3xl w-[350px] max-w-[350px] mx-2 min-h-[150px] ">
              <p className="font-bold text-2xl text-center py-5 max-sm:text-lg text-Main">
                {isIgnoring ? content.UnblockTheUser : content.BlockTheUser}
              </p>
              <div className="flex items-center  justify-evenly gap-2 m-5">
                <button
                  onClick={() => {
                    if (!isIgnoring) {
                      handleSubmit(
                        "v1/ignore?ignored_id=" + id,
                        false,
                        false,
                        true
                      ).then(() => {
                        setSure(false);
                      });
                    } else {
                      deleteIgnore(id).then(() => {
                        setSure(false);
                      });
                    }
                  }}
                  className="transition-all px-5 py-2 text-lg bg-blue-500 hover:bg-blue-400 text-white font-semibold  rounded-md">
                  {content.yes}
                </button>
                <button
                  onClick={() => setSure(false)}
                  className="transition-all px-5 py-2 text-lg hover:bg-red-400 text-white  bg-red-500 font-semibold  rounded-md">
                  {content.cancel}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {chat ? (
        <li
          onClick={() => setSure(true)}
          className="hover:bg-Main hover:text-white pb-1 pt-2">
          {content.Block}
        </li>
      ) : (
        <div
          className={`hover:bg-Main ${className ? className : "w-10 h-10"} ${
            isIgnoring ? "bg-Main" : `bg-gray-400`
          } rounded-full w-10 h-10 flex items-center justify-center cursor-pointer`}
          onClick={() => {
            checkToken ? setSure(true) : showMessagesSign();
          }}>
          <MdBlockFlipped
            size={size ? size : 30}
            className={`hover:text-white cursor-pointer ${
              isIgnoring ? "text-white" : "text-slate-200"
            }`}
          />
        </div>
      )}
    </div>
  );
}
export default Forbidden;
