import { useState } from "react";
import { NavLink } from "react-router-dom";
import { RiArrowDropDownFill, CiLogout } from "../../Context/exportIcons";
import { avatars, ListUser } from "../../Context/lists";
import { fileUrl, language, logout, useClose } from "../../Tools/APIs";
import PopUp from "../../components/PopUps/PopUp";
import { content } from "../../Context/translate";
import { ImageUser } from "../../components";

function UserList({ data }) {
  const { open, setOpen, mouse } = useClose();
  const [show, setShow] = useState(false);
  const selectedAvatar = avatars.filter(
    (e) => e.id === data?.data?.avatar_id
  )[0];
  return (
    <div className="max-md:hidden">
      <div
        className="relative cursor-pointer"
        ref={mouse}
        onClick={() => setOpen(!open)}>
        <div className="flex items-center justify-center gap-1">
          <ImageUser
            image={
              data?.data?.images && data?.data?.images?.length !== 0
                ? fileUrl +
                  data?.data?.images[data?.data?.images?.length - 1].image
                : data?.data?.avatar_id
                ? selectedAvatar.img
                : ""
            }
            id={data?.data?.login_details?.id}
            isOnline={data?.data?.isOnline}
            className="w-12 h-12 rounded-full"
          />
          {/* <img
            src={
              data?.images && data?.images.length != 0
                ? fileUrl + data?.images[data?.images.length - 1].image
                : data?.avatar_id
                ? selectedAvatar.img
                : ddd
            }
            alt=""
            className="w-12 h-12 rounded-full"
          /> */}
          <RiArrowDropDownFill
            color="white"
            size={35}
            className={`${
              open ? "rotate-180 transition-all" : "rotate-0 transition-all"
            }`}
          />
        </div>
        <ul
          className={`${
            open
              ? `absolute z-50 overflow-hidden top-12 ${
                  language === "ar" ? "left-4" : "right-4"
                } rounded-tl-3xl rounded-br-3xl text-center bg-white shadow-lg border w-64`
              : "hidden"
          }`}>
          {ListUser.map((e, i) => (
            <NavLink
              key={i}
              to={e.link}
              className={`flex items-center gap-1 px-2 hover:text-white ${
                sessionStorage.getItem("gender") === "FEMALE"
                  ? "hover:bg-Main"
                  : "hover:bg-Secondary"
              } transition-all`}>
              <e.icon size={23} />
              <div className="flex items-center gap-4">
                <li className=" pb-1 pt-2 ">{e?.name}</li>
                {data?.notifications_types &&
                  data?.notifications_types[e.notification_name] > 0 &&
                  e.notification_name && (
                    <p className="flex justify-center items-center bg-red-500 text-white rounded-lg px-1">
                      {
                        data?.notifications_types[
                          e.notification_name && e.notification_name
                        ]
                      }
                    </p>
                  )}
              </div>
            </NavLink>
          ))}
          <div
            onClick={() => setShow(true)}
            className={`flex items-center gap-1 px-2 ${
              sessionStorage.getItem("gender") === "MALE"
                ? "hover:bg-Secondary"
                : "hover:bg-Main"
            } hover:!text-white transition-all`}>
            <CiLogout size={23} />
            <li className=" pb-1 pt-2">{content.logout}</li>
          </div>
        </ul>
      </div>
      <PopUp
        onClickFalse={() => setShow(false)}
        onClick={() => logout("v1/logout", true)}
        show={show}
        title={content.sureLogout}
      />
    </div>
  );
}
export default UserList;
