import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Switch from "react-switch";
import {
  CgMenuLeftAlt,
  CiLogout,
  CgMenuRightAlt,
} from "../../Context/exportIcons";

import { avatars, ListSideBar, ListSideBarNoSign } from "../../Context/lists";
import { changeLanguage, content } from "../../Context/translate";
import {
  checkToken,
  fileUrl,
  language,
  logout,
  useClose,
} from "../../Tools/APIs";
import { ImageUser } from "../../components";
import PopUp from "../../components/PopUps/PopUp";
import Logo from "../../images/qiranbook6 (1) 1.svg";
import { useContextHook } from "../../Context/ContextOPen";
const SideBar = ({ data }) => {
  const { setShowUserImages } = useContextHook();
  const { mouse, open, setOpen } = useClose();
  const [show, setShow] = useState(false);
  const direction = document.body.style.direction;

  const selectedAvatar = avatars.filter(
    (e) => e.id === data?.data?.data?.avatar_id
  )[0];


  return (
    <div className="md:hidden">
      {language === "ar" ? (
        <CgMenuRightAlt
          onClick={() => setOpen(true)}
          className="cursor-pointer w-8 h-8 max-sm:w-6 max-sm:h-6"
        />
      ) : (
        <CgMenuLeftAlt
          onClick={() => setOpen(true)}
          className="cursor-pointer w-8 h-8 max-sm:w-6 max-sm:h-6"
        />
      )}
      <div
        ref={mouse}
        className={`w-[300px] h-full fixed pb-24 bg-white top-0 ${
          direction === "rtl" ? "right-0" : "left-0"
        } transition-all z-[61] border-r overflow-auto ${
          open
            ? ""
            : direction === "rtl"
            ? "translate-x-full"
            : "-translate-x-full"
        }`}>
        {checkToken ? (
          <div
            className={`${
              sessionStorage.getItem("gender") === "FEMALE"
                ? "bg-Main"
                : "bg-Secondary"
            } p-3 flex justify-between items-center gap-2`}>
            <div>
              <ImageUser
                image={
                  data?.data?.data?.images?.length > 0
                    ? fileUrl +
                      data?.data?.data?.images[
                        data?.data?.data?.images?.length - 1
                      ].image
                    : data?.data?.data?.avatar_id
                    ? selectedAvatar.img
                    : ""
                }
                id={data?.data?.data?.login_details?.id}
                isOnline={data?.data?.data?.isOnline}
                onClick={() =>
                  data?.data?.data?.images?.length !== 0 &&
                  setShowUserImages([data?.data?.data?.login_details?.id, true])
                }
                className="w-[56px] h-[56px]"
              />
            </div>
            <div className="flex-1 text-lg text-center">
              <div className="text-white font-bold">
                {data?.data?.data?.secret_data?.name}
              </div>
              <div className="text-sm font-semibold text-white">
                {content.Registred} {data?.data?.data?.registered_since}
              </div>
            </div>
          </div>
        ) : (
          <div className="">
            <img src={Logo} alt="" className="w-[200px] mx-auto" />
          </div>
        )}
        <PopUp
          onClickFalse={() => setShow(false)}
          onClick={() => logout("v1/logout", true)}
          show={show}
          title={content.sureLogout}
        />
        {checkToken ? (
          <ul className="space-y-2">
            {ListSideBar.map((e, i) => (
              <React.Fragment key={i}>
                <li
                  className={`px-3 py-2 ${
                    sessionStorage.getItem("gender") === "FEMALE"
                      ? "hover:text-Main"
                      : "hover:text-Secondary"
                  } transition-all duration-300`}>
                  <NavLink
                    to={e.link || ""}
                    className={`flex items-center justify-start gap-2 `}>
                    {e.icon ? <e.icon size={25} /> : ""}
                    <div className="font-semibold flex gap-2">
                      {e?.name}
                      {data?.data?.notifications_count > 0 &&
                        data?.data?.notifications_types[e?.notification_name] >
                          0 &&
                        e?.notification_name && (
                          <p className="flex justify-center items-center bg-red-500 text-white rounded-lg px-1">
                            {
                              data?.data?.notifications_types[
                                e?.notification_name
                              ]
                            }
                          </p>
                        )}
                    </div>
                  </NavLink>
                </li>
                {e.br && <hr />}
              </React.Fragment>
            ))}
            <div className="flex items-center gap-1 justify-center !my-4">
              <div className="font-semibold ">
                {language === "ar" ? "EN" : "عربي"}
              </div>
              <Switch
                height={15}
                uncheckedIcon
                checkedIcon
                offColor="#cab8b8"
                onColor="#cab8b8"
                offHandleColor={
                  checkToken
                    ? sessionStorage.getItem("gender") === "FEMALE"
                      ? "#008dc9"
                      : "#ff6584"
                    : "#ff6584"
                }
                onHandleColor={
                  checkToken
                    ? sessionStorage.getItem("gender") === "FEMALE"
                      ? "#008dc9"
                      : "#ff6584"
                    : "#ff6584"
                }
                activeBoxShadow={`0 0 1px 1px ${
                  checkToken
                    ? sessionStorage.getItem("gender") === "FEMALE"
                      ? "#008dc9"
                      : "#ff6584"
                    : "#ff6584"
                }`}
                handleDiameter={22}
                width={35}
                onChange={(e) => {
                  if (e) changeLanguage("en");
                  else changeLanguage("ar");
                }}
                checked={language === "en" ? true : false}
              />
              <div className="font-semibold ">
                {language === "ar" ? "عربي" : "EN"}
              </div>
            </div>
            <hr />
            <li
              onClick={() => setShow(true)}
              className={`px-3 py-2 ${
                sessionStorage.getItem("gender") === "FEMALE"
                  ? "hover:text-Main"
                  : "hover:text-Secondary"
              } cursor-pointer flex items-center justify-start gap-2 transition-all duration-300`}>
              <>
                <CiLogout size={23} />
                <li className=" font-semibold">{content.logout}</li>
              </>
            </li>
          </ul>
        ) : (
          <ul className="">
            {ListSideBarNoSign.map((e, i) => (
              <React.Fragment key={i}>
                <li className="px-3 py-2 my-1 hover:text-Main transition-all duration-300">
                  <NavLink
                    to={e.link || ""}
                    className="flex items-center justify-start gap-2">
                    {e.icon ? <e.icon size={25} /> : ""}
                    <div className="font-semibold">{e.name}</div>
                  </NavLink>
                </li>
                {e.br && <hr />}
              </React.Fragment>
            ))}
            <div className="flex items-center gap-1 justify-center mt-4">
              <div className="text-Pink font-semibold ">
                {language === "ar" ? "EN" : "عربي"}
              </div>
              <Switch
                height={15}
                uncheckedIcon
                checkedIcon
                offColor="#cab8b8"
                onColor="#cab8b8"
                offHandleColor={
                  checkToken
                    ? sessionStorage.getItem("gender") === "FEMALE"
                      ? "#008dc9"
                      : "#ff6584"
                    : "#ff6584"
                }
                onHandleColor={
                  checkToken
                    ? sessionStorage.getItem("gender") === "FEMALE"
                      ? "#008dc9"
                      : "#ff6584"
                    : "#ff6584"
                }
                activeBoxShadow={`0 0 1px 1px ${
                  checkToken
                    ? sessionStorage.getItem("gender") === "FEMALE"
                      ? "#008dc9"
                      : "#ff6584"
                    : "#ff6584"
                }`}
                handleDiameter={22}
                width={35}
                onChange={(e) => {
                  if (e) changeLanguage("en");
                  else changeLanguage("ar");
                }}
                checked={language === "en" ? true : false}
              />
              <div className="text-Pink font-semibold">
                {language === "ar" ? "عربي" : "EN"}
              </div>
            </div>
          </ul>
        )}
      </div>
      <div
        className={open ? "bg-black/10 fixed top-0 left-0 w-full h-full " : ""}
      />
    </div>
  );
};

export default SideBar;
