import React from "react";
import userDef from "../../images/icon-user-default.png";
const ImageUser = ({
  image,
  className,
  id,
  isOnline,
  isChat,
  onClick,
  imgspa,
}) => {
  return (
    <div className="relative">
      {imgspa ? (
        <span
          className={`w-5 h-5 rounded-full border  absolute left-0 bottom-0 ${
            isOnline
              ? "border-gray-300 bg-green-500"
              : "border-white bg-gray-300"
          }`}></span>
      ) : (
        ""
      )}
      <img
        src={image || userDef}
        alt=""
        className={`rounded-full cursor-pointer max-lg:w-18 max-lg:h-18 max-sm:w-14 max-sm:h-14 my-2 ${
          className || "w-20 h-20"
        }`}
        onClick={onClick}
      />
    </div>
  );
};

export default ImageUser;
