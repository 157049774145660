import { content } from "../../Context/translate";
import { language } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";

function Data({ name, text, number, className }) {
  return (
    <div className={`font-bold text-Main ${className}`}>
      <span>{name}</span>

      <span
        className={`font-semibold text-Secondary flex-1 relative ${
          language === "ar" ? "mr-2" : "ml-2"
        }`}>
        {text}
      </span>

      {number && (
        <span className="font-normal text-Secondary flex-1 f-number">
          {number}
        </span>
      )}
    </div>
  );
}
export default Data;
