import React, { createContext, useContext, useState } from "react";

const ContextChat = createContext({});

const ContextChatProvider = ({ children }) => {
  const [newMessage, setNewMessage] = useState({});
  const [chatDelelete, setChatDelelete] = useState();
  const [messageDelete, setMessageDelete] = useState();
  const [messageRead, setMessageRead] = useState();
  const [isIgnoring, setIsIgnoring] = useState();
  const [isCaring, setIsCaring] = useState();
  const [isActive, setIsActive] = useState();
  const [loadingCounter, setLoadingCounter] = useState(0);

  return (
    <ContextChat.Provider
      value={{
        setNewMessage,
        newMessage,
        chatDelelete,
        setChatDelelete,
        messageRead,
        setMessageRead,
        messageDelete,
        setMessageDelete,
        isCaring,
        isIgnoring,
        setIsIgnoring,
        setIsCaring,
        isActive,
        setIsActive,
        loadingCounter,
        setLoadingCounter,
      }}>
      {children}
    </ContextChat.Provider>
  );
};

export default ContextChatProvider;

export const useContextChat = () => {
  return useContext(ContextChat);
};
