import { Col } from "../../Tools/Grid-system";
import NavSearch from "./NavSearch";
import { Outlet } from "react-router-dom";

const Search = () => {
  return (
    <Col md={11} className="mx-auto">
      <NavSearch />
      <Outlet />
    </Col>
  );
};

export default Search;
