function TextArea({ className, name, value, onChange, text, title }) {
  return (
    <div className="my-1 ">
      <div className=" text-lg font-bold text-Secondary mb-2">{text}</div>
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        placeholder={title}
        className={`border border-[#B3B3B3] outline-[#2684FF]  py-3 rounded-xl block w-full px-3 h-[150px] ${className}`}
      />
    </div>
  );
}
export default TextArea;
