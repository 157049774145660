import { content } from "../../Context/translate";
import hero from "../../images/heroh.svg";

const HeroSection = () => {
  return (
    <>
      <div className={`max-sm:h-[50vh] h-[100vh]     `}>
        <div className="h-[60%] max-sm:h-[100%] max-sm:flex max-sm:justify-center max-sm:items-center w-full hero flex justify-around items-center px-8 ">
          <div className={`flex flex-col gap-2 text-center ml-8 max-md:ml-0`}>
            <h1
              className="text-5xl max-sm:text-2xl font-bold max-sm:text-center text-Secondary"
              data-aos="fade-up"
              data-aos-anchor-placement="center-center"
              data-aos-once
              data-aos-duration="1000">
              {content.QiranBook}
            </h1>
            <h2
              className="text-3xl max-sm:text-xl max-lg:text-md font-normal text-Secondary my-2 "
              data-aos="fade-down"
              data-aos-anchor-placement="center-center"
              data-aos-once
              data-aos-duration="1000">
              {content.siteDesc}
            </h2>
            <h2
              className="text-3xl max-sm:text-xl max-lg:text-md font-normal text-Secondary "
              data-aos="fade-left"
              data-aos-anchor-placement="center-center"
              data-aos-once
              data-aos-duration="1000">
              {content.explaination}
            </h2>
          </div>

          <img
            src={hero}
            alt=""
            className="w-[30%] h-[90%] max-sm:w-[50%] max-md:h-full"
          />
        </div>
      </div>
    </>
  );
};

export default HeroSection;
