import { useEffect } from "react";
import { useContextChat } from "../../Context/ContextChat";
import { Col } from "../../Tools/Grid-system";
import QuickSearchHome from "../Home/QuickSearchHome";

import Members from "../Members";
import Page1 from "../PersonalFile/HeadersProfile";
import NotificationsHome from "./NotificationsHome";

function HomeUser({ data, isLoading }) {
  return (
    <Col md={11} className="mx-auto">
      <>
        <Page1 data={data?.data?.data} isLoading={isLoading} />
        <br />
        <NotificationsHome data={data?.data?.notifications} />
        <br />
        <QuickSearchHome className="pt-1" />
        <br />
        <Members
          link="active"
          category="/active"
          title="LastEnteredMembers"
          notFilters={true}
          isHome={false}
        />
        <Members
          link="new"
          category="/new"
          title="NewMembers"
          notFilters={true}
          isHome={false}
        />
        <Members
          link="online"
          category="/online"
          title="OnlineMembers"
          notFilters={true}
          isHome={false}
        />
        <Members
          link="health-conditions"
          category="/health-status"
          title="HealthConditions"
          notFilters={true}
          isHome={false}
        />
      </>
    </Col>
  );
}
export default HomeUser;
