import { useEffect } from "react";
import { Col } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import HeadersProfile from "./HeadersProfile";
import InfoUsers from "./InfoUsers";

function PersonalFile({ data, isLoading }) {
  useEffect(() => {
    localStorage.setItem("nationality", data?.data?.data?.nationality?.id);
    localStorage.setItem("country", data?.data?.data?.country?.id);
  }, [data?.data?.data]);

   if (!data&& isLoading) return <Loading />;

  return (
    <>
      <Col md={11} className="mx-auto space-y-8 ">
        <Col>
          <HeadersProfile data={data?.data?.data} />
        </Col>
        <Col>
          <InfoUsers data={data?.data?.data} />
        </Col>
      </Col>
    </>
  );
}
export default PersonalFile;
