import React from "react";
import nofound from "../../images/msg923693489-432.jpg";
import { content } from "../../Context/translate";

const NotFound = ({ text }) => {
  return (
    <div className="flex-col mt-4 mb-4 mx-auto  ">
      <div className="mx-auto">
        <img
          src={nofound}
          alt=""
          className="w-1/3 h-1/3  rounded-full mx-auto "
        />
      </div>
      <p className="text-center text-2xl text-Secondary mt-4 font-semibold">
        {text || content.NoResults}
      </p>
    </div>
  );
};

export default NotFound;
