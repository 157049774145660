import { useParams } from "react-router-dom";
import { useContextChat } from "../../Context/ContextChat";
import { useEffect, useState } from "react";
import MessageRecipientOption from "./MessageRecipientOption";
import { language } from "../../Tools/APIs";

const MessageRecipient = ({ message, userId }) => {
  const { messageDelete } = useContextChat();
  const [ShowMessage, setShowMessage] = useState(true);
  useEffect(() => {
    setShowMessage(+messageDelete === +message?.id ? false : true);
  }, [messageDelete]);
  return (
    <>
      {ShowMessage && (
        <div
          className={`max-w-[60%] min-w-[90px] w-fit bg-slate-400 px-2 pt-1 ${
            language === "ar"
              ? "mr-auto rounded-tl-none"
              : "ml-auto rounded-tr-none"
          } relative text-white rounded-md `}>
          <span
            className={`absolute bg-black top-0 ${
              language === "ar"
                ? "-left-[16px] border_chat2-ar"
                : "-right-[16px] border_chat2-en"
            }`}
          />
          <div
            className={`flex items-start justify-between gap-3 ${
              language === "ar" ? "flex-row-reverse" : ""
            }`}>
            <div col={1} className="-ml-2">
              <MessageRecipientOption
                content={message.content}
                messageId={message.id}
                userId={userId}
              />
            </div>
            <pre
              className="whitespace-pre-wrap"
              style={{ wordBreak: "break-word" }}>
              {message.content}
            </pre>
          </div>
          <div className="">
            <div
              className={`f_number text-[12px] ${
                language === "ar" ? "text-end" : "text-start"
              } `}>
              {new Date(message.created_at).toLocaleTimeString()}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default MessageRecipient;
