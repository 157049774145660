import { useContextHook } from "../../Context/ContextOPen";
import { useFETCH } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import SwiperProfileImage from "../SwiperProfileImage/SwiperProfileImage";

const PopUpImages = () => {
  const { showUserImages, setShowUserImages } = useContextHook();

  const { data, isLoading } = useFETCH(
    showUserImages[1] ? "v1/users/show/" + showUserImages[0] : ""
  );

  const images = data?.data?.data?.images;

  return (
    <>
      {showUserImages[1] && (
        <div className="flex justify-center items-center">
          <div
            onClick={() => setShowUserImages(["", false])}
            className="fixed w-full h-full top-0 left-0 bg-black bg-opacity-50 z-[101] "></div>
          <SwiperProfileImage images={images} isLoading={isLoading} />
        </div>
      )}
    </>
  );
};

export default PopUpImages;
