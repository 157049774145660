import { Link, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { fileUrl, useFETCH } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import { PrintHTML, Promotion } from "../../components";
import Loading from "../../Tools/Loading";
import { content } from "../../Context/translate";
function SingleBlog() {
  const { id, sectionId } = useParams();

  const { data, isLoading } = useFETCH(
    `v1/blog/sections/${sectionId}/posts/${id}`
  );

  if (isLoading) return <Loading />;

  return (
    <div>
      <>
        <Row className="items-center sm:px-10 relative">
          <Col xs={6}>
            <p className="font-semibold max-sm:text-center text-lg text-Secondary my-3 leading-8  max-sm:font-normal text-gradient max-sm:text-sm">
              {data?.data?.data?.title}
            </p>
          </Col>
          <Col xs={6}>
            <img
              src={fileUrl + data?.data?.data?.image}
              alt=""
              className="mx-auto md:mr-8 h-[300px] max-md:h-[230px] object-contain"
            />
          </Col>
        </Row>
        <div className="px-2x">
          <PrintHTML text={data?.data?.data?.content} />
        </div>
        <Swiper
          spaceBetween={30}
          slidesPerView={3}
          breakpoints={{
            1020: {
              slidesPerView: 3,
            },
            767: {
              slidesPerView: 2,
            },
            0: {
              slidesPerView: 1,
            },
          }}
          className="my-5">
          {data?.data?.data?.related_posts?.map((e, i) => (
            <SwiperSlide key={i}>
              <div className="border rounded-lg w-full overflow-hidden">
                <div className="h-[220px] w-full">
                  <img
                    src={fileUrl + e.image}
                    alt=""
                    className="w-full h-full"
                  />
                </div>
                <p className="font-semibold md:text-lg text-Secondary my-3 left-7 text-gradient px-2">
                  {e.title}
                </p>
                <p className="max-md:text-sm p-2">
                  <div className="text-end">
                    <Link to={`/blog/${sectionId}/${e.id}`}>
                      <span className="text-Secondary text-sm font-bold underline mx-2">
                        {content.ReadMore}
                      </span>
                    </Link>
                  </div>
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </>
      <Promotion />
    </div>
  );
}
export default SingleBlog;
