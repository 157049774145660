import { Col, Row } from "../../Tools/Grid-system";
import { Cards } from "../../components";
import not from "../../images/notImages.png";

const NotFoundImages = ({ title }) => {
  return (
    <div>
      <>
        <Row justify="center">
          <Col>
            <Cards>
              <img src={not} alt="" className="mx-auto" />
              <h1 className="font-semibold text-2xl mt-5 text-center">
                {title}
              </h1>
            </Cards>
          </Col>
        </Row>
      </>
    </div>
  );
};

export default NotFoundImages;
