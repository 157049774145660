import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useListPage } from "../../Context/listSignUp";
import { content } from "../../Context/translate";
import { checkToken, gender, useClose, useFilter } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import { ButtonMain, Cards, Selects, SliderMUI } from "../../components";
import { FaFilter } from "react-icons/fa";
import { useSearch } from "../../pages/Home/QuickSearchHome";

const PopUpQuickSearch = ({ className, total }) => {
  const { mouse, open, setOpen } = useClose();
  const { handleParamsDeleteAll, searchParams, handleParamsClick } =
    useFilter();

  const [value, setValue] = useState([18, 70]);
  const { quickSearch } = useListPage(true);
  const { setDataSearch, dataSearch, handleChangeSelect, queryString } =
    useSearch();
  const [searchFor, setSearchFor] = useState("");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (!checkToken) {
      setSearchFor(gender);
      sessionStorage.setItem("gender", gender);
      setDataSearch({
        ...dataSearch,
        gender: gender === "FEMALE" ? "MALE" : "FEMALE",
      });
    }
  }, [gender]);

  const handleChangeSelectFinal = (selected, active) => {
    handleChangeSelect(selected, active);
    handleParamsClick(active.name, selected.value);
  };

  useEffect(() => {
    handleParamsDeleteAll();
  }, []);

  useEffect(() => {
    handleParamsClick("age_more_than", value[0]);
    handleParamsClick("age_less_than", value[1]);
  }, [value]);

  return (
    <>
      {open && (
        <div className="bg-black/20 fixed top-0 left-0 w-full h-full z-[61]" />
      )}
      <div ref={mouse} className="rounded-2xl">
        <ButtonMain
          className=""
          onClick={() => setOpen(!open)}
          title={
            <div className="flex items-center gap-2 max-sm:text-sm">
              {content.Filter}
              <FaFilter />
            </div>
          }
        />
        {open && (
          <div className="fixed h-[85vh] overflow-scroll rounded-2xl chat md:top-[60%] top-[55%] left-1/2 -translate-y-1/2 -translate-x-1/2 w-[90%] md:w-1/2 z-[62]">
            <form className="">
              <Row justify="center">
                <Col>
                  <Cards className="">
                    <div
                      onClick={() => setOpen(!open)}
                      className="w-fit sticky top-0 right-full z-30 text-left text-2xl font-semibold px-3 py-1 rounded-full hover:text-white cursor-pointer hover:bg-red-600 transition-all duration-300  ">
                      X
                    </div>
                    {!checkToken && (
                      <div className="rounded-xl text-sm text-center overflow-hidden w-fit mx-auto mb-4 space-x-3">
                        <button
                          onClick={() => {
                            handleParamsClick("gender", "MALE");
                            sessionStorage.setItem("gender", "FEMALE");
                            setSearchFor("FEMALE");
                            setDataSearch({
                              ...dataSearch,
                              gender: "MALE",
                            });
                          }}
                          className={`${
                            searchFor === "FEMALE"
                              ? "text-white bg-Secondary"
                              : "text-Secondary bg-white"
                          } transition-all duration-300 font-semibold rounded-xl text-sm text-center p-3`}>
                          {content.forHusband}
                        </button>
                        <button
                          onClick={() => {
                            handleParamsClick("gender", "FEMALE");
                            sessionStorage.setItem("gender", "MALE");
                            setSearchFor("MALE");
                            setDataSearch({
                              ...dataSearch,
                              gender: "FEMALE",
                            });
                          }}
                          className={`${
                            searchFor === "MALE"
                              ? "text-white bg-Main"
                              : "text-Main bg-white"
                          } transition-all duration-300 font-semibold rounded-xl text-sm text-center p-3`}>
                          {content.forWife}
                        </button>
                      </div>
                    )}
                    <Row className="sm:justify-center">
                      {quickSearch?.map((e, i) => (
                        <Col md={6} key={i}>
                          <Selects
                            name={e.name}
                            text={e.title}
                            value={dataSearch?.[e.name]}
                            onChange={handleChangeSelectFinal}
                            options={e.options}
                          />
                        </Col>
                      ))}
                      <Col md={6} col={11} className="self-start">
                        <SliderMUI
                          className="text-xl max-md:text-lg font-bold"
                          title={content.Age}
                          unit={content.years}
                          max={70}
                          min={18}
                          value={value}
                          onChange={handleChange}
                          name="age"
                        />
                      </Col>
                    </Row>
                    <div className="flex mt-5">
                      <div
                        onClick={() => setOpen(false)}
                        className={` px-4 w-fit mx-auto mb-5 transition-all font-semibold py-2 text-white cursor-pointer bg-Main flex items-center justify-center rounded-lg rounded-br-lg gap-2`}>
                        {content.View + (total ? total : "")}
                      </div>
                      <div
                        onClick={() => setOpen(false)}
                        className={` px-4 w-fit mx-auto mb-5 transition-all font-semibold   py-2 text-white cursor-pointer bg-Secondary flex items-center justify-center rounded-lg rounded-br-lg gap-2  `}>
                        {content.CancelAll}
                      </div>
                    </div>
                  </Cards>
                </Col>
              </Row>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default PopUpQuickSearch;
