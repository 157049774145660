import { language } from "../Tools/APIs";
import Translate from "./translate.json";

const headerElements = ["h1", "h2", "h3", "h4", "h5", "h6"];

const changeFont = (array, font) => {
  array.map((e) => {
    document.querySelectorAll(e).forEach((element) => {
      element.style.fontFamily = font;
    });
  });
};

export let direction = language === "ar" ? "rtl" : "ltr";

export let content = {};

export const changeLanguage = (lang) => {
  window.localStorage.setItem("language", lang);
  window.location.reload(false);
};

if (language === "ar") {
  content = Translate.ar;
  document.body.style.direction = "rtl";
  document.querySelector("body").style.fontFamily = "Tajawal";
  changeFont(headerElements, "system-ui");
} else {
  content = Translate.en;
  document.body.style.direction = "ltr";
  document.querySelector("body").style.fontFamily = "Acumin";
  changeFont(headerElements, "Mayriad");
}
