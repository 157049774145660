import Cards from "../Cards/Cards";
import ButtonMain from "../Buttons/ButtonMain";
import { content } from "../../Context/translate";

const PopUp = ({ title, onClickFalse, onClick, show }) => {
  return (
    <>
      {show !== 0 && show && (
        <>
          <div
            onClick={onClickFalse}
            className="fixed w-full h-full top-0 left-0 bg-black/20 z-[61] "></div>
          <Cards className="!fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-10 z-[62]">
            <h1 className="text-3xl font-bold text-center text-Main ">
              {title}
            </h1>
            <div className="flex justify-around gap-5 mt-10 ">
              <ButtonMain
                title={content.yes}
                onClick={() => {
                  onClick();
                  onClickFalse();
                  title === content.sureLogout && sessionStorage.clear();
                }}
                className="mx-3 my-2 !px-11 !py-2 bg-blue-500 !text-white !text-lg !font-bold hover:bg-blue-400 cursor-pointer hover:!text-white"
              />
              <ButtonMain
                title={content.cancel}
                onClick={onClickFalse}
                className="mx-3 my-2 !px-11 !py-2 bg-red-500 !text-white !text-lg !font-bold hover:bg-red-400 cursor-pointer hover:!text-white"
              />
            </div>
          </Cards>
        </>
      )}
    </>
  );
};

export default PopUp;
