import "./style.css";
export const Col = ({
  md,
  lg,
  sm,
  xl,
  xs,
  col,
  children,
  className,
  ref,
  style,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      style={style}
      ref={ref}
      className={`col-${col} col-lg-${lg} col-md-${md} col-xs-${xs} mb-2 col-sm-${sm} col-xl-${xl} ${className}`}>
      {children}
    </div>
  );
};

export const Row = ({ children, className, gap, justify, style, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={style}
      className={`row ${"gap-" + gap} justify-${justify} ${className}`}>
      {children}
    </div>
  );
};
export const Container = ({ children, className }) => {
  return (
    <section className={"py-2 " + className}>
      <div className="w-[90%] mx-auto  ">{children}</div>
    </section>
  );
};
