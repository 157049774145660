import { Link, useParams } from "react-router-dom";
import { FaFacebook, FaTwitter, FaWhatsapp } from "../../Context/exportIcons";
import { useFETCH } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import { PrintHTML } from "../../components";
import { content } from "../../Context/translate";
import Loading from "../../Tools/Loading";
function SingleQuestion({ type }) {
  const { sectionId, id } = useParams();
  // const { data, isLoading } = useFetch(
  //   `v1/faqs-sections/${sectionId}/faqs/${id}`
  // );
  const { data, isLoading } = useFETCH(
    `v1/faqs-sections/${sectionId}/faqs/${id}`
  );

  if (isLoading) return <Loading />;

  return (
    <div className="">
      <Row>
        <Col md={11} className="mx-auto">
          <h1 className="mb-10 text-Main  font-bold text-center text-3xl ">
            {type + " »"} {data?.data?.data?.name}
          </h1>
        </Col>
        <Col md={11} className="mx-auto shadow-md shadow-Main rounded-2xl">
          <div className="bg-white rounded-xl flex flex-col gap-6 p-6">
            <div className="flex gap-3 max-sm:flex-col">
              <p className="text-2xl font-bold">{content.Answer} </p>
              <PrintHTML
                text={data?.data?.data?.content}
                className="font-medium text-lg"
              />
            </div>

            {/* <div className="flex justify-between items-center !mt-16 border-b-2 border-Main pb-5">
              <p className="lg:text-xl text-lg font-semibold text-Secondary">
                {content.ShareViaSocialMediaLinks}
              </p>
              <div className="flex items-center gap-3">
                <FaWhatsapp size={35} className="text-[#1BD741]" />
                <FaFacebook size={35} className="text-[#3A559F]" />
                <FaTwitter size={35} className="text-[#50ABF1]" />
              </div>
            </div> */}
            <div className="bg-[#FDFCFC] py-1 gap-3 mx-auto flex items-center justify-between max-sm:flex-col">
              <p className="lg:text-xl text-lg font-bold text-Main">
                {content.DoYouHaveOtherQuestions}
              </p>
              <Link to="/contact-us">
                <div className="bg-Secondary text-white font-semibold rounded-full px-5 py-2">
                  {content.ContactUs}
                </div>
              </Link>
            </div>
            {/* <h3 className="text-2xl font-bold text-Main">
              {content.RelatedQuestions}
            </h3>
            <div className="flex items-center gap-2">
              <div className="!w-2 !h-2 rounded-full bg-Main" />
              <p className="lg:text-lg">{content.DoYouHaveOtherQuestions}</p>
            </div> */}
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default SingleQuestion;
