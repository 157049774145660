import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContextHook } from "../../../Context/ContextOPen";
import { useListPage } from "../../../Context/listSignUp";
import { Col, Container, Row } from "../../../Tools/Grid-system";
import { ButtonMain, Cards, Selects, TextArea } from "../../../components";
import { useNext } from "./Page2";
import { content } from "../../../Context/translate";
import { checkIsSearchSelect } from "../../../Context/functions";
import Process from "../../../components/Process/Process";

const Page4 = () => {
  const gender = sessionStorage.getItem("gender") === "FEMALE";
  const { listPage4 } = useListPage();
  const navigate = useNavigate();
  const [local, setLocal] = useState({
    health_status_id: sessionStorage.getItem("health_status_id") || "",
    smoking_status_id: sessionStorage.getItem("smoking_status_id") || "",

    [gender ? "hijab_status_id" : "beard_status_id"]:
      (gender
        ? sessionStorage.getItem("hijab_status_id")
        : sessionStorage.getItem("beard_status_id")) || "",
    prayer_status_id: sessionStorage.getItem("prayer_status_id") || "",
    religiosity_status_id:
      sessionStorage.getItem("religiosity_status_id") || "",
    own_props: sessionStorage.getItem("own_props") || "",
    future_wife_props: sessionStorage.getItem("future_wife_props") || "",
  });
  const { setListError } = useContextHook();
  const handleChange = (event) => {
    const { name, value } = event.target;
    const valueFile = value;
    setLocal((prevLocal) => ({
      ...prevLocal,
      [name]: valueFile,
    }));
  };
  const handleChangeSelect = (selected, active) => {
    setLocal((prevLocal) => ({
      ...prevLocal,
      [active.name]: Array.isArray(selected)
        ? selected.map((e) => e.value)
        : selected.value,
    }));
  };
  const { error } = useNext(local);
  return (
    <div className="mb-12">
      <Container>
        <Row className="items-center">
          <Col md={8} className="mx-auto">
            <Col md={8} className="mx-auto !py-6">
              <p
                className={`${
                  sessionStorage.getItem("gender") === "FEMALE"
                    ? "text-Forth"
                    : "text-Fifth"
                } mx-auto text-center text-3xl font-bold`}
              >
                {sessionStorage.getItem("gender") === "FEMALE"
                  ? content.Registerawife
                  : content.Registerapair}
              </p>
            </Col>
            <Process />
            <Cards className="mt-10">
              <Col sm={12} className="mx-auto">
                <Row className="">
                  {listPage4?.map((e) => (
                    <Col md={6}>
                      <Selects
                        name={e.name}
                        text={e.title}
                        value={local?.[e.name]}
                        onChange={handleChangeSelect}
                        options={e.options}
                      />
                    </Col>
                  ))}
                  <Col className="text-Main text-xl font-semibold pt-6 ">
                    {content.AboutMe}
                  </Col>
                  <Col className="mx-auto">
                    <TextArea
                      name="own_props"
                      value={local.own_props}
                      onChange={handleChange}
                      text={content.SignUpTextAreaDesc}
                    />
                  </Col>
                  <Col className="text-Main text-xl font-semibold pt-6">
                    {content.SpecificationsDesc}
                  </Col>
                  <Col className="mx-auto">
                    <TextArea
                      name="future_wife_props"
                      value={local.future_wife_props}
                      onChange={handleChange}
                      text={content.SignUpTextAreaDesc}
                    />
                  </Col>
                </Row>
              </Col>
              <div className="flex justify-between mt-5">
                <ButtonMain
                  className="w-[150px] bg-Secondary"
                  title={content.Previous}
                  onClick={() => window.history.go(-1)}
                />
                <ButtonMain
                  onClick={() => {
                    if (error.length > 0) {
                      setListError([...error]);
                    } else {
                      navigate("/auth/sign-up/page-5");
                    }
                  }}
                  className="w-[150px]"
                  title={content.Next}
                />
              </div>
            </Cards>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Page4;
