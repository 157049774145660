import { useState } from "react";
import { ageVoid } from "../../Context/functions";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs";
import { Col } from "../../Tools/Grid-system";
import Pagination from "../../Tools/Pagination";
import { Cards, ImageUser } from "../../components";
import NotFoundImages from "./NotFoundImages";
import { useContextHook } from "../../Context/ContextOPen";
import { content } from "../../Context/translate";
import Loading from "../../Tools/Loading";
import { avatars } from "../../Context/lists";

const RoleImages = () => {
  const { setShowPopUpUser } = useContextHook();

  const { data, isLoading } = useFETCH(
    "v1/users/profile/picture/privilege/users"
  );
  if (isLoading) return <Loading />;

  return (
    <div>
      <>
        {data?.data?.data?.total !== 0 && (
          <Col md={12} className="mx-auto">
            <Pagination
              pageCount={Math.ceil(
                data?.data?.data?.total / data?.data?.data?.per_page
              )}>
              <Cards>
                <div className="flex items-center justify-between text-Secondary font-bold text-xl mb-4 px-5">
                  <p>{content.MemberName}</p>
                  <p>{content.Action}</p>
                </div>
                {data?.data?.data?.data.map((e) => (
                  <Card
                    key={e.login_details.id}
                    image={
                      e.images.length > 0
                        ? fileUrl + e.images[e.images.length - 1].image
                        : e?.avatar_id
                        ? avatars.filter(
                            (element) => element.id === e.avatar_id
                          )[0]?.img
                        : ""
                    }
                    name={e.secret_data?.name}
                    status={e.social_status?.social_status?.name}
                    age={e.social_status?.age}
                    id={e?.login_details?.id}
                    from={e.nationality_residence?.city?.name}
                    time={e.pivot_created_at}
                    onClick={() => setShowPopUpUser([e.login_details.id, true])}
                  />
                ))}
              </Cards>
            </Pagination>
          </Col>
        )}
        {!data?.data?.data?.total && !isLoading && (
          <NotFoundImages title={content.noMemberSeePhoto} />
        )}
      </>
    </div>
  );
};

export default RoleImages;

const Card = ({ image, name, status, age, from, time, id, onClick }) => {
  const { handleSubmit } = usePOST();
  const [success, setSuccess] = useState(true);
  const { setMessagesSuccess } = useContextHook();
  return (
    <div className="flex items-center justify-between px-4 gap-4">
      <div
        className="flex gap-5 items-center cursor-pointer flex-1"
        onClick={onClick}>
        <ImageUser id={id} image={image} className="w-12 h-12" />
        <div>
          <p>
            <span className="font-bold">{name}</span> - {status} -{" "}
            {ageVoid(age)} - {from}
          </p>
        </div>
      </div>
      <div>
        <div
          onClick={() =>
            handleSubmit(
              success
                ? `v1/users/${id}/profile/hide/picture`
                : `v1/users/${id}/profile/show/picture`,
              "",
              true
            ).then(() => {
              setSuccess(!success);
              setMessagesSuccess([
                success
                  ? "The user has been blocked from seeing your photo"
                  : "The user has been allowed to see your photo",
              ]);
            })
          }
          className={`${
            success ? "bg-Main" : "bg-slate-300"
          } !w-6 !h-6 rounded-tr-lg rounded-bl-lg border-[3px] border-slate-300 cursor-pointer`}
        />
      </div>
    </div>
  );
};
