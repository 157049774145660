import { useEffect, useState } from "react";
import { useContextHook } from "../../Context/ContextOPen";
import { content } from "../../Context/translate";
import { useFETCH, usePOST } from "../../Tools/APIs";
import AddFile from "../../Tools/AddFile";
import Loading from "../../Tools/Loading";

const Myphotos = ({ id }) => {
  const { data: dataOld, isLoading } = useFETCH(id && `v1/users/show/${id}`);

  const {
    setFormData,
    viewImages,
    setViewImages,
    handleChangeArrayImages,
    handleSubmit,
    setImages,
    formData,
    loading,
    counter,
    setCounter,
    successfulPost,
    images,
  } = usePOST({});

  const [userImages, setUserImages] = useState(dataOld?.data?.data?.images);
  useEffect(() => {
    setUserImages(dataOld?.data?.data?.images);
  }, [dataOld?.data?.data?.images]);

  const { setMessagesSuccess } = useContextHook();
  useEffect(() => {
    if (successfulPost) {
      setImages({});
      setMessagesSuccess([content.UpdatePhotosLibrary]);
      setCounter(0);
      setFormData({});
    }
  }, [successfulPost]);

  return (
    <div className="relative">
      {(isLoading || loading) && <Loading />}
      <AddFile
        type="IMAGES"
        name="images"
        title={content.imageOptional}
        viewImages={viewImages}
        setViewImages={setViewImages}
        setFormData={setFormData}
        onChange={handleChangeArrayImages}
        images={userImages}
        setUserImages={setUserImages}
        setImagesToSend={setImages}
        formData={formData}
        counter={counter}
        setCounter={setCounter}
        imagesToSend={images}
      />

      <button
        className="bg-Main p-4 rounded-2xl text-white font-semibold mt-4"
        onClick={() => {
          handleSubmit("v1/profile/update?_method=PUT", "", "", true);
        }}>
        {content.Save}
      </button>
    </div>
  );
};

export default Myphotos;
