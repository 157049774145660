import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { checkToken, fileUrl } from "../../Tools/APIs";
import "./styles.css";

// import required modules
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

import MembersCard from "../Cards/MembersCard";
import LoadingMembersCard from "../Cards/LoadingMembersCard";
import { Col } from "../../Tools/Grid-system";
import { avatars } from "../../Context/lists";

function SectionSwiper({ data, category, isLoading }) {
  return (
    <>
      <div className=" rounded-2xl">
        <Swiper
          slidesPerView={3}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper  custom-swiper !p-3"
          breakpoints={{
            1020: {
              slidesPerView: checkToken ? 2 : 3,
            },
            767: {
              slidesPerView: checkToken ? 1 : 2,
            },
            0: {
              slidesPerView: 1,
            },
          }}
        >
          {(isLoading ? [1, 2, 3, 4] : data?.slice(0, 7))?.map((e, i) => (
            <SwiperSlide key={i}>
              <Col key={e.id} className="!p-3">
                {isLoading ? (
                  <LoadingMembersCard />
                ) : (
                  <MembersCard
                    isOnline={e?.is_online}
                    id={e?.login_details?.id}
                    image={
                      e.images.length > 0
                        ? fileUrl + e.images[e.images.length - 1].image
                        : e?.avatar_id
                        ? avatars.filter((ele) => ele.id === e?.avatar_id)[0]
                            .img
                        : ""
                    }
                    isCaring={e.is_caring}
                    name={e?.login_details?.username}
                    age={e.social_status?.age}
                    status={
                      category === "/health-status"
                        ? e.study_work?.health_status?.name
                        : e.social_status?.social_status?.name
                    }
                    city={e.nationality_residence?.city?.name}
                    job={e.study_work?.job?.name}
                    user={e}
                    country={e.nationality_residence?.country?.name}
                  />
                )}
              </Col>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}

export default SectionSwiper;
