import { useEffect, useState } from "react";
import { BsBell } from "../../Context/exportIcons";
import { content } from "../../Context/translate";
import { useFETCH, useFilter } from "../../Tools/APIs";
import { Col } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import Pagination from "../../Tools/Pagination";
import { Cards, Notification, TitlePage } from "../../components";

function Notifications({ propsData }) {
  const { searchParams } = useFilter();
  const {
    data: fetchdData,
    isLoading,
    setIsLoading,
  } = useFETCH(
    searchParams.get("page")
      ? `v1/notifications?page=${searchParams.get("page")}`
      : null
  );

  const [data, setData] = useState(null);

  useEffect(() => {
    if (searchParams.get("page")) {
      setData(fetchdData?.data);
    } else {
      setData({ data: propsData?.notifications });
      setIsLoading(false);
    }
  }, [propsData, fetchdData]);
  if (!data && isLoading) return <Loading />;

  return (
    <>
      <Pagination
        pageCount={Math.ceil(data?.data?.total / data?.data?.per_page)}>
        <Col md={11} className="mx-auto">
          <Cards className="mt-1">
            <TitlePage title="Notifications" />
            {data?.data?.data?.map((e, i) => (
              <Notification
                state={e?.state}
                isActive={propsData?.data?.is_active}
                hasRead={e.has_read}
                key={i}
                userId={e.model_id}
                title={e?.state === 5 ? e?.title : e?.description}
                description={e?.state === 5 && e?.description}
                text={
                  e?.created_at ? new Date(e?.created_at).toLocaleString() : ""
                }>
                <div
                  className={`${
                    e.has_read ? "bg-gray-300" : "bg-red-200"
                  } rounded-full p-2 text-Main`}>
                  <BsBell size={25} />
                </div>
              </Notification>
            ))}
            {data?.data?.data?.data?.length === 0 && !isLoading ? (
              <div className="flex justify-center items-center h-[30vh] text-xl font-semibold">
                {content.NoNotifications}
              </div>
            ) : (
              ""
            )}
          </Cards>
        </Col>
      </Pagination>
    </>
  );
}
export default Notifications;
