import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Checked } from ".";
import useRegisterData from "../../Context/registerData";
import { useFETCH, usePOST } from "../../Tools/APIs";
import { Col } from "../../Tools/Grid-system";
import { ButtonMain, Cards } from "../../components";
import { content } from "../../Context/translate";
import { checkIsSearchSelect } from "../../Context/functions";
import Loading from "../../Tools/Loading";
const Chat = () => {
  // const { data, isLoading } = useFetch("v1/settings");
  const { data, isLoading } = useFETCH("v1/settings");
  const [active, setActive] = useState({
    chat_allowed_countries: "",
    chat_allowed_nationalities: "",
  });

  const { registerData } = useRegisterData(sessionStorage.getItem("gender"));
  const { handleSubmit, setFormData, loading, formData, handleChangeSelect } =
    usePOST({});
  const handleSubmitMain = () => {
    handleSubmit("v1/settings/chat/update?_method=PUT", "", "", true);
  };

  useEffect(() => {
    setFormData({
      chat_allowed_countries: data?.data?.data?.chat_allowed_countries,
      chat_allowed_nationalities: data?.data?.data?.chat_allowed_nationalities,
      option_chat_allowed_countries:
        registerData?.countries
          ?.filter((item) => {
            const itemIds = item.id.toString();
            return data?.data?.data?.chat_allowed_countries
              ?.split(",")
              .includes(itemIds);
          })
          .map((e) => {
            return { value: e.id, label: e.name };
          }) || "",
      option_chat_allowed_nationalities:
        registerData?.nationalities
          ?.filter((item) => {
            const itemIds = item.id.toString();
            return data?.data?.data?.chat_allowed_nationalities
              ?.split(",")
              .includes(itemIds);
          })
          .map((e) => {
            return { value: e.id, label: e.name };
          }) || "",
    });
    setActive({
      chat_allowed_nationalities:
        data?.data?.data?.chat_allowed_nationalities === "ALL" ||
        data?.data?.data?.chat_allowed_nationalities === "SAME_NATIONALITY"
          ? data?.data?.data?.chat_allowed_nationalities
          : "Choose1",
      chat_allowed_countries:
        data?.data?.data?.chat_allowed_countries === "ALL" ||
        data?.data?.data?.chat_allowed_countries === "SAME_COUNTRY"
          ? data?.data?.data?.chat_allowed_countries
          : "Choose2",
    });
  }, [data?.data?.data, registerData]);
  const list = [
    {
      titleMain: content.nationalitiesOfTheMembersIAllowToWriteToMe,
    },
    {
      title: content.allowAllNationalities,
      value: "ALL",
      name: "chat_allowed_nationalities",
    },
    {
      title: content.ofTheSameNationalityAsMe,
      value: "SAME_NATIONALITY",
      name: "chat_allowed_nationalities",
    },
    {
      title: content.chooseNationalities,
      value: "Choose1",
      name: "chat_allowed_nationalities",
    },
    {
      value: "Choose1",
      name: "chat_allowed_nationalities",
      options: registerData?.nationalities,
    },
    {
      titleMain: content.selectTheCountriesOfTheMembersAllowedToWriteToYou,
    },
    {
      title: content.allowAllCountries,
      value: "ALL",
      name: "chat_allowed_countries",
    },
    {
      title: content.fromTheSameCountriesAsMe,
      value: "SAME_COUNTRY",
      name: "chat_allowed_countries",
    },
    {
      title: content.chooseCountries,
      value: "Choose2",
      name: "chat_allowed_countries",
    },
    {
      value: "Choose2",
      name: "chat_allowed_countries",
      options: registerData?.countries,
    },
  ];

  if (isLoading || loading) return <Loading />;

  return (
    <div>
      <Col md={12} className="mx-auto pt-5">
        <Cards>
          {list.map((e, i) =>
            e.titleMain ? (
              <h1 key={i} className="text-2xl font-bold my-4">
                {e.titleMain}
              </h1>
            ) : e.options ? (
              active?.[e.name] === e.value && (
                <Select
                  key={i}
                  isMulti
                  isSearchable={checkIsSearchSelect(e.name)}
                  className="w-full bg-[#E7E7E7] mt-2 rounded-lg text-center"
                  name={e.name}
                  value={formData?.["option_" + e.name]}
                  onChange={handleChangeSelect}
                  options={e.options?.map((e) => {
                    return { value: e.id, label: e.name };
                  })}
                />
              )
            ) : (
              <>
                <Checked
                  key={i}
                  active={e.value === active?.[e.name]}
                  onClick={() => {
                    setFormData((prev) => ({
                      ...prev,
                      [e.name]: e.value,
                    }));
                    setActive((prev) => ({
                      ...prev,
                      [e.name]: e.value,
                    }));
                  }}
                  title={e.title}
                />
              </>
            )
          )}
          <div className="w-fit mr-auto">
            <ButtonMain
              title={content.Save}
              className="!px-14 mt-10"
              onClick={handleSubmitMain}
            />
          </div>
        </Cards>
      </Col>
    </div>
  );
};

export default Chat;
