import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";
import { clickZoomInImage, fileUrl } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";

export default function SwiperProfileImage({ images, isLoading }) {
  return (
    <>
      <Swiper
        navigation={true}
        modules={[Navigation]}
        className="!fixed !top-[50%] w-[30%] max-md:!w-[50%] h-[30%] !z-[102] flex justify-center items-center bg-slate-300 -translate-y-1/2 rounded-xl">
        {isLoading ? (
          <Loading />
        ) : (
          images?.map((e) => (
            <SwiperSlide className="flex justify-center items-center w-[90%] ">
              <img
                src={fileUrl + e?.image}
                alt=""
                className="object-contain w-full h-full cursor-pointer"
                onClick={clickZoomInImage}
              />
            </SwiperSlide>
          ))
        )}
      </Swiper>
    </>
  );
}
