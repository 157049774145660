import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { content } from "../../Context/translate";
import { checkToken, fileUrl, useFETCH } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import { ButtonMain } from "../../components";
import ddd from "../../images/2443aad60db12636446f 1.png";
import google from "../../images/a4c4ac5e92bd4e2f57f6.png";
import app from "../../images/af4ee7777207b062d970.png";
import { Ads } from "../../layout/SideBarUser/SideBarUser";
import HomeUser from "../HomeUser";
import HeroSection from "./HeroSection";
import Page2 from "./QuickSearchHome";
import RoutsSection from "./RoutsSection";
import UsersSection from "./UsersSection";

const Home = ({ propsData, isLoading }) => {
  useEffect(() => {
    AOS.init({
      // Whether to animate elements only once
    }); // Initialize AOS
  }, []);
  const { data: dataHome, isLoading: isLoadingHome } = useFETCH(`v1/home`);

  const blog = dataHome?.data?.data?.blog_posts[0];

  const { data: appData } = useFETCH("v1/info/all");

  const appLinks = [
    {
      name: "google",
      image: google,
      link: appData?.data?.data && appData?.data?.data["google_play_url"],
    },
    {
      name: "app",
      image: app,
      link: appData?.data?.data && appData?.data?.data["app_store_url"],
    },
  ];

  return (
    <Col className="overflow-hidden">
      {checkToken ? (
        <HomeUser data={propsData} isLoading={isLoading} />
      ) : (
        <>
          <HeroSection />
          <Page2 />
          <div className="sm:mt-0 md:-mt-40">
            <RoutsSection />
            <UsersSection
              link="/new"
              isLoading={isLoadingHome}
              title={content.NewMembers}
              data={dataHome?.data?.data?.new_users}
            />
            <UsersSection
              link="/active"
              isLoading={isLoadingHome}
              title={content.LastEnteredMembers}
              data={dataHome?.data?.data?.activeUsers}
            />
            <UsersSection
              link="/online"
              isLoading={isLoadingHome}
              title={content.Online}
              data={dataHome?.data?.data?.online_users}
            />
            <UsersSection
              category="/health-status"
              link="/health-conditions"
              isLoading={isLoadingHome}
              title={content.HealthConditions}
              data={dataHome?.data?.data?.healthStatus}
            />

            <Row className="bg-gradient-to-r from-[#D86A82] to-[#8F6398] !py-8 !p-4 !px-16 !mt-14">
              <Col
                md={8}
                className="text-xl max-sm:text-lg text-white space-y-3">
                <h2 className="text-3xl max-sm:text-xl font-semibold">
                  {content.QiranBookDesc}
                </h2>
                <li className="list-disc">{content.UsersNumber}</li>
                <li className="list-disc">{content.InstantNotification}</li>
                <li className="list-disc">{content.AppDesc}</li>
                <p>{content.AppRate}</p>
                <div className="flex justify-start max-sm:justify-center items-center gap-3 !mt-10">
                  {appLinks.map((e) => (
                    <a key={e.name} href={e.link}>
                      <img src={e.image} alt={e.name} width={150} />
                    </a>
                  ))}
                </div>
              </Col>
              <Col md={4}>
                <div className="relative -top-16">
                  <img
                    src={ddd}
                    alt=""
                    className="object-contain h-[330px] max-md:hidden relative bottom-1/3"
                    data-aos="fade-right"
                    data-aos-once
                  />
                </div>
              </Col>
            </Row>
            <div className="container mx-auto text-center my-8">
              <div className="flex justify-center gap-2">
                <div className="w-1 bg-Main h-8"></div>
                <h1 className="text-3xl font-bold text-center text-Secondary">
                  {content.QiranBookBlog}
                </h1>
              </div>
              <Col
                md={9}
                className=" mt-12  gap-4  relative flex justify-center mx-auto">
                <div className="w-[95%] mx-auto bg-white rounded-xl overflow-hidden shadow-lg min-h-[200px]">
                  <div className="w-full md:flex h-full">
                    <div className="md:w-3/4">
                      <img
                        className="w-full h-full object-cover"
                        src={fileUrl + blog?.image}
                        alt=""
                      />
                    </div>
                    <div className="md:w-1/4 flex flex-col justify-center px-6 py-4 bg-gradient-to-r from-[#8F6398] to-[#d86a82] text-white">
                      <div className="h-full w-full">
                        <div className="font-bold text-xl mb-2 h-full w-full max-lg:text-sm flex items-center">
                          {blog?.title}
                        </div>
                      </div>
                      <Link
                        to={`/blog/${blog?.section_id}/${blog?.id}`}
                        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-xl">
                        {content.ReadMore}
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>

              <ButtonMain
                link="/blog"
                title={content.ViewMore}
                className="border-Main w-fit hover:bg-Main hover:text-white mx-auto text-2xl mt-12"
              />
            </div>
          </div>
        </>
      )}
      {isLoading || isLoadingHome ? null : (
        <Col col={10} className="mx-auto max-h-[70vh]">
          <Ads posistion="home" />
        </Col>
      )}
    </Col>
  );
};

export default Home;
