import React, { useEffect } from "react";
import { Col, Row } from "../../Tools/Grid-system";
import { useLocation } from "react-router-dom";
import { gender } from "../../Tools/APIs";

const Process = () => {
  const { pathname } = useLocation();
  const pageNumber = pathname.substring(pathname.length - 1);

  return (
    <Col md={8} className="flex justify-center mx-auto">
      <Row>
        <Col
          sm={12}
          className="flex justify-center items-center max-md:gap-2  ">
          <div
            className={`w-16 h-16  rounded-full flex justify-center items-center ${
              pageNumber >= 2 ? "bg-Fifth" : "bg-blue-200"
            }  
                  `}>
            <div className={` text-4xl font-semibold ${pageNumber >= 2 ? "text-white" : "text-black"}`}>1</div>
          </div>
          <div
            className={` w-[100px] h-2 max-md:hidden ${
              pageNumber >= 2 ? "bg-Fifth" : "bg-blue-200"
            }`}>
            {" "}
          </div>
          <div
            className={`w-16 h-16  rounded-full flex justify-center items-center ${
              pageNumber >= 3 ? "bg-Fifth" : "bg-blue-200"
            }  
                  `}>
            <div className={` text-4xl font-semibold ${pageNumber >= 3 ? "text-white" : "text-black"}`} >2</div>
          </div>
          <div
            className={` w-[100px] h-2 max-md:hidden ${
              pageNumber >= 3 ? "bg-Fifth" : "bg-blue-200"
            }`}>
            {" "}
          </div>
          <div
            className={`w-16 h-16  rounded-full flex justify-center items-center ${
              pageNumber >= 4 ? "bg-Fifth" : "bg-blue-200"
            }  
                  `}>
            <div className={` text-4xl font-semibold ${pageNumber >= 4 ? "text-white" : "text-black"}`}>3</div>
          </div>
          <div
            className={` w-[100px] h-2 max-md:hidden ${
              pageNumber >= 4 ? "bg-Fifth" : "bg-blue-200"
            }`}>
            {" "}
          </div>
          <div
            className={`w-16 h-16  rounded-full flex justify-center items-center ${
              pageNumber >= 5 ? "bg-Fifth" : "bg-blue-200"
            }  
                  `}>
            <div className={` text-4xl font-semibold ${pageNumber >= 5 ? "text-white" : "text-black"}`}>4</div>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default Process;
