import { useEffect, useRef, useState } from "react";
import { useContextChat } from "../../Context/ContextChat";
import { IoMdSend } from "../../Context/exportIcons";
import { Col, Row } from "../../Tools/Grid-system";
import { content as transContent } from "../../Context/translate";
import { language, useFETCH } from "../../Tools/APIs";
import { useContextHook } from "../../Context/ContextOPen";
import Loading from "../../Tools/Loading";

const MessagesBottom = ({ id, data }) => {
  const { isIgnoring, setIsIgnoring } = useContextChat();
  const { setMessagesSuccess } = useContextHook();
  const { isLoading, deleteIgnore, successfulDeleteIgnore } = useFETCH(
    "",
    `v1/ignore/${id}/delete`
  );
  const dataAll = data?.data?.data;
  const [paddingBottom, setPaddingBottom] = useState("0px");
  const { setNewMessage } = useContextChat();
  const [show, setShow] = useState(false);
  const [content, setContent] = useState("");
  const textarea = useRef();
  const dateTime = new Date();

  useEffect(() => {
    if (dataAll) setIsIgnoring(dataAll.is_ignoring);
  }, []);

  useEffect(() => {
    if (successfulDeleteIgnore) {
      setIsIgnoring(false);
      setMessagesSuccess([transContent.MessageUnblocked]);
    }
  }, [successfulDeleteIgnore]);

  const sendMessage = () => {
    // messages.unshift({
    //   sendMessage: true,
    //   is_sent_by_auth_user: true,
    //   content: content,
    //   created_at: `${dateTime.toJSON()}`,
    // });

    // messages = [...messages,{
    //   sendMessage: true,
    //   is_sent_by_auth_user: true,
    //   content: content,
    //   created_at: `${dateTime.toJSON()}`,
    // }]

    setNewMessage({
      sendMessage: true,
      is_sent_by_auth_user: true,
      content: content,
      created_at: `${dateTime.toJSON()}`,
      key: Date.now(),
    });

    setContent("");
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (isIgnoring) setShow(true);
      else sendMessage();
    }
  };
  const handleFocus = () => {
    // Assuming the keyboard height to be 50px in this example
    const keyboardHeight = 200;
    setPaddingBottom(`${keyboardHeight}px`);
  };

  const handleBlur = () => {
    setPaddingBottom("200px"); // Reset to default
  };

  return (
    <>
      {isLoading && <Loading />}
      <div
        className={`sticky bottom-0 md:h-[8vh] max-md:h-[12vh] max-md:bottom-[${paddingBottom}] ${
          show && "z-30"
        }`}>
        {show && (
          <div>
            <div
              onClick={() => setShow(false)}
              className={`
          fixed w-full h-full top-0 left-0 bg-black/60 z-[61] flex justify-center items-center overflow-x-auto`}></div>
            <div className=" fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white z-[62] rounded-3xl w-[350px] max-w-[350px] mx-2 min-h-[150px] ">
              <p className="font-bold text-2xl text-center py-5 max-sm:text-lg text-Main">
                {transContent.UnblockTheUser}
              </p>
              <div className="flex justify-center gap-4 items-center">
                <button
                  onClick={() =>
                    deleteIgnore(id).then(() => {
                      setShow(false);
                    })
                  }
                  className="transition-all px-5 py-2 text-lg bg-blue-500 hover:bg-blue-400 text-white font-semibold  rounded-md">
                  {transContent.yes}
                </button>
                <button
                  onClick={() => setShow(false)}
                  className="transition-all px-5 py-2 text-lg hover:bg-red-400 text-white  bg-red-500 font-semibold  rounded-md">
                  {transContent.cancel}
                </button>
              </div>
            </div>
          </div>
        )}
        <Row className={`px-4 !m-0 py-1 bg-slate-100 items-center h-full `}>
          <Col col={11} className="flex justify-center items-center md:h-[80%]">
            <textarea
              onFocus={handleFocus}
              onBlur={handleBlur}
              ref={textarea}
              onKeyDown={handleKeyDown}
              name="content"
              value={content}
              placeholder={transContent.WriteMessage}
              onChange={(e) => setContent(e.target.value)}
              className={`w-full h-full chat outline-1 outline-Main border-2 border-Secondary bg-white py-1 px-4 rounded-lg text-Secondary font-semibold text-md  ${
                language === "ar" ? "rtl" : "ltr"
              }`}></textarea>
          </Col>
          <Col col={1}>
            <div className="flex justify-center items-center h-full w-full">
              <IoMdSend
                size={25}
                onClick={() => {
                  if (isIgnoring) {
                    setShow(true);
                    return;
                  } else sendMessage();
                }}
                className={`cursor-pointer w-full text-Main hover:text-Secondary ${
                  language === "ar" ? "rotate-180" : ""
                }`}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MessagesBottom;
