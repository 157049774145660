import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col } from "../../Tools/Grid-system";
import { ButtonMain, Cards } from "../../components";
import { content } from "../../Context/translate";

function CreateToken() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    localStorage.removeItem("token");
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      localStorage.setItem("token", token);
    }, 4000);
  }, [token]);
  return (
    <Col md={10} className="mx-auto py-3">
      <Cards
        className="h-[50vh] flex justify-center items-center"
        isLoading={loading}>
        {!loading && token ? (
          <div>
            <div className="text-xl font-semibold transition-all">
              {content.YouHaveBeenLoggedInSuccessfully}
            </div>
            <div>
              <ButtonMain
                title={content.GoToTheHomePage}
                className="w-fit mx-auto my-2"
                onClick={() => {
                  navigate("/");
                  window.location.reload();
                }}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </Cards>
    </Col>
  );
}
export default CreateToken;
