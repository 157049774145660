import { ageVoid, childrenVoid } from "../../Context/functions";
import { content } from "../../Context/translate";
import { Col, Row } from "../../Tools/Grid-system";
import { Cards, DataCol } from "../../components";

function InfoUsers({ data }) {
  return (
    <>
      <Cards className="px-4">
        <Row justify={"center"} className="mx-auto  ">
          <p className="font-bold jus text-Main text-xl my-6 max-md:text-center ">
            {content.HousingAndMaritalStatus}
          </p>
          <Col md={4} xs={4} className="mx-auto mt-6  ">
            <DataCol
              text={data?.nationality_residence?.nationality?.name}
              name={content.Nationality}
            />
          </Col>
          <Col md={4} xs={4} className="mx-auto ">
            <DataCol
              text={data?.nationality_residence?.city?.name}
              name={content.City}
            />
          </Col>
          <Col md={4} xs={4}>
            <DataCol
              text={
                <>
                  {ageVoid(data?.social_status?.age) +
                    " — " +
                    data?.social_status?.social_status?.name}
                  <br />
                  {childrenVoid(+data?.social_status?.children)}
                </>
              }
              name={content.FamilyStatus}
            />
          </Col>
          <Col md={4} xs={4}>
            <DataCol
              text={data?.religious_commitment?.religiosity_status?.name}
              name={content.Religious}
            />
          </Col>
          <Col md={4} xs={4}>
            <DataCol
              text={data?.religious_commitment?.prayer_status?.name}
              name={content.Prayer}
            />
          </Col>
          <Col md={4} xs={4}>
            <DataCol
              text={data?.social_status?.marriage_status?.name}
              name={content.TypeOfMarriage}
            />
          </Col>
          <p className="font-bold jus text-Main text-xl my-6 max-md:text-center">
            {content.AppearanceAndHealth}
          </p>
          <Col md={4} xs={4}>
            <DataCol
              text={data?.my_specifications?.skin_color?.name}
              name={content.SkinColor}
            />
          </Col>
          <Col md={4} xs={4}>
            <DataCol
              text={
                data?.my_specifications?.height +
                content.cm +
                " , " +
                data?.my_specifications?.weight +
                content.kg
              }
              name={content.HeightAndWeight}
            />
          </Col>
          <Col md={4} xs={4}>
            <DataCol
              text={data?.study_work?.financial_status?.name}
              name={content.PhysicalCondition}
            />
          </Col>
          <Col md={4} xs={4}>
            <DataCol
              text={data?.study_work?.health_status?.name}
              name={content.HealthStatus}
            />
          </Col>
          <Col md={4} xs={4}>
            <DataCol
              text={data?.religious_commitment?.smoking_status?.name}
              name={content.Smoking}
            />
          </Col>
          <Col md={4} xs={4}>
            {data?.gender === "MALE" ? (
              <DataCol
                text={data?.religious_commitment?.beard_status?.name}
                name={content.Beard}
              />
            ) : (
              <DataCol
                text={data?.religious_commitment?.hijab_status?.name}
                name={content.Hijab}
              />
            )}
          </Col>
          <p className="font-bold jus text-Main text-xl my-6 max-md:text-center">
            {content.EducationAndWork}
          </p>
          <Col md={4} xs={4}>
            <DataCol
              text={data?.study_work?.educational_level?.name}
              name={content.EducationQualification}
            />
          </Col>
          <Col md={4} xs={4}>
            <DataCol
              text={data?.study_work?.job?.name}
              name={content.JobTital}
            />
          </Col>
          <Col md={4} xs={4}>
            <DataCol
              text={data?.study_work?.employment_status?.name}
              name={content.Employment}
            />
          </Col>
          <Col md={4} xs={4}>
            <DataCol
              text={data?.study_work?.monthly_income_range?.name}
              name={content.MonthlyIncome}
            />
          </Col>
          <Col md={4} xs={4}>
            <DataCol
              text={data?.my_specifications?.physique_status?.name}
              name={content.PhysicalCondition}
            />
          </Col>
          <Col md={4} xs={4}>
            <DataCol text="Self-employed" name={content.ScopeOfWork} />
          </Col>
          <Col md={4} xs={4}>
            <DataCol text={data?.own_props} name={content.MySpecifications} />
          </Col>
          <Col md={4} xs={4}>
            <DataCol
              text={data?.future_wife_props}
              name={content.SpecificationsOfMyLifePartner}
            />
          </Col>
        </Row>
      </Cards>
    </>
  );
}
export default InfoUsers;
