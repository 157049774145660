import img from "../../images/qiranbloghome.png";
import { language, useFETCH } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
function AboutUs({ name }) {
  const { data, isLoading } = useFETCH("v1/info/all");

  return (
    <div>
      <>
        <Row>
          <Col md={10} className="mx-auto">
            <h1 className="text-3xl text-Main font-bold my-6 text-center">
              {name}
            </h1>
            <div className="bg-white rounded-xl shadow-2xl p-4 space-y-5 overflow-hidden">
              <img
                src={img}
                alt=""
                className="h-[40vh] w-full object-contain"
              />
              <p>
                {language === "ar"
                  ? data?.data?.data?.about_us_ar
                  : data?.data?.data?.about_us_en}
              </p>
            </div>
          </Col>
        </Row>
      </>
    </div>
  );
}
export default AboutUs;
