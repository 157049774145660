import { Link } from "react-router-dom";
import {
  BsBell,
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "../../Context/exportIcons";
import { content } from "../../Context/translate";
import { Col, Row } from "../../Tools/Grid-system";
import { Notification, TitlePage } from "../../components";
import { language, useFETCH } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";

function NotificationsHome({ data: notifications, isLoading }) {
  return (
    <>
      <TitlePage title="Notifications" />
      <Row>
        <Col className="flex justify-end">
          <Link
            to="/profile/notifications"
            className={`${
              language === "ar" ? "link-title-ar" : "link-title-en"
            } flex items-center text-xl self-end max-sm:text-sm font-semibold hover:underline text-center hover:text-Main text-Secondary`}>
            {content.ViewAll}
            {language === "ar" ? (
              <MdOutlineArrowBackIosNew size={17} />
            ) : (
              <MdOutlineArrowForwardIos size={17} />
            )}
          </Link>
        </Col>
      </Row>
      {notifications?.total === 0 ? (
        <div className="flex justify-center items-center h-[15vh] text-xl font-semibold">
          {content.NoNotifications}
        </div>
      ) : isLoading ? (
        <Loading />
      ) : (
        notifications?.data?.length > 0 &&
        notifications?.data?.slice(0, 5)?.map((e) => (
          <Notification
            hasRead={e.has_read}
            key={e.model_id}
            userId={e?.model_id}
            title={e?.state === 5 ? e?.title : e?.description}
            text={e?.created_at ? new Date(e?.created_at).toLocaleString() : ""}
            description={e.state === 5 && e?.description}
            state={e?.state}>
            <div
              className={`${
                e.has_read ? "bg-gray-300" : "bg-red-200"
              } rounded-full p-2 text-Main`}>
              <BsBell size={25} />
            </div>
          </Notification>
        ))
      )}
    </>
  );
}
export default NotificationsHome;
