import React, { useEffect } from "react";
import { Heart, ImageUser } from "../../components";
import { useContextHook } from "../../Context/ContextOPen";
import { content } from "../../Context/translate";
import { fileUrl, language, useFETCH } from "../../Tools/APIs";
import Forbidden from "../../components/PopUps/Forbidden";
import { avatars } from "../../Context/lists";
import { useContextChat } from "../../Context/ContextChat";
const NavChatBottom = ({ id }) => {
  const { setIsCaring, setIsIgnoring } = useContextChat();
  const { data, isLoading } = useFETCH(id && "v1/users/show/" + id);
  const dataAll = data?.data?.data;

  useEffect(() => {
    setIsCaring(dataAll?.is_caring);
    setIsIgnoring(dataAll?.is_ignoring);
  }, [dataAll]);

  const { setShowBottomSheet, setShowPopUpUser } = useContextHook();
  return (
    <div
      className={`bg-white h-[10vh] px-5 py-2 sticky top-0 z-20 w-full flex items-center border-b-2 border-gray-300`}>
      <div className={`flex w-full items-center justify-between  `}>
        <div className={`flex gap-5 items-center `}>
          <ImageUser
            image={
              dataAll?.images.length > 0
                ? fileUrl + dataAll?.images[dataAll?.images.length - 1].image
                : dataAll?.avatar_id
                ? avatars.filter((e) => e.id === dataAll?.avatar_id)[0].img
                : ""
            }
            id={id}
            className="w-12 h-12"
            onClick={() => setShowPopUpUser([id, true])}
            imgspa={true}
            isOnline={dataAll?.is_online}
          />
          {!isLoading && (
            <div className={`font-semibold`}>
              <div className="">{dataAll?.secret_data?.name}</div>
              <div>
                {dataAll?.is_online ? (
                  <span className="text-green-500 text-xs opacity-75">
                    {content.Online}
                  </span>
                ) : (
                  <span className="text-red-500 text-[11px] opacity-75">
                    {dataAll?.last_active_at
                      ? dataAll?.last_active_at
                      : content.Offline}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
        <div className={`flex gap-3 items-center `}>
          {!isLoading && (
            <>
              <Heart
                id={id}
                className="!bg-slate-100 rounded-full w-7 h-7"
                size={20}
                check={dataAll?.is_caring}
              />
              <Forbidden
                id={id}
                className=" rounded-full !w-7 !h-7 "
                size={25}
              />
            </>
          )}
          <button
            onClick={() => setShowBottomSheet(["", false])}
            className=" rounded-full w-7 h-7 flex justify-center items-center bg-red-500 hover:bg-red-400 hover:!text-white">
            <span className="text-lg text-white font-bold">x</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavChatBottom;
