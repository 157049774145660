import React from "react";
import { checkToken, fileUrl } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import Pagination from "../../Tools/Pagination";
import {
  LoadingMembersCard,
  MembersCard,
  SectionSwiper,
} from "../../components";
import { Ads } from "../../layout/SideBarUser/SideBarUser";
import NotFound from "../../components/NotFound/NotFound";
import { useLocation } from "react-router-dom";
import { avatars } from "../../Context/lists";

const DataMapMembers = ({ isLoading, data, category }) => {
  const { pathname } = useLocation();
  const dataSwiper = data?.data;

  if (dataSwiper?.length === 0 && !isLoading) return <NotFound />;

  return (
    <div className=" relative">
      <>
        {pathname === "/online" ||
        pathname === "/new" ||
        pathname === "/active" ||
        pathname === "/health-conditions" ||
        pathname === "/members" ? (
          <Pagination pageCount={Math.ceil(data?.total / data?.per_page)}>
            <Row>
              {(!data?.data ? [1, 2, 3, 4, 5, 6] : data?.data)?.map((e, i) => (
                <React.Fragment key={i}>
                  <Col lg={6} md={12} key={e.id} className="!p-3">
                    {!isLoading ? (
                      <MembersCard
                        isOnline={e?.is_online}
                        id={e?.login_details?.id}
                        image={
                          e?.images?.length > 0
                            ? fileUrl + e.images[e.images.length - 1].image
                            : e?.avatar_id
                            ? avatars.filter(
                                (ele) => ele.id === e?.avatar_id
                              )[0]?.img
                            : ""
                        }
                        isCaring={e.is_caring}
                        name={e?.login_details?.username}
                        age={e.social_status?.age}
                        status={
                          category === "/health-status"
                            ? e.study_work?.health_status?.name
                            : e.social_status?.social_status?.name
                        }
                        city={e.nationality_residence?.city?.name}
                        job={e.study_work?.job?.name}
                        user={e}
                        country={e?.nationality_residence?.country?.name}
                      />
                    ) : (
                      <LoadingMembersCard />
                    )}
                  </Col>
                  {(data?.data.length < 2 || i === 1) && (
                    <Ads posistion="between_users" className="!h-[50vh]" />
                  )}
                </React.Fragment>
              ))}
            </Row>
          </Pagination>
        ) : (
          <SectionSwiper
            data={dataSwiper}
            category={category}
            isLoading={isLoading}
          />
        )}
      </>
    </div>
  );
};

export default DataMapMembers;
