import { useState } from "react";
import { Col, Container, Row } from "../../../Tools/Grid-system";
import { ButtonMain, Cards, Input } from "../../../components";
import { useEffect } from "react";
import { useContextHook } from "../../../Context/ContextOPen";
import { useLOGIN, usePOST } from "../../../Tools/APIs";
import { useNext } from "./Page2";
import { content } from "../../../Context/translate";
import Process from "../../../components/Process/Process";
import { avatars } from "../../../Context/lists";
import AddImage from "../../../Tools/AddImage/AddImage";

function Page5() {
  const [viewFile, setViewFile] = useState("");

  const [avatarId, setAvatarId] = useState({});

  const [imageFile, setImageFile] = useState();

  const gender = sessionStorage.getItem("gender") === "FEMALE";
  const { setListError, listError } = useContextHook();
  const [local, setLocal] = useState({
    name: sessionStorage.getItem("name") || "",
    phone_number: sessionStorage.getItem("phone_number") || "",
    
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    const valueFile = value;
    setLocal((prevLocal) => ({
      ...prevLocal,
      [name]: valueFile,
    }));
  };

  const handleChangeInput = (event) => {
    const { files } = event.target;

    if (files.length > 0) {
      const file = files[0]; 
      setViewFile(URL.createObjectURL(file)); 
      setImageFile(file); 
      console.log("Selected file:", file);
    }
  };

  const { error } = useNext(local);

  const { setFormData, handleSubmit, loading } = useLOGIN();
  useEffect(() => {
    setFormData({
      username: sessionStorage.getItem("username"),
      email: sessionStorage.getItem("email"),
      password: sessionStorage.getItem("password"),
      country_id: +sessionStorage.getItem("country_id"),
      nationality_id: +sessionStorage.getItem("nationality_id"),
      city_id: +sessionStorage.getItem("city_id"),
      marriage_status_id: +sessionStorage.getItem("marriage_status_id"),
      social_status_id: +sessionStorage.getItem("social_status_id"),
      age: +sessionStorage.getItem("age"),
      children: +sessionStorage.getItem("children"),
      weight: +sessionStorage.getItem("weight"),
      height: +sessionStorage.getItem("height"),
      skin_color_id: +sessionStorage.getItem("skin_color_id"),
      physique_status_id: +sessionStorage.getItem("physique_status_id"),
      religiosity_status_id: +sessionStorage.getItem("religiosity_status_id"),
      prayer_status_id: +sessionStorage.getItem("prayer_status_id"),
      smoking_status_id: +sessionStorage.getItem("smoking_status_id"),
      [gender ? "hijab_status_id" : "beard_status_id"]: gender
        ? +sessionStorage.getItem("hijab_status_id")
        : +sessionStorage.getItem("beard_status_id"),
      educational_level_id: +sessionStorage.getItem("educational_level_id"),
      financial_status_id: +sessionStorage.getItem("financial_status_id"),
      employment_status_id: +sessionStorage.getItem("employment_status_id"),
      job: sessionStorage.getItem("job"),
      monthly_income_range_id: +sessionStorage.getItem(
        "monthly_income_range_id"
      ),
      health_status_id: +sessionStorage.getItem("health_status_id"),
      own_props: sessionStorage.getItem("own_props"),
      future_wife_props: sessionStorage.getItem("future_wife_props"),
      name: sessionStorage.getItem("name"),
      phone_number: +sessionStorage.getItem("phone_number"),
      job_id: +sessionStorage.getItem("job_id"),
      gender: sessionStorage.getItem("gender"),
      fcm_token: sessionStorage.getItem("fcm_token"),
      image: imageFile,
      // avatar_id: avatarId?.id ? avatarId?.id : null,
    });
  }, [
    sessionStorage.getItem("username"),
    sessionStorage.getItem("email"),
    sessionStorage.getItem("password"),
    sessionStorage.getItem("job_id"),
    sessionStorage.getItem("country_id"),
    sessionStorage.getItem("nationality_id"),
    sessionStorage.getItem("city_id"),
    sessionStorage.getItem("marriage_status_id"),
    sessionStorage.getItem("social_status_id"),
    sessionStorage.getItem("age"),
    sessionStorage.getItem("children"),
    sessionStorage.getItem("weight"),
    sessionStorage.getItem("height"),
    sessionStorage.getItem("skin_color_id"),
    sessionStorage.getItem("physique_status_id"),
    sessionStorage.getItem("religiosity_status_id"),
    sessionStorage.getItem("prayer_status_id"),
    sessionStorage.getItem("smoking_status_id"),
    sessionStorage.getItem("beard_status_id"),
    sessionStorage.getItem("hijab_status_id"),
    sessionStorage.getItem("educational_level_id"),
    sessionStorage.getItem("financial_status_id"),
    sessionStorage.getItem("employment_status_id"),
    sessionStorage.getItem("job"),
    sessionStorage.getItem("monthly_income_range_id"),
    sessionStorage.getItem("health_status_id"),
    sessionStorage.getItem("own_props"),
    sessionStorage.getItem("future_wife_props"),
    sessionStorage.getItem("name"),
    sessionStorage.getItem("phone_number"),
    sessionStorage.getItem("gender"),
   imageFile,
    // avatarId,
  ]);

  const handleSubmitFinal = () => {
    handleSubmit("v1/register");
  };

  return (
    <Container className="mb-12">
      <Row>
        <Col xl={7} className="mx-auto">
          <Col md={8} className="mx-auto !py-6">
            <p
              className={`${
                sessionStorage.getItem("gender") === "FEMALE"
                  ? "text-Forth"
                  : "text-Fifth"
              } mx-auto text-center text-3xl font-bold`}
            >
              {sessionStorage.getItem("gender") === "FEMALE"
                ? content.Registerawife
                : content.Registerapair}
            </p>
          </Col>
          <Process />
          <Cards isLoading={loading} className="mt-10">
            <Col md={11} className="mx-auto">
              <Row>
                <Col md={6} className="mx-auto">
                <div className="text-Secondary text-xl font-bold">  {content.ProfileImage} </div>
                  <AddImage
                  type={"IMAGES"}
                  title={content.AddProfileImage}
                    name="image"
                    newImage={viewFile}
                    onChange={handleChangeInput}
                    clickDeleteImage={() => {
                      setViewFile("");
                      setFormData(prev => ({ ...prev, image: null }));
                    }}
                  />
                </Col>
                <Col md={6} className="mx-auto">
                  <Input
                    text={content.FullName}
                    title={content.EnterTheFullName}
                    name="name"
                    value={local.name}
                    onChange={handleChange}
                  />
                </Col>
                <Col md={6} className="mx-auto">
                  <Input
                    type="number"
                    text={content.PhoneNumber}
                    title={content.PhoneNumber}
                    name="phone_number"
                    value={local.phone_number}
                    onChange={handleChange}
                  />
                </Col>
                {/* <Col>
                  <p className=" text-Secondary text-lg font-bold my-6">
                    {content.Chooseyourgraphicimage}
                  </p>
                  <Row justify={"between"} className="mx-auto py-4">
                    {avatars
                      .filter(
                        (e) => e.type === sessionStorage.getItem("gender")
                      )
                      .map((e) => (
                        <Col
                          lg={2}
                          md={3}
                          sm={5}
                          className={`${
                            avatarId.id === e.id
                              ? "bg-red-300 rounded-full border-6 border-red-300 "
                              : ""
                          } `}
                          key={e.id}
                        >
                          <img
                            src={e.img}
                            alt=""
                            onClick={() => setAvatarId({ ...e })}
                            className="cursor-pointer"
                          />
                        </Col>
                      ))}
                  </Row>
                </Col> */}
              </Row>
              {/* <div className="my-5 flex items-center">
                <input
                  type="checkbox"
                  name="You_must_read_the_oath" // ! => translate
                  onChange={handleChange}
                  className="mx-3 w-5 h-5"
                />
                <span className="text-Secondary text-lg font-semibold">
                  {content.IHaveTaken}
                </span>
              </div> */}

              <div className="flex justify-between">
                <ButtonMain
                  className="w-[150px] bg-Secondary"
                  title={content.Previous}
                  onClick={() => window.history.go(-1)}
                />
                <ButtonMain
                  onClick={() => {
                    // if (error.length > 0) {
                    //   setListError([...error]);
                    // } else {
                      handleSubmitFinal();
                    }
                  }
                  className="w-[150px]"
                  title={content.Send}
                />
              </div>
            </Col>
          </Cards>
        </Col>
      </Row>
    </Container>
  );
}
export default Page5;
