import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { fileUrl, useFETCH } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import Pagination from "../../Tools/Pagination";
import { Promotion } from "../../components";
import { content } from "../../Context/translate";
import Loading from "../../Tools/Loading";
function Blog() {
  const { data, isLoading } = useFETCH("v1/blog/sections?paginate=0");
  const [idBlog, setIdBlog] = useState(1);
  useEffect(() => {
    setIdBlog(data?.data?.data[0]?.id);
  }, [data?.data?.data]);

  if (isLoading) return <Loading />;

  return (
    <Col md={11} className="mx-auto ">
      <>
        <h1 className="font-bold text-3xl  text-Secondary  text-center mt-12 mb-12">
          {content.Categories}
        </h1>
        <div className="flex gap-3 overflow-x-auto ">
          {data?.data?.data?.map((e) => (
            <div
              onClick={() => {
                setIdBlog(e.id);
              }}
              className={`${
                idBlog === e.id
                  ? "bg-Main text-white shadow-sm shadow-Main"
                  : "text-Main bg-white "
              } min-w-[150px] flex justify-center items-center hover:bg-Main hover:text-white transition-all hover:shadow-sm  hover:shadow-Main  rounded-md px-5 py-3 font-semibold max-sm:text-sm max-sm:py-2 max-sm:px-3 mx-auto cursor-pointer `}>
              {e.name}
            </div>
          ))}
        </div>
        <h2 className="font-bold  text-3xl text-Secondary  text-center mb-12 mt-12">
          {content.Blogs}
        </h2>
      </>
      <Posts sectionId={idBlog} />
      <Promotion />
    </Col>
  );
}
export default Blog;

const Posts = ({ sectionId }) => {
  const { search } = useLocation();

  // const { data, isLoading } = useFetch(
  //   sectionId && `v1/blog/sections/${sectionId}/posts${search}`
  // );
  const { data, isLoading } = useFETCH(
    sectionId && `v1/blog/sections/${sectionId}/posts${search}`
  );
  return (
    <Pagination
      pageCount={Math.ceil(
        data?.data?.data.total / data?.data?.data?.per_page
      )}>
      <Row justify={"between"}>
        {isLoading ? (
          <>
            <Col md={4} xs={6}>
              <div className="rounded-lg h-[260px] bg-slate-200 animate-pulse" />
            </Col>
            <Col md={4} xs={6}>
              <div className="rounded-lg h-[260px] bg-slate-200 animate-pulse" />
            </Col>
            <Col md={4} xs={6}>
              <div className="rounded-lg h-[260px] bg-slate-200 animate-pulse" />
            </Col>
          </>
        ) : (
          data?.data?.data?.data?.map((e) => (
            <Col md={4} xs={6} sm={11} className="flex justify-between mx-4 ">
              <div className=" rounded-2xl shadow-md shadow-Main w-full overflow-hidden hover:scale-110 transition-all mt-8">
                <div className="h-[220px] w-full">
                  <img
                    src={fileUrl + e.image}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>
                <p className="font-semibold md:text-md text-Secondary my-3 left-7 text-gradient px-2 text-center">
                  {e.title.length > 50
                    ? e.title.substring(0, 60) + "..."
                    : e.title}
                </p>
                <p className="max-md:text-sm p-2">
                  <div className="text-end">
                    <Link to={`/blog/${sectionId}/${e.id}`}>
                      <span className="text-Secondary text-sm font-bold underline mx-2">
                        {content.ReadMore}
                      </span>
                    </Link>
                  </div>
                </p>
              </div>
            </Col>
          ))
        )}
      </Row>
    </Pagination>
  );
};
