import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { HashRouter } from "react-router-dom";
import ContextProvider from "./Context/ContextOPen";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import ContextChatProvider from "./Context/ContextChat";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
 
    <ContextChatProvider>
      <ContextProvider>
        <HashRouter>
          <App />
        </HashRouter>
      </ContextProvider>
    </ContextChatProvider>
  // </React.StrictMode>
);
