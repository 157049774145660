import { Sheet } from "react-modal-sheet";
import ChatsBottom from "../../pages/Chat/ChatsBottom";
import "./style.css";
import { language } from "../../Tools/APIs";
import { useContextChat } from "../../Context/ContextChat";

function BottomSheetChat({ id, isOpen, setShowBottomSheet }) {
  const { setNewMessage } = useContextChat();
  return (
    <>
      <Sheet
        isOpen={isOpen}
        onClose={() => {
          setNewMessage({});
          setShowBottomSheet(["", false]);
        }}
        className={`w-[400px] h-[70%] ${
          language === "ar"
            ? "md:!right-full md:translate-x-[500px]"
            : "md:!left-full  md:-translate-x-[500px]"
        }  mt-auto max-md:w-full max-md:h-[92vh] !z-[60]`}
        tweenConfig={{ ease: "linear", duration: 0.3 }}>
        <Sheet.Container className="!rounded-3xl">
          <Sheet.Header />
          <Sheet.Content>
            <ChatsBottom id={id} />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </>
  );
}
export default BottomSheetChat;
