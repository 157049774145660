import React from "react";
import { content } from "../../Context/translate";
import { Col } from "../../Tools/Grid-system";
import { useNavigate } from "react-router-dom";

const TitlePage = ({ title, md, desc, col, className, chat }) => {
  return (
    <div>
      <Col col={md ? 0 : col || 12} md={!col ? md || 12 : 0}>
        <div className="flex justify-start gap-2 items-center h-full">
          <div className="w-[4px] bg-Main h-10"></div>
          <h1
            className={`text-3xl max-sm:text-xl font-bold p-3 rounded-md text-Main text-center ${className}`}
          >
            {content[title]}
          </h1>
        </div>
        {content["Desc" + title] ? (
          <p className="text-xl max-sm:text-lg mt-4 font-bold text-Secondary mb-4">
            {(desc ? desc + " " : "") + content["Desc" + title]}
          </p>
        ) : (
          ""
        )}
      </Col>
    </div>
  );
};

export default TitlePage;
