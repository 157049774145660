import { Outlet } from "react-router-dom";
import { content } from "../../Context/translate";
import { NavFilterSearch, TitlePage } from "../../components";
import { Col } from "../../Tools/Grid-system";

const Settings = () => {
  return (
    <Col md={11} className="mx-auto">
      <TitlePage title="Settings" className="text-start text-4xl" />
      <div>
        <NavFilterSearch
          listNav={[
            { name: content.General, link: "/settings/" },
            { name: content.Chat, link: "chat" },
            { name: content.Notifications, link: "notifications" },
          ]}
        />
      </div>
      <Outlet />
    </Col>
  );
};

export default Settings;

export const Checked = ({ title, onClick, active }) => {
  return (
    <>
      <div onClick={onClick} className="flex justify-start items-center gap-5 ">
        <div
          className={`${
            active ? "bg-Main" : "bg-slate-300"
          } !w-6 !h-6 rounded-tr-lg rounded-bl-lg border-[3px] border-slate-300 cursor-pointer text-Secondary`}
        />
        <div className="text-lg text-opacity-60 font-bold mt-2 text-Secondary">
          {title}
        </div>
      </div>
    </>
  );
};
