import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContextHook } from "../../../Context/ContextOPen";
import { useListPage } from "../../../Context/listSignUp";
import { content } from "../../../Context/translate";
import {
  baseUrl,
  browserName,
  browserVersion,
  checkToken,
  language,
  usePOST,
} from "../../../Tools/APIs";
import { Col, Container, Row } from "../../../Tools/Grid-system";
import { ButtonMain, Cards, Input, Selects } from "../../../components";
import Select from "react-select";

import Process from "../../../components/Process/Process";
import AddImage from "../../../Tools/AddImage/AddImage";

const Page2 = () => {
  const { listPage2, listAges, listWeight, listChildren, listHeight } =
    useListPage();
  const navigate = useNavigate();
  const { setListError } = useContextHook();
  const [local, setLocal] = useState({
    username: sessionStorage.getItem("username") || "",
    email: sessionStorage.getItem("email") || "",
    password: sessionStorage.getItem("password") || "",
    marriage_status_id: sessionStorage.getItem("marriage_status_id") || "",
    social_status_id: sessionStorage.getItem("social_status_id") || "",
    age: sessionStorage.getItem("age") || "",
    weight: sessionStorage.getItem("weight") || "",
    height: sessionStorage.getItem("height") || "",
    children: sessionStorage.getItem("children") || 0,
    password_confirmation:
      sessionStorage.getItem("password_confirmation") || "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    const valueFile = value;
    setLocal((prevLocal) => ({
      ...prevLocal,
      [name]: valueFile,
    }));
  };
  const handleChangeSelect = (selected, active) => {
    setLocal((prevLocal) => ({
      ...prevLocal,
      [active.name]: Array.isArray(selected)
        ? selected.map((e) => e.value)
        : selected.value,
    }));
  };

  const { error } = useNext({
    ...local,
    password_mismatch: local?.password === local?.password_confirmation,
  });
  // =======================================================================> username
  const [typingTimeoutUserName, setTypingTimeoutUserName] = useState(null);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const handleChangeUserName = (event) => {
    setTypingTimeoutUserName(null);
    const { name, value } = event.target;
    setLocal((prevLocal) => ({
      ...prevLocal,
      [name]: value,
    }));
    sessionStorage.setItem([name], value);
    clearTimeout(typingTimeout);
    const newTypingTimeout = setTimeout(() => {
      handleSubmitUserName(
        baseUrl +
          "v1/register/validate?username=" +
          sessionStorage.getItem("username")
      );
    }, 1000);
    setTypingTimeout(newTypingTimeout);
  };
  const handleSubmitUserName = (url) => {
    axios
      .post(
        url,
        {},
        {
          headers: {
            Authorization: "Bearer " + checkToken,
            finger_print: browserName + " " + browserVersion,
            locale: language,
          },
        }
      )
      .then((response) => {})
      .catch((error) => {
        setTypingTimeoutUserName(error.response.data.message);
      });
  };




  // =======================================================================> email

  const [typingTimeoutEmail, setTypingTimeoutEmail] = useState(null);
  const [errorMessageEmail, setErrorMessageEmail] = useState(null);
  const handleChangeEmail = (event) => {
    setErrorMessageEmail(null);
    const { name, value } = event.target;
    setLocal((prevLocal) => ({
      ...prevLocal,
      [name]: value,
    }));
    sessionStorage.setItem([name], value);
    clearTimeout(typingTimeoutEmail);
    const newTypingTimeout = setTimeout(() => {
      handleSubmitEmail(
        baseUrl +
          "v1/register/validate?email=" +
          sessionStorage.getItem("email")
      );
    }, 1000);
    setTypingTimeoutEmail(newTypingTimeout);
  };
  const handleSubmitEmail = (url) => {
    axios
      .post(
        url,
        {},
        {
          headers: {
            Authorization: "Bearer " + checkToken,
            finger_print: browserName + " " + browserVersion,
            locale: language,
          },
        }
      )
      .then((response) => console.log())
      .catch((error) => {
        setErrorMessageEmail(error.response.data.message);
      });
  };
  return (
    <div className="mb-12 ">
      <Container>
        <Row className="items-center">
          <Col md={8} className="mx-auto !py-6">
            <p
              className={`${
                sessionStorage.getItem("gender") === "FEMALE"
                  ? "text-Forth"
                  : "text-Fifth"
              } mx-auto text-center text-3xl font-bold`}>
              {sessionStorage.getItem("gender") === "FEMALE"
                ? content.Registerawife
                : content.Registerapair}
            </p>
          </Col>
          <Process />
          <Cards className="mt-10 w-[70%] max-md:w-[90%] mx-auto">
            <Row justify="between">
              <div className="md:hidden w-full">
                <Col col={12}>
                  <Input
                    text={content.Username}
                    name="username"
                    title={content.Username}
                    value={local.username}
                    onChange={handleChangeUserName}
                  />
                  <div className="text-red-500 text-sm mt-2">
                    {typingTimeoutUserName}
                  </div>
                </Col>
                <Col col={11} className="mx-auto my-4">
                  <div
                    className={`bg-blue-200 relative p-5 ${
                      language === "ar" ? "pr-7" : "pl-7"
                    } rounded-lg`}>
                    <span
                      className={`border-r-transparent border-t-transparent border-b-blue-200 border-l-transparent border-[15px] absolute ${
                        language === "ar" ? "right-9" : "left-9"
                      }  -top-6`}></span>
                    <h2 className="font-semibold text-lg">
                      {content.Username}
                    </h2>
                    <ul className="marker:text-Fifth marker:text-xl list-disc">
                      <li className="text-gray-500">
                        {content.UsernameCondition1}
                      </li>
                      <li className="text-gray-500">
                        {content.UsernameCondition2}
                      </li>
                      <li className="text-gray-500">
                        {content.UsernameCondition3}
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col col={12}>
                  <Input
                    text={content.Email}
                    title={content.Email}
                    name="email"
                    value={local.email}
                    onChange={handleChangeEmail}
                  />
                  <div className="text-red-500 text-sm mt-2">
                    {errorMessageEmail}
                  </div>
                </Col>
              </div>
              <div className="flex justify-between items-center gap-5 max-md:hidden">
                <div className="flex-col flex w-[50%]">
                  {/* <Col col={12}>
                  <AddImage
                    name="image"
                    newImage={viewFile}
                    oldImage={userImage && fileUrl +  userImage}
                    clickDeleteOldImage={() => {
                      setUserImage('')
                    }}
                    onChange={handleChangeInput}
                    clickDeleteImage={() => {
                      setViewFile("");
                      setFormData({ ...formData, image: "" });
                    }}
                  />
                  </Col> */}
                  <Col col={12}>
                    <Input
                      text={content.Username}
                      name="username"
                      title={content.Username}
                      value={local.username}
                      onChange={handleChangeUserName}
                    />
                    <div className="text-red-500 text-sm mt-2">
                      {typingTimeoutUserName}
                    </div>
                  </Col>
                  <Col col={12}>
                    <Input
                      text={content.Email}
                      title={content.Email}
                      name="email"
                      value={local.email}
                      onChange={handleChangeEmail}
                    />
                    <div className="text-red-500 text-sm mt-2">
                      {errorMessageEmail}
                    </div>
                  </Col>
                </div>
                <Col md={5} className="flex justify-start">
                  <div
                    className={`bg-blue-200 relative p-5 ${
                      language === "ar" ? "pr-7" : "pl-7"
                    } rounded-lg`}>
                    <span
                      className={` border-t-transparent border-b-transparent  border-[15px] absolute ${
                        language === "ar"
                          ? "-right-6 border-l-blue-200 border-r-transparent"
                          : "-left-6 border-r-blue-200 border-l-transparent"
                      } top-9`}></span>
                    <h2 className="font-semibold text-lg">
                      {content.Username}
                    </h2>
                    <ul className="marker:text-Fifth marker:text-xl list-disc">
                      <li className="text-gray-500">
                        {content.UsernameCondition1}
                      </li>
                      <li className="text-gray-500">
                        {content.UsernameCondition2}
                      </li>
                      <li className="text-gray-500">
                        {content.UsernameCondition3}
                      </li>
                    </ul>
                  </div>
                </Col>
              </div>
              <Col md={6}>
                <Input
                  name="password"
                  type="password"
                  text={content.Password}
                  value={local.password}
                  title={content.Password}
                  onChange={handleChange}
                  isPassword={true}
                />
              </Col>
              <Col md={6}>
                <Input
                  name="password_confirmation"
                  type="password"
                  text={content.ConfirmPassword}
                  title={content.ConfirmPassword}
                  value={local.password_confirmation}
                  onChange={handleChange}
                  isPassword={true}
                />
              </Col>
              <Col className="text-Main text-xl font-semibold pt-6">
                {content.MaritalStatus}
              </Col>
              {listPage2?.map((e, i) => (
                <Col md={6} key={i}>
                  <Selects
                    name={e.name}
                    text={e.title}
                    value={local?.[e.name]}
                    onChange={handleChangeSelect}
                    options={e.options}
                  />
                </Col>
              ))}

              <Col md={6}>
                <div className="my-2">
                  <div className="text-xl font-semibold text-Secondary border-Main">
                    {content.Age}
                  </div>
                  <Select
                    className="w-full bg-[#E7E7E7] mt-2 rounded-xl text-center text-Secondary font-semibold"
                    placeholder={content.Select}
                    options={listAges}
                    name="age"
                    onChange={handleChangeSelect}
                    value={
                      (local?.age &&
                        listAges
                          ?.filter((e) => `${e.value}` === `${local?.age}`)
                          ?.map((q) => {
                            return { value: q.value, label: q.label };
                          })[0]) ||
                      null
                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="my-2">
                  <div className="text-xl font-semibold text-Secondary border-Main">
                    {content.Weight}
                  </div>
                  <Select
                    className="w-full bg-[#E7E7E7] mt-2 rounded-xl text-center text-Secondary font-semibold"
                    placeholder={content.Select}
                    options={listWeight}
                    name="weight"
                    onChange={handleChangeSelect}
                    value={
                      (local?.weight &&
                        listWeight
                          ?.filter((e) => `${e.value}` === `${local?.weight}`)
                          ?.map((q) => {
                            return { value: q.value, label: q.label };
                          })[0]) ||
                      null
                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="my-2">
                  <div className="text-xl font-semibold text-Secondary border-Main">
                    {content.Height}
                  </div>
                  <Select
                    className="w-full bg-[#E7E7E7] mt-2 rounded-xl text-center text-Secondary font-semibold"
                    placeholder={content.Select}
                    options={listHeight}
                    name="height"
                    onChange={handleChangeSelect}
                    value={
                      (local?.height &&
                        listHeight
                          ?.filter((e) => `${e.value}` === `${local?.height}`)
                          ?.map((q) => {
                            return { value: q.value, label: q.label };
                          })[0]) ||
                      null
                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="my-2">
                  <div className="text-xl font-semibold text-Secondary border-Main">
                    {content.Children}
                  </div>
                  <Select
                    className="w-full bg-[#E7E7E7] mt-2 rounded-xl text-center text-Secondary font-semibold"
                    placeholder={content.Select}
                    options={listChildren}
                    name="children"
                    onChange={handleChangeSelect}
                    value={
                      ((local?.children || local?.children === 0) &&
                        listChildren
                          ?.filter((e) => `${e.value}` === `${local?.children}`)
                          ?.map((q) => {
                            return { value: q.value, label: q.label };
                          })[0]) ||
                      null
                    }
                  />
                </div>
              </Col>
              {/* <SliderMUI
                  title={content.Age}
                  unit={content.years}
                  max={70}
                  min={18}
                  value={local?.age}
                  onChange={handleChange}
                  name="age"
                /> */}
              {/* <Col md={6}>
                <SliderMUI
                  title={content.Weight}
                  unit={content.kg}
                  max={200}
                  min={18}
                  value={local?.weight}
                  onChange={handleChange}
                  name="weight"
                />
              </Col>
              <Col md={6}>
                <SliderMUI
                  title={content.Height}
                  unit={content.cm}
                  max={220}
                  min={100}
                  value={local?.height}
                  onChange={handleChange}
                  name="height"
                />
              </Col>
              <Col md={6} className="mx-auto">
                <SliderMUI
                  title={content.Children}
                  unit=" "
                  max={12}
                  name="children"
                  value={local?.children}
                  onChange={handleChange}
                />
              </Col> */}
            </Row>
            <div className="w-full flex justify-end max-md:justify-center">
              <ButtonMain
                onClick={() => {
                  if (error.length > 0) {
                    setListError([...error]);
                  } else if (errorMessageEmail || typingTimeoutUserName) {
                    return null;
                  } else {
                    navigate("/auth/sign-up/page-3");
                  }
                }}
                className="w-[150px] mt-5"
                title={content.Next}
              />
            </div>
          </Cards>
        </Row>
      </Container>
    </div>
  );
};

export default Page2;

export const useNext = (dataPage, clientSideValidation = null) => {
  const [error, setError] = useState([]);
  useEffect(() => {
    setError([]);
    for (const [key, value] of Object.entries(dataPage)) {
      if (value || value === 0) {
        sessionStorage.setItem(key, value);
      } else if (!clientSideValidation) {
        let arMessage = null;
        switch (key) {
          case "marriage_status_id":
            arMessage = " اختر نوع الزواج ";
            break;
          case "social_status_id":
            arMessage = "اختر الحالة العائلية ";
            break;
          case "password_mismatch":
            arMessage = " كلمات السر غير متطابقة";
            break;
          case "height":
            arMessage = "اختر الطول";
            break;
          case "weight":
            arMessage = "اختر الوزن";
            break;
          case "age":
            arMessage = "اختر العمر";
            break;
          case "username":
            arMessage = " اسم المستخدم مطلوب ";
            break;
          case "email":
            arMessage = " الايميل مطلوب ";
            break;
          case "password":
            arMessage = " كلمة السر مطلوبة ";
            break;
          case "password_confirmation":
            arMessage = "حقل تأكيد كلمة السر مطلوب ";
            break;
          case "nationality_id":
            arMessage = "اختر الجنسية";
            break;
          case "country_id":
            arMessage = "اختر البلد";
            break;
          case "city_id":
            arMessage = "اختر المدينة";
            break;

          case "skin_color_id":
            arMessage = "اختر لون البشرة";
            break;
          case "physique_status_id":
            arMessage = "اختر بنيةالجسم";
            break;
          case "educational_level_id":
            arMessage = "اختر مستوى التعليم ";
            break;

          case "financial_status_id":
            arMessage = "اختر مستوى التعليم ";
            break;

          case "educational_level_id":
            arMessage = "اختر الحالة المالية ";
            break;

          case "job_id":
            arMessage = "اختر مجال العمل  ";
            break;

          case "employment_status_id":
            arMessage = "اختر عنوان الوظيفة  ";
            break;

          case "monthly_income_range_id":
            arMessage = "اختر الدخل الشهري  ";
            break;

          case "health_status_id":
            arMessage = "اختر الحالة الصحية  ";
            break;
          case "smoking_status_id":
            arMessage = "اختر حالة التدخين  ";
            break;
          case "beard_status_id":
            arMessage = "اختر حالة اللحية  ";
            break;
          case "hijab_status_id":
            arMessage = "اختاري حالة الحجاب";
            break;
          case "prayer_status_id":
            arMessage = "اختر حالة الصلاة ";
            break;
          case "religiosity_status_id":
            arMessage = "اختر حالة التدين ";
            break;

          case "own_props":
            arMessage = "يجب ارفاق نبذة عنك";
            break;
          case "future_wife_props":
            arMessage = " يجب إرفاق المواصفات التي تريدها في شريك حياتك";
            break;

          case "name":
            arMessage = " الاسم الكامل مطلوب";
            break;

          case "phone_number":
            arMessage = "رقم الهاتف مطلوب";
            break;
        }
        setError((prevErrors) => [
          ...prevErrors,
          localStorage.getItem("language") == "en"
            ? `The ${key
                .replace("_id", " ")
                .replaceAll("_", " ")} field is required`
            : arMessage,
        ]);
      }
    }
  }, [dataPage]);
  return { error };
};
