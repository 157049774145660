import { content } from "./translate";

export function ageVoid(age) {
  return age + " " + content.years;
}
export function genderVoid(gender) {
  return gender === "MALE" ? "Male" : "Fmale";
}
export function checkIsSearchSelect(name) {
  return name === "nationality_id" ||
    name === "country_id" ||
    name === "city_id"
    ? true
    : false;
}
export function childrenVoid(children) {
  switch (children) {
    case 0:
      return content.NoChildren;
    case 1:
      return content.OneChildren;
    case 2:
      return content.TwoChildren;
    case 3:
      return content.ThreeChildren;
    case 4:
      return content.FourChildren;
    case 5:
      return content.FiveChildren;
    case 6:
      return content.SixChildren;
    case 7:
      return content.SevenChildren;
    case 8:
      return content.EightChildren;
    case 9:
      return content.NineChildren;
    case 10:
      return content.TenChildren;
    case 11:
      return content.ElevenChildren;
    case 12:
      return content.TwelveChildren;
    default:
      return "";
  }
}
// switch (children) {
//   case 0:
//     return "لا يوجد أطفال";
//   case 1:
//     return "طفل واحد";
//   case 2:
//     return "طفلان";
//   case 3:
//     return "ثلاثة أطفال";
//   case 4:
//     return "أربعة أطفال";
//   case 5:
//     return "خمسة أطفال";
//   case 6:
//     return "ستة أطفال";
//   case 7:
//     return "سبعة أطفال";
//   case 8:
//     return "ثمانية أطفال";
//   case 9:
//     return "تسعة أطفال";
//   case 10:
//     return "عشرة أطفال";
//   case 11:
//     return "أحد عشر طفلًا";
//   case 12:
//     return "اثنا عشر طفلًا";
//   default:
//     return "";
// }
