import { useState } from "react";
import { useContextHook } from "../../../Context/ContextOPen";
import { usePOST } from "../../../Tools/APIs";
import { Col, Container, Row } from "../../../Tools/Grid-system";
import { ButtonMain, Cards } from "../../../components";
import Input from "../../../components/Input/Input";
import { useNext } from "../SignUp/Page2";
import { content } from "../../../Context/translate";
function SetPassword() {
  const [password, setPassword] = useState(false);
  const { setListError } = useContextHook();
  const { handleSubmit, handleChangeInput, formData, loading } = usePOST({
    email: sessionStorage.getItem("email"),
    verification_code: sessionStorage.getItem("verification_code"),
    password: "",
    password_confirmation: "",
  });
  const { error } = useNext(formData);
  return (
    <Container>
      <Row>
        <Col md={8} className="mx-auto">
          <Cards isLoading={loading} className="mt-10">
            <Col md={8} className="mx-auto">
              <h1 className="text-2xl font-semibold max-xsm:text-lg">
                {content.NewPassword}
              </h1>
              <Input
                name="password"
                onChange={handleChangeInput}
                type={!password ? "password" : "text"}
                title={content.NewPassword}
              />
              <Input
                name="password_confirmation"
                onChange={handleChangeInput}
                type={!password ? "password" : "text"}
                title={content.ConfirmPassword}
              />
              <div className="text-lg mt-5 flex items-center">
                <input
                  type="checkbox"
                  onClick={() => setPassword(!password)}
                  name="checkbox"
                  className="mx-3 w-fit"
                />
                <span>{content.ShowPassword}</span>
              </div>
              <div className="w-fit mx-auto">
                <ButtonMain
                  onClick={() => {
                    if (error.length > 0) {
                      return setListError([...error]);
                    }
                    handleSubmit("v1/reset-password", "", "/auth/sign-in");
                  }}
                  title={content.ConfirmPassword}
                />
              </div>
            </Col>
          </Cards>
        </Col>
      </Row>
    </Container>
  );
}
export default SetPassword;
