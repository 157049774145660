import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useListPage } from "../../Context/listSignUp";
import { content } from "../../Context/translate";
import { checkToken, gender, language } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import { Cards, Selects, SliderMUI } from "../../components";
import wingImage from "../../images/pngwing.png";

const QuickSearchHome = ({ className }) => {
  const [value, setValue] = useState([18, 50]);
  const { quickSearch } = useListPage(true);
  const { queryString, setDataSearch, dataSearch, handleChangeSelect } =
    useSearch();
  const [searchFor, setSearchFor] = useState(gender || "MALE");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!checkToken) {
      setSearchFor("MALE");
      sessionStorage.setItem("gender", "MALE");
      setDataSearch({
        ...dataSearch,
        gender: gender === "FEMALE" ? "MALE" : "FEMALE",
      });
    }
  }, []);

  useEffect(() => {
    if (!checkToken && gender) {
      if (gender) setSearchFor(gender);
      sessionStorage.setItem("gender", gender);
      setDataSearch({
        ...dataSearch,
        gender: gender === "FEMALE" ? "MALE" : "FEMALE",
      });
    }
  }, [gender]);

  return (
    <div
      className={
        className ? className : `mx-auto w-[85%] sm:-translate-y-56 mb-11`
      }
    >
      <Cards>
        <h1 className="text-2xl max-sm:text-2xl font-bold text-center mb-2 text-Main">
          {content.StartYourIslamicMarriageJourney}
        </h1>
        {checkToken ? (
          ""
        ) : (
          <div className="rounded-xl text-sm text-center overflow-hidden w-fit mx-auto my-4 space-x-3">
            <button
              onClick={() => {
                sessionStorage.setItem("gender", "FEMALE");
                setSearchFor("FEMALE");
                setDataSearch({
                  ...dataSearch,
                  gender: "MALE",
                });
              }}
              className={`${
                searchFor === "FEMALE"
                  ? "text-white bg-Secondary transition-all duration-300 font-semibold text-sm text-center rounded-xl"
                  : "text-Secondary bg-white transition-all duration-300 font-semibold text-sm text-center rounded-xl"
              } p-3`}
            >
              {content.forHusband}
            </button>
            <button
              onClick={() => {
                sessionStorage.setItem("gender", "MALE");
                setSearchFor("MALE");
                setDataSearch({
                  ...dataSearch,
                  gender: "FEMALE",
                });
              }}
              className={`${
                searchFor === "MALE"
                  ? "text-white bg-Main transition-all duration-300 font-semibold text-sm text-center rounded-xl"
                  : "text-Main bg-white transition-all duration-300 font-semibold text-sm text-center rounded-xl"
              } p-3 m-2`}
            >
              {content.forWife}
            </button>
          </div>
        )}
        <Row className="sm:justify-center">
          {quickSearch?.map((e, i) => (
            <Col md={4} xs={6} key={i}>
              <Selects
                name={e.name}
                text={e.title}
                value={dataSearch?.[e.name]}
                onChange={handleChangeSelect}
                options={e.options}
              />
            </Col>
          ))}
          <Col md={4} xs={5} col={11} className="self-start">
            <SliderMUI
              className="text-xl max-md:text-lg font-bold"
              title={content.Age}
              unit={content.years}
              max={70}
              min={18}
              value={value}
              onChange={handleChange}
              name="age"
            />
          </Col>

          <Col md={4} xs={6}>
            <div
              className={
                "h-[180px] flex justify-end max-md:flex max-md:justify-center"
              }
            >
              <img src={wingImage} alt="wing" className="img" />
            </div>
          </Col>
        </Row>
        <div className="flex justify-center">
          <div className="w-1/4 max-sm:w-1/2">
            <Link
              to={
                "/search/users?" +
                queryString +
                (value[0] ? "&age_more_than=" + value[0] : "") +
                (value[1] ? "&age_less_than=" + value[1] : "")
              }
              className="w-full"
            >
              <button className="font-bold px-8 py-2 mx-auto text-white cursor-pointer bg-Main rounded-lg w-full">
                {content.Search}
              </button>
            </Link>
          </div>
        </div>
      </Cards>
    </div>
  );
};

export default QuickSearchHome;

export const useSearch = (initialState) => {
  const [dataSearch, setDataSearch] = useState(initialState);
  const handleChangeSearch = (e) => {
    const { name, value } = e.target;
    setDataSearch({
      ...dataSearch,
      [name]: value,
    });
  };
  const handleChangeSelect = (selected, active) => {
    setDataSearch({
      ...dataSearch,
      [active.name]: Array.isArray(selected)
        ? selected.map((e) => e.value)
        : selected.value,
    });
  };
  var params = new URLSearchParams();
  for (var key in dataSearch) {
    params.append(key, dataSearch[key]);
  }
  var queryString = params.toString();

  return {
    handleChangeSearch,
    queryString,
    dataSearch,
    setDataSearch,
    handleChangeSelect,
  };
};
