import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useContextHook } from "../../../Context/ContextOPen";
import { useLOGIN } from "../../../Tools/APIs";
import { Col, Container, Row } from "../../../Tools/Grid-system";
import { ButtonMain, Cards, Input } from "../../../components";
import Logo from "../../../images/qiranbook6 (1) 1.svg";
import { useNext } from "../SignUp/Page2";
import { content } from "../../../Context/translate";
import Loading from "../../../Tools/Loading";
function Login() {
  const { setListError } = useContextHook();
  const [password, setPassword] = useState(false);
  const { handleSubmit, loading, handleChange, setFormData, formData } =
    useLOGIN({
      username: "",
      password: "",
    });
  const { error } = useNext(formData);

  useEffect(() => {
    if (sessionStorage.getItem("fcm_token")) {
      setFormData({
        ...formData,
        fcm_token: sessionStorage.getItem("fcm_token"),
      });
    }
  }, []);
  const handleSubmitFinal = () => {
    handleSubmit("v1/login");
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmitFinal();
    }
  };

  return (
    <div className=" mb-12 max-md:!h-[80vh]">
      <Container>
        {loading && <Loading />}
        <Row>
          <Col lg={8} md={10} className=" mx-auto">
            <Cards
              isLoading={loading}
              className="mt-10 shadow-sm !bg-slate-200">
              <div className="md:hidden">
                <img src={Logo} alt="" className="w-[200px] mx-auto" />
              </div>
              <Col lg={7} md={10} className="!mx-auto">
                <h2 className="text-2xl text-center font-bold text-[#115894] mx-auto">
                  {content.SignIn}
                </h2>
                <Input
                  text={content.Username}
                  title={content.Username}
                  className="bg-white !text-black lg:w-[80%] mx-auto"
                  name="username"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  SignIn={true}
                />
                <Input
                  className="bg-white !text-black lg:w-[80%] mx-auto"
                  type={!password ? "password" : "text"}
                  text={content.Password}
                  name="password"
                  onChange={handleChange}
                  title={content.Password}
                  onKeyDown={handleKeyDown}
                  SignIn={true}
                />
                <div className="flex items-center justify-around max-md:justify-between mt-6">
                  <div className="md:text-lg text-sm flex items-center">
                    <input
                      type="checkbox"
                      onClick={() => setPassword(!password)}
                      name="checkbox"
                      className="mx-1 w-fit"
                    />
                    <span className="text-gray-700 text-base">
                      {content.ShowPassword}
                    </span>
                  </div>
                  <p className="text-gray-700 hover:underline">
                    <Link to="/auth/forget-password">
                      {content.ForgotPassword}
                    </Link>
                  </p>
                </div>
                <div className="flex justify-center mt-6 text-lg  ">
                  <ButtonMain
                    onClick={() => {
                      if (error.length > 0) {
                        setListError([...error]);
                      } else {
                        handleSubmitFinal();
                      }
                    }}
                    className="w-[150px] !my-3 rounded-[20rem] "
                    title={content.SignIn}
                  />
                </div>

                <p className="text-gray-700 text-center text-xl font-bold   mt-4">
                  {content.AreYouANewMember}
                  <Link
                    to="/auth/sign-up"
                    className="text-xl text-[#DB4A65] font-bold mx-1">
                    {content.CreateAnAccount}
                  </Link>
                </p>
              </Col>
            </Cards>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Login;
