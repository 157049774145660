import React from "react";
import { Col } from "../../Tools/Grid-system";
import UserOperations from "../UserOperations/UserOperations";

const Inbox = ({ data }) => {
  return (
    <Col className="mx-auto">
      <UserOperations
        title="Chats"
        api={"v1/chat/users?page=1"}
        isChat={true}
      />
    </Col>
  );
};

export default Inbox;
