import { Link } from "react-router-dom";
import useRegisterData from "../../Context/registerData";
import { content } from "../../Context/translate";
import { language, useFilter } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import { TitlePage } from "../../components";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "../../Context/exportIcons";
import PopUpQuickSearch from "../../components/PopUps/PopUpQuickSearch";
const NavBarMembers = ({ data, title, category, notFilters, link, isHome }) => {
  const { searchParams, handleParamsClick } = useFilter();
  const { registerData } = useRegisterData(sessionStorage.getItem("gender"));

  return (
    <div>
      <>
        <Row className="items-center" justify="between">
          {isHome ? (
            link ? (
              <>
                <TitlePage
                  col={10}
                  title={title}
                  desc={data?.total}
                  className="text-start text-4xl"
                />
                <Link
                  to={`${link}`}
                  className={`${
                    language === "ar" ? "link-title-ar" : "link-title-en"
                  } flex items-center text-xl self-end max-sm:text-sm font-semibold hover:underline text-center hover:text-Main text-Secondary`}>
                  {content.ViewAll}
                  {language === "ar" ? (
                    <MdOutlineArrowBackIosNew size={17} />
                  ) : (
                    <MdOutlineArrowForwardIos size={17} />
                  )}
                </Link>
              </>
            ) : (
              <TitlePage
                col={10}
                title={title}
                desc={data?.total}
                className="text-start text-4xl"
              />
            )
          ) : (
            <div className="flex justify-between gap-4 !my-4">
              <div className="flex justify-center gap-2">
                <div className="w-1 bg-Main h-full"></div>
                <h1 className="text-3xl max-sm:text-xl font-bold text-Secondary">
                  {content[title]}
                </h1>
              </div>
              <Link
                to={`${link}`}
                className={`${
                  language === "ar" ? "link-title-ar" : "link-title-en"
                } flex items-center text-xl self-end max-sm:text-sm font-semibold hover:underline text-center hover:text-Main text-Secondary`}>
                {content.ViewAll}
                {language === "ar" ? (
                  <MdOutlineArrowBackIosNew size={17} />
                ) : (
                  <MdOutlineArrowForwardIos size={17} />
                )}
              </Link>
            </div>
          )}

          {!notFilters && (
            <Col>
              <Row className="justify-end items-center">
                <Col col={4}>
                  <select
                    name="country_id"
                    value={searchParams.get("country_id")}
                    onChange={(e) => {
                      handleParamsClick("country_id", e.target.value);
                      handleParamsClick("page", 1);
                    }}
                    className="select w-full bg-[#F7F7F7] border text-lg  max-sm:text-sm  p-2 rounded-lg outline-none text-center">
                    <option value="">{content.AllCountries}</option>
                    {registerData?.countries.map((e, i) => (
                      <option value={e.id} key={i}>
                        {e.name}
                      </option>
                    ))}
                  </select>
                </Col>

                {category === "/health-status" && (
                  <Col col={4}>
                    <select
                      name="health_id"
                      value={searchParams.get("health_id")}
                      onChange={(e) =>
                        handleParamsClick("health_id", e.target.value)
                      }
                      className="w-full bg-[#F7F7F7] border text-lg  max-sm:text-sm  py-2 rounded-lg outline-none px-1 text-center">
                      <option value="">{content.AllHealthStatuses}</option>
                      {registerData?.healths_statuses.map((e, i) => (
                        <option value={e.id} key={i}>
                          {e?.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                )}

                <Col col={4}>
                  <PopUpQuickSearch total={data?.total} />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </>
    </div>
  );
};

export default NavBarMembers;
