import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./firebase";
import { useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation, useRoutes } from "react-router-dom";
import {
  BottomSheetChat,
  MessagesError,
  MessagesSign,
  MessagesSuccess,
} from "./components";
import ProfilePopUp from "./components/PopUps/ProfilePopUp";
import { useContextChat } from "./Context/ContextChat";
import { dataPageMembers, dataUserOperations } from "./Context/lists";
import { content } from "./Context/translate";
import AudioFile from "./images/smallmessag_93143d2d5268411.mp3";
import * as layout from "./layout";
import * as page from "./pages";
import { RequireAuth, RequireLogin, checkToken, useFETCH } from "./Tools/APIs";
import * as Error from "./Tools/Error";
import { Col, Container, Row } from "./Tools/Grid-system";
import PopUpImages from "./components/PopUps/PopUpImages";
import { useContextHook } from "./Context/ContextOPen";
import MessageActivate from "./components/Messages/MessageActivate";

const App = () => {
  const {
    setNewMessage,
    setChatDelelete,
    setMessageRead,
    setMessageDelete,
    setLoadingCounter,
  } = useContextChat();
  const { pathname } = useLocation();
  const refAudio = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // const messaging = getMessaging();

  onMessage(messaging, (payload) => {
    if (+pathname.substring(6) === +payload?.data?.user) {
      // return setNewMessage({
      //   is_sent_by_auth_user: false,
      //   content: payload.data.content,
      //   created_at: payload.data.created_at,
      //   receiver_id: payload.data.receiver_id,
      //   sender_id: payload.data.sender_id,
      //   id: payload.data.id,
      //   updated_at: payload.data.created_at,
      // });
    } else if (payload.notification.title === "chat deletion event") {
      return setChatDelelete(payload?.data?.user_id);
    } else if (payload.notification.title === "message deletion event") {
      return setMessageDelete(payload?.data?.message_id);
    } else if (payload.notification.title === "message read event") {
      return setMessageRead(payload?.data?.user_id);
    } else {
      if (refAudio.current) {
        refAudio.current.play();
        setNewMessage({
          reload: payload?.data?.created_at,
        });
      }
    }
  });
  async function requestPermission() {
    const prem = await Notification.requestPermission();
    if (prem === "granted") {
      const fcm_token = await getToken(messaging, {
        vapidKey:
          "BDgmQzQGM34ZCN770v5l4cnti7mmQg8rTYjzbnnatNFHL18bZHqcJrfwLVhOXmPA-umV1mGhZzD2VQMfsk9OQaQ",
      });
      sessionStorage.setItem("fcm_token", fcm_token);
    } else if (prem === "denied") {
    }
  }
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", async () => {
        try {
          const registration = await navigator.serviceWorker.register(
            "/firebase-messaging-sw.js",
            {
              scope: "/firebase-cloud-messaging-push-scope",
            }
          );
          //    console.log("تم تسجيل خدمة العمل بنجاح:", registration);
        } catch (error) {
          //   console.error("فشل تسجيل خدمة العمل:", error);
        }
      });
    } else {
      console.warn("متصفحك لا يدعم خدمات العمل.");
    }
    requestPermission();
  }, []);
  const location = useLocation();
  useEffect(() => {
    const pathsToForceRefresh = ["/profile", "/profile/edit", "/"];
    if (pathsToForceRefresh.includes(location.pathname)) {
      setProfileState(location.pathname);
      refresh.current++;
    }
  }, [location.pathname]);
  const [profileState, setProfileState] = useState("");
  const [notificationRingState, setNotificationRingState] = useState(1);
  const { setIsActive } = useContextChat();

  const refresh = useRef(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setNotificationRingState((prev) => prev + 1);
    }, 8000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);
  const [readNotifications, setReadNotifications] = useState(0);

  const { data, isLoading } = useFETCH(
    checkToken &&
      "v1/profile?ring=" + notificationRingState + `&read=${readNotifications}`,
    // (profileState === "/profile" ? "" : profileState) +
    false,
    false,
    false,
    false,
    refresh.current
  );

  useEffect(() => {
    setIsActive(data?.data?.data?.is_active);
  }, [data?.data?.data?.is_active]);

  useEffect(() => {
    if (
      data?.data?.notifications_count > 0 &&
      localStorage.getItem("notified_on") !=
        data?.data?.notifications?.data[0]?.id
    ) {
      const audio = new Audio(AudioFile);
      audio.play().catch((error) => console.log(error));
      localStorage.setItem(
        "notified_on",
        data?.data?.notifications?.data[0]?.id
      );
    }
    if (data?.data?.notifications_count == 0) {
      setReadNotifications(0);
    }
    setLoadingCounter((prev) => prev + 1);
  }, [data]);

  const prevLocation = useRef(location.pathname);

  useEffect(() => {
    if (
      location.pathname === "/profile/notifications" &&
      prevLocation.current !== "/profile/notifications"
    ) {
      setReadNotifications(1);
    } else if (location.pathname !== "/profile/notifications") {
      setReadNotifications(0);
    }
    prevLocation.current = location.pathname;
  }, [location]);

  const { data: dataCheck } = useFETCH(checkToken && "v1/check/auth");

  const { showBottomSheet, setShowBottomSheet } = useContextHook();

  return (
    <div className="max-md:h-screen flex flex-col max-md:overflow-y-hidden">
      <audio ref={refAudio}>
        <source src={Audio} type="audio/mpeg" />
      </audio>
      <MessagesSuccess />
      <MessagesError />
      <ProfilePopUp />
      <MessagesSign />
      <MessageActivate />
      <BottomSheetChat
        isOpen={showBottomSheet[1]}
        id={showBottomSheet[0]}
        setShowBottomSheet={setShowBottomSheet}
      />
      <PopUpImages />
      <layout.NavBar data={data} />
      <div className="max-md:h-[80vh] flex-1 max-md:overflow-y-scroll">
        <Routes>
          <Route element={<RequireLogin />}>
            {!checkToken && (
              <Route
                path="/"
                element={<page.Home propsData={data} isLoading={isLoading} />}
              />
            )}

            <Route path="admin/sign-in/:token" element={<page.CreateToken />} />
            <Route path="auth">
              <Route path="sign-in" element={<page.Login />} />
              <Route path="sign-up" element={<page.SignUp />}>
                <Route index element={<page.Page1 />} />
                <Route path="page-1" element={<page.Page1 />} />
                <Route path="page-2" element={<page.Page2 />} />
                <Route path="page-3" element={<page.Page3 />} />
                <Route path="page-4" element={<page.Page4 />} />
                <Route path="page-5" element={<page.Page5 />} />
              </Route>
              <Route path="forget-password" element={<page.ForgetPassword />} />
              <Route
                path="verification-code"
                element={<page.VerificationCode />}
              />
              <Route path="set-password" element={<page.SetPassword />} />
            </Route>
          </Route>
        </Routes>
        {(pathname === "/" && !checkToken) ||
        pathname.substring(0, 5) === "/auth" ||
        pathname.includes("/admin/sign-in/") ? (
          ""
        ) : (
          <Container>
            <br />
            <Row className="max-md:h-[90vh]">
              {!checkToken &&
              (pathname === "/" ||
                pathname.substring(0, 5) === "/chat" ||
                pathname.substring(0, 5) === "/auth") ? (
                ""
              ) : (
                <Col md={3} className="max-sm:hidden relative">
                  <layout.SideBarUser data={data} />
                </Col>
              )}
              <Col md={9} className="md:!pl-5">
                <Routes>
                  {checkToken && (
                    <Route
                      path="/"
                      element={
                        <page.Home propsData={data} isLoading={isLoading} />
                      }
                    />
                  )}
                  <Route
                    path="account-activation"
                    element={<page.ActivateAccount />}
                  />
                  <Route element={<RequireAuth />}>
                    <Route path="chat" element={<page.Inbox data={data} />}>
                      {/* <Route index element={<page.DefaultChat />} />
                      <Route path=":id" element={<page.Chats />} /> */}
                      <Route path="err/403" element={<Error.P403 />} />
                    </Route>
                  </Route>
                  <Route path="search" element={<page.Search />}>
                    <Route index element={<page.NameSearch />} />
                    <Route path="advanced" element={<page.AdvancedSearch />} />
                    <Route path="quick" element={<page.QuickSearch />} />
                  </Route>
                  <Route path="search/users" element={<page.SearchUser />} />
                  <Route element={<RequireAuth />}>
                    <Route path="settings" element={<page.Settings />}>
                      <Route index element={<page.GeneralSettings />} />
                      <Route path="chat" element={<page.ChatSettings />} />
                      <Route
                        path="notifications"
                        element={<page.NotificationsSettings />}
                      />
                    </Route>
                    <Route path="user-images" element={<page.UsersImages />}>
                      <Route index element={<page.RoleImages />} />
                      <Route
                        path="members-images"
                        element={<page.MembersImages />}
                      />
                      <Route
                        path="my-images"
                        element={
                          <page.Myphotos
                            id={data?.data?.data?.login_details?.id}
                          />
                        }
                      />
                    </Route>
                    <Route path="profile">
                      <Route
                        index
                        element={
                          <page.PersonalFile
                            data={data}
                            isLoading={isLoading}
                          />
                        }
                      />
                      <Route path="edit" element={<page.UpdateProfile />} />
                      <Route
                        path="notifications"
                        element={
                          <page.Notifications
                            propsData={data?.data}
                            isLoading={isLoading}
                          />
                        }
                      />
                    </Route>
                  </Route>
                </Routes>
                <DataPages />
              </Col>
            </Row>
          </Container>
        )}
      </div>
      <layout.Footer />
    </div>
  );
};

export default App;

const DataPages = () => {
  const pages = [
    { path: "about-us", element: <page.AboutUs name={content.AboutUs} /> },
    {
      path: "site-terms",
      element: <page.AboutUs name={content.SiteTermsAndConditions} />,
    },
    { path: "contact-us", element: <page.ContactUs /> },
    { path: "privacy-policy", element: <page.PrivacyPolicy /> },
    {
      path: "help",
      element: (
        <page.QuestionCategories type="HELP_CENTER" title={content.Help} />
      ),
    },
    {
      path: "help/:sectionId/:id",
      element: <page.SingleQuestion type={content.Help} />,
    },
    {
      path: "faqs",
      element: (
        <page.QuestionCategories type="FAQS" title={content.CommonQuestions} />
      ),
    },
    {
      path: "faqs/:sectionId/:id",
      element: <page.SingleQuestion type={content.FAQ} />,
    },
    { path: "blog", element: <page.Blog /> },
    { path: "blog/:sectionId/:id", element: <page.SingleBlog /> },
  ];
  const [userRoutes, setUserRoutes] = useState([]);
  useEffect(() => {
    setUserRoutes(
      dataUserOperations
        .map((e) => {
          return {
            path: e.path,
            element: (
              <page.UserOperations
                actionApi={e.actionApi}
                api={e.api}
                title={e.title}
              />
            ),
          };
        })
        .concat(
          dataPageMembers.map((e) => {
            return {
              path: e.path,
              element: (
                <page.Members
                  category={e.category}
                  title={e.title}
                  desc={e.desc}
                />
              ),
            };
          })
        )
        .concat(
          pages.map((e) => {
            return {
              path: e.path,
              element: e.element,
            };
          })
        )
    );
  }, []);
  let routes = useRoutes(userRoutes);
  return routes;
};
