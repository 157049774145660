import { useEffect } from "react";
import { useContextHook } from "../../Context/ContextOPen";
const MessagesSuccess = () => {
  const { messagesSuccess, setMessagesSuccess } = useContextHook();
  useEffect(() => {
    if (messagesSuccess) {
      setTimeout(() => {
        setMessagesSuccess("");
      }, 4000);
    } else if (!messagesSuccess) {
      setMessagesSuccess("");
    }
  }, [messagesSuccess]);

  return (
    <div>
      {messagesSuccess.length > 0 && (
        <>
          <div
            onClick={() => {
              setMessagesSuccess("");
            }}
            className={`fixed transition-all right-7 bg-Secondary rounded-xl  py-3 px-7 shadow-lg z-[63] text-lg font-bold  ${
              messagesSuccess.length > 0
                ? "bottom-24  md:bottom-10 "
                : "-bottom-full"
            }`}>
            <div>
              <div>
                <ul className="text-white  px-3">
                  {messagesSuccess?.map((e, i) => (
                    <li key={i} className="flex items-center py-1">
                      <div className="">{e}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MessagesSuccess;
