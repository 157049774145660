import React from "react";
import { useLocation } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs";
import { Col } from "../../Tools/Grid-system";
import DataMapMembers from "./DataMapMembers";
import NavBarMembers from "./NavBarMembers";

const Members = ({
  title,
  desc,
  category,
  notFilters,
  link,
  isHome = true,
}) => {
  const { search, pathname } = useLocation();
  const { data, isLoading } = useFETCH(`v1/users${category}${search}`);

  return pathname === "/online" ||
    pathname === "/new" ||
    pathname === "/active" ||
    pathname === "/health-conditions" ? (
    <Col md={11} className="min-h-[50vh] mx-auto">
      <NavBarMembers
        link={link}
        data={data?.data?.data}
        title={title}
        desc={desc}
        category={category}
        notFilters={notFilters}
        isHome={isHome}
      />
      <DataMapMembers
        data={data?.data?.data}
        isLoading={isLoading}
        category={category}
      />
    </Col>
  ) : data?.data?.data?.data?.length === 0 && !isLoading ? null : (
    <Col md={11} className="min-h-[50vh] mx-auto">
      <NavBarMembers
        link={link}
        data={data?.data?.data}
        title={title}
        desc={desc}
        category={category}
        notFilters={notFilters}
        isHome={isHome}
      />
      <DataMapMembers
        data={data?.data?.data}
        isLoading={isLoading}
        category={category}
      />
    </Col>
  );
};

export default Members;
