import React from "react";
import ButtonMain from "../Buttons/ButtonMain";
import Cards from "../Cards/Cards";
import { content } from "../../Context/translate";

const PopUpNoUser = ({ title, onClickFalse }) => {
  return (
    <>
      <div
        onClick={onClickFalse}
        className="fixed w-full h-full top-0 left-0 bg-black bg-opacity-50 z-[62] "></div>
      <Cards className="!fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-10 z-[63]">
        <h1 className="text-2xl font-bold text-center text-Main ">{title}</h1>
        <div className="flex justify-around gap-5 mt-10 ">
          <ButtonMain
            title={content.ok}
            onClick={onClickFalse}
            className="mx-3 my-2 px-11 bg-blue-500 !text-white !text-lg !font-bold hover:bg-blue-400 cursor-pointer hover:!text-white"
          />
        </div>
      </Cards>
    </>
  );
};

export default PopUpNoUser;
