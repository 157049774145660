import { useEffect, useState } from "react";
import { useFETCH } from "../Tools/APIs";

const useRegisterData = (type, all = false) => {
  const { data, isLoading } = useFETCH(type && `v1/input/lists?type=${type}`);
  const [registerData, setRegisterData] = useState(null);

  useEffect(() => {
    setRegisterData(data?.data?.data);
  }, [data?.data]);

  return { registerData, isLoading };
};

export default useRegisterData;
