import React, { useEffect, useRef, useState } from "react";
import { FaArrowDown } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import { useContextChat } from "../../Context/ContextChat";
import { content } from "../../Context/translate";
import { language, useFETCH } from "../../Tools/APIs";
import MessageRecipient from "./MessageRecipient";
import MessagesBottom from "./MessagesBottom";
import MessageSender from "./MessageSender";
import NavChatBottom from "./NavChatBottom";

const ChatsBottom = ({ id }) => {
  const [filter, setFilter] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const { newMessage, setNewMessage, chatDelelete, setMessageRead } =
    useContextChat();
  const distance = useRef(0);
  const disntanceInPercent = useRef(0);
  const [messages, setMessages] = useState([]);
  const { pathname } = useLocation();

  const scrollContainerRef = useRef(0);
  let scrollHeight = useRef(0);
  let scrollTop = useRef(0);

  let filterSelector = useRef(filter);

  let { data, isLoading, deleteItem, successfulDelete } = useFETCH(
    `v1/chat/users/${id}/messages?page=${filterSelector.current}`,
    `v1/chat/users/${id}/messages`,
    false,
    true,
    true,
    refresh,
    true
  );
  // useEffect(()=>{
  //     const interval = setInterval(() =>{
  //       filterSelctor = 1;
  //        setRefresh((prevRefresh)=> prevRefresh + 1);

  //        clearInterval(interval);
  //       }, 10000)

  // }, [refresh]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    distance.current = disntanceInPercent.current * windowHeight;
    scrollHeight.current = scrollContainerRef.current.scrollHeight;
    scrollTop.current = scrollContainerRef.current.scrollTop;

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth, windowHeight]);

  useEffect(() => {
    setMessages((prevMessages) => {
      if (data?.data?.data?.current_page > 1) {
        data?.data?.data?.data?.toReversed().map((value) => {
          prevMessages.push(value);
        });
        return prevMessages;
      } else {
        const prevMessagesCopy = [...prevMessages];
        const dataCopy = [...(data?.data?.data?.data || [])];
        let index = 0;
        let prevIndex = 0;
        while (
          prevIndex < prevMessagesCopy.length &&
          prevMessagesCopy[prevIndex].id == null
        ) {
          prevIndex++;
        }

        while (prevIndex < 100 && index < dataCopy.length) {
          if (prevMessagesCopy.length === prevIndex) {
            prevMessagesCopy.push(dataCopy[index]);
          } else {
            prevMessagesCopy[prevIndex] = dataCopy[index];
          }

          prevIndex++;
          index++;
        }

        return prevMessagesCopy;
      }
    });
    if (data?.data?.data?.current_page === 1) {
      const interval = setInterval(() => {
        filterSelector.current = 1;
        setRefresh((prevRefresh) => prevRefresh + 1);
        clearInterval(interval);
      }, 5000);
    }

    // });
  }, [data?.data?.data?.data]);

  useEffect(() => {
    setMessages([]);
    setMessageRead("");
    setFilter(1);
  }, [id, pathname]);

  useEffect(() => {
    if (chatDelelete === id) {
      setMessages([]);
      setFilter(1);
    }
  }, [chatDelelete]);

  function updateMessageByKey(key, updatedMessage) {
    setMessages((prevMessages) =>
      prevMessages.map((message) => {
        if (message.key === key) {
          return updatedMessage;
        }
        return message;
      })
    );
  }
  useEffect(() => {
    if (newMessage?.content) {
      if (newMessage.id) {
        updateMessageByKey(newMessage.key, newMessage);
        setNewMessage({});
      } else {
        setMessages((prevMessages) => [newMessage, ...prevMessages]);
      }
      scrollHeight.current = scrollContainerRef.current.scrollHeight;
      scrollTop.current = scrollContainerRef.current.scrollTop;
    }
  }, [newMessage]);

  useEffect(() => {
    if (scrollHeight.current - (distance.current + scrollTop.current) < 1) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
    scrollHeight.current = scrollContainerRef.current.scrollHeight;
    scrollTop.current = scrollContainerRef.current.scrollTop;
  }, [messages]);

  useEffect(() => {
    distance.current = scrollContainerRef.current.scrollHeight;
    scrollHeight.current = scrollContainerRef.current.scrollHeight;
    scrollTop.current = scrollContainerRef.current.scrollTop;
    disntanceInPercent.current = distance.current / windowHeight;
  }, []);
  return (
    <div className="relative">
      <NavChatBottom id={id} isLoading={isLoading} />
      <div
        onScroll={(e) => {
          if (data?.data?.data?.data?.length !== 0) {
            if (e.target.scrollTop === 0) {
              filterSelector.current = filter + 1;
              setFilter(filter + 1);
            }
          }
        }}
        ref={scrollContainerRef}
        className="pb-[12.5vh] pt-3 relative w-full h-[71vh] md:h-[54vh] px-4 space-y-1 chat overflow-y-scroll scroll-smooth bg-slate-200 text-black font-semibold text-opacity-60 text-sm chat-background">
        {isLoading && (
          <div className="flex justify-center items-center">
            <h1 className="text-xl font-bold">{content.Loading}</h1>
          </div>
        )}
        {renderMessages(messages, deleteItem, successfulDelete, isLoading, id)}
        {scrollHeight.current -
          (distance.current + scrollContainerRef.current.scrollTop) >
          100 && (
          <FaArrowDown
            color="white"
            onClick={() => {
              scrollContainerRef.current.scrollTop =
                scrollContainerRef.current.scrollHeight;
            }}
            size={35}
            className={`bg-Main p-2 rounded-full cursor-pointer bottom-16 max-md:bottom-24 fixed z-30 ${
              language === "ar" ? "right-4" : "left-4"
            } `}
          />
        )}
      </div>
      <MessagesBottom id={id} data={data} />
    </div>
  );
};

export default ChatsBottom;

const renderMessages = (
  messages,
  deleteItem,
  successfulDelete,
  isLoading,
  userId
) => {
  let currentDate = null;
  if (messages.length === 0 && !isLoading) {
    return (
      <div className="w-full h-full bg-slate-200 flex justify-center items-center text-xl font-bold  text-black text-opacity-60">
        {content.noMessages}
      </div>
    );
  }
  let index = 0;
  return messages?.toReversed().map((message) => {
    if (currentDate !== new Date(message.created_at).toLocaleDateString()) {
      currentDate = new Date(message.created_at).toLocaleDateString();
      return (
        <div key={message.id} className="pb-1">
          <div className="mx-auto px-2 py-1 my-2 bg-Secondary w-fit rounded-xl text-white font-semibold text-md">
            {currentDate}
          </div>
          {message.is_sent_by_auth_user ? (
            <MessageSender
              message={message}
              deleteMessage={() =>
                deleteItem(
                  message?.id,
                  `v1/chat/users/${userId}/messages/${message?.id}`
                )
              }
              successfulDelete={successfulDelete}
              index={index++}
              id={userId}
            />
          ) : (
            <MessageRecipient message={message} userId={userId} />
          )}
        </div>
      );
    }
    return (
      <div key={message.id} className="pb-1">
        {message.is_sent_by_auth_user ? (
          <MessageSender
            message={message}
            deleteMessage={() =>
              deleteItem(
                message.id,
                `v1/chat/users/${userId}/messages/${message.id}`
              )
            }
            successfulDelete={successfulDelete}
            index={index++}
            id={userId}
          />
        ) : (
          <MessageRecipient message={message} userId={userId} />
        )}
      </div>
    );
  });
};
