import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContextHook } from "../../../Context/ContextOPen";
import { useListPage } from "../../../Context/listSignUp";
import { Col, Container, Row } from "../../../Tools/Grid-system";
import { ButtonMain, Cards, Selects } from "../../../components";
import { useNext } from "./Page2";
import { content } from "../../../Context/translate";
import { checkIsSearchSelect } from "../../../Context/functions";
import Process from "../../../components/Process/Process";

const Page3 = () => {
  const navigate = useNavigate();
  const { listPage3 } = useListPage();
  const [local, setLocal] = useState({
    nationality_id: sessionStorage.getItem("nationality_id") || "",
    country_id: sessionStorage.getItem("country_id") || "",
    city_id: sessionStorage.getItem("city_id") || "",
    skin_color_id: sessionStorage.getItem("skin_color_id") || "",
    physique_status_id: sessionStorage.getItem("physique_status_id") || "",
    educational_level_id: sessionStorage.getItem("educational_level_id") || "",
    financial_status_id: sessionStorage.getItem("financial_status_id") || "",
    job_id: sessionStorage.getItem("job_id") || "",
    employment_status_id: sessionStorage.getItem("employment_status_id") || "",
    monthly_income_range_id:
      sessionStorage.getItem("monthly_income_range_id") || "",
  });
  const { setListError } = useContextHook();
  const { error } = useNext(local);

  const handleChangeSelect = (selected, active) => {
    setLocal((prevLocal) => ({
      ...prevLocal,
      [active.name]: Array.isArray(selected)
        ? selected.map((e) => e.value)
        : selected.value,
    }));
  };
  return (
    <div className="mb-12">
      <Container>
        <Row className="items-center">
          <Col md={8} className="mx-auto">
            <Col md={8} className="mx-auto !py-6">
              <p
                className={`${
                  sessionStorage.getItem("gender") === "FEMALE"
                    ? "text-Forth"
                    : "text-Fifth"
                } mx-auto text-center text-3xl font-bold`}
              >
                {sessionStorage.getItem("gender") === "FEMALE"
                  ? content.Registerawife
                  : content.Registerapair}
              </p>
            </Col>
            <Process />
            <Cards className="mt-10">
              <Row>
                {listPage3?.map((e) => (
                  <>
                    {e.text ? (
                      <Col className="text-Main text-2xl font-semibold pt-6">
                        {e.text}
                      </Col>
                    ) : (
                      <Col md={6}>
                        <Selects
                          name={e.name}
                          text={e.title}
                          value={local?.[e.name]}
                          onChange={handleChangeSelect}
                          options={e.options}
                        />
                      </Col>
                    )}
                  </>
                ))}
                <div className="flex justify-between !mt-5">
                  <ButtonMain
                    className="w-[150px] bg-Secondary"
                    title={content.Previous}
                    onClick={() => window.history.go(-1)}
                  />
                  <ButtonMain
                    onClick={() => {
                      if (error.length > 0) {
                        setListError([...error]);
                      } else {
                        navigate("/auth/sign-up/page-4");
                      }
                    }}
                    className="w-[150px]"
                    title={content.Next}
                  />
                </div>
              </Row>
            </Cards>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Page3;
