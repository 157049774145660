import { useNavigate } from "react-router-dom";
import { content } from "../../Context/translate";
import { useFilter } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import { ButtonMain, Cards, Input } from "../../components";
import { Ads } from "../../layout/SideBarUser/SideBarUser";

const NameSearch = () => {
  const navigate = useNavigate();
  const { handleParamsClick, searchParams } = useFilter();
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      navigate(`/search/users?search=${searchParams.get("search")}`);
    }
  };

  return (
    <div className="py-5 flex flex-col items-center">
      <>
        <Row justify="center" className="w-[80%] mx-auto">
          <Col>
            <Cards className="px-10 items-center flex flex-col">
              <Input
                text={content.Username}
                title={content.Username}
                onChange={(e) => handleParamsClick("search", e.target.value)}
                value={searchParams.get("search")}
                className="w-[70%] max-sm:w-[90%]"
                containerClass="flex flex-col items-center"
                onKeyDown={handleKeyDown}
              />
              <ButtonMain
                link={`/search/users?search=${searchParams.get("search")}`}
                title={content.Search}
                className="bg-Main text-white mb-5 mt-8 w-1/4 max-sm:w-1/2"
              />
            </Cards>
          </Col>
        </Row>
        <Ads posistion="quick_username_search" className="w-full h-[60vh]" />
      </>
    </div>
  );
};

export default NameSearch;
