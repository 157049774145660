import { useEffect, useState } from "react";
import useItemsData from "./itemsData";
import useRegisterData from "./registerData";
import { content } from "./translate";
import { useLocation } from "react-router-dom";

export const useListPage = (genderState = false, data, data2) => {
  const gender = sessionStorage.getItem("gender") === "FEMALE";
  const { registerData } = useRegisterData(
    genderState
      ? gender
        ? "MALE"
        : "FEMALE"
      : sessionStorage.getItem("gender")
  );
  const location = useLocation();

  const { itemsData: cityData } = useItemsData(
    "cities",
    sessionStorage.getItem("country_id")
  );
  const { itemsData: jobData } = useItemsData(
    "jobs",
    sessionStorage.getItem("employment_status_id")
  );
  const listAges = [];
  const listWeight = [];
  const listChildren = [];
  const listHeight = [];
  for (let i = 18; i < 71; i++) {
    listAges.push({ value: i, label: `${i}` });
  }
  for (let i = 18; i < 201; i++) {
    listWeight.push({ value: `${i}.00`, label: `${i}` });
  }
  for (let i = 0; i < 13; i++) {
    listChildren.push({ value: i, label: `${i}` });
  }
  for (let i = 100; i < 221; i++) {
    listHeight.push({ value: `${i}.00`, label: `${i}` });
  }
  const listPage2 = [
    {
      title: content.TypeOfMarriage,
      name: "marriage_status_id",
      options: registerData?.marriage_statuses,
    },
    {
      title: content.FamilyStatus,
      name: "social_status_id",
      options: registerData?.socials_statuses,
    },
  ];

  const listPage3 = [
    {
      title: content.Nationality,
      name: "nationality_id",
      options: registerData?.nationalities,
    },
    {
      title: content.Country,
      name: "country_id",
      options: registerData?.countries,
    },
    {
      title: content.City,
      name: "city_id",
      options: cityData,
    },
    { text: content.YourSpecifications },
    {
      title: content.SkinColor,
      name: "skin_color_id",
      options: registerData?.skin_colors,
    },
    {
      title: content.BodyStructure,
      name: "physique_status_id",
      options: registerData?.physiques_statuses,
    },
    { text: content.StudyAndWork },
    {
      title: content.EducationLevel,
      name: "educational_level_id",
      options: registerData?.educational_levels,
    },
    {
      title: content.FinancialStatus,
      name: "financial_status_id",
      options: registerData?.financial_statuses,
    },
    {
      title: content.ScopeOfWork,
      name: "employment_status_id",
      options: registerData?.employment_statuses,
    },
    {
      title: content.JobTital,
      name: "job_id",
      options: jobData,
    },
    {
      title: content.MonthlyIncome,
      name: "monthly_income_range_id",
      options: registerData?.monthly_income_ranges,
    },
  ];
  const [listPage4, setListPage4] = useState([]);
  useEffect(() => {
    setListPage4([
      {
        title: content.HealthStatus,
        name: "health_status_id",
        options: registerData?.healths_statuses,
      },
      {
        title: content.Smoking,
        name: "smoking_status_id",
        options: registerData?.smoking_statuses,
      },
      {
        title: gender ? content.Hijab : content.Beard,
        name: gender ? "hijab_status_id" : "beard_status_id",
        options: gender
          ? registerData?.hijab_statuses
          : registerData?.beard_statuses,
      },
      {
        title: content.Prayer,
        name: "prayer_status_id",
        options: registerData?.prayer_statuses,
      },
      {
        title: content.Religiosity,
        name: "religiosity_status_id",
        options: registerData?.religiosity_statuses,
      },
    ]);
  }, [gender, registerData, sessionStorage.getItem("gender")]);

  const updateProfile = [
    {
      title: content.TypeOfMarriage,
      name: "marriage_status_id",
      options: registerData?.marriage_statuses,
    },
    {
      title: content.FamilyStatus,
      name: "social_status_id",
      options: registerData?.socials_statuses,
    },
    {
      title: content.Nationality,
      name: "nationality_id",
      options: registerData?.nationalities,
    },
    {
      title: content.Country,
      name: "country_id",
      options: registerData?.countries,
    },
    {
      title: content.City,
      name: "city_id",
      options: cityData || registerData?.cities,
    },
    {
      title: content.SkinColor,
      name: "skin_color_id",
      options: registerData?.skin_colors,
    },
    {
      title: content.BodyStructure,
      name: "physique_status_id",
      options: registerData?.physiques_statuses,
    },
    {
      title: content.EducationLevel,
      name: "educational_level_id",
      options: registerData?.educational_levels,
    },
    {
      title: content.FinancialStatus,
      name: "financial_status_id",
      options: registerData?.financial_statuses,
    },
    {
      title: content.ScopeOfWork,
      name: "employment_status_id",
      options: registerData?.employment_statuses,
    },
    {
      title: content.JobTital,
      name: "job_id",
      options: jobData || registerData?.jobs,
    },
    {
      title: content.MonthlyIncome,
      name: "monthly_income_range_id",
      options: registerData?.monthly_income_ranges,
    },
    {
      title: content.HealthStatus,
      name: "health_status_id",
      options: registerData?.healths_statuses,
    },
    {
      title: content.Smoking,
      name: "smoking_status_id",
      options: registerData?.smoking_statuses,
    },
    {
      title: gender ? content.Hijab : content.Beard,
      name: gender ? "hijab_status_id" : "beard_status_id",
      options: gender
        ? registerData?.hijab_statuses
        : registerData?.beard_statuses,
    },
    {
      title: content.Prayer,
      name: "prayer_status_id",
      options: registerData?.prayer_statuses,
    },
    {
      title: content.Religiosity,
      name: "religiosity_status_id",
      options: registerData?.religiosity_statuses,
    },
  ];

  const [advancedSearch, setAdvancedSearch] = useState([]);
  useEffect(() => {
    setAdvancedSearch([
      {
        title: content.TypeOfMarriage,
        name: "marriage_status_id",
        options: registerData?.marriage_statuses,
      },
      {
        title: content.FamilyStatus,
        name: "social_status_id",
        options: registerData?.socials_statuses,
      },
      {
        title: content.Nationality,
        name: "nationality_id",
        options: registerData?.nationalities,
      },
      {
        title: content.Country,
        name: "country_id",
        options: registerData?.countries,
      },
      {
        title: content.City,
        name: "city_id",
        options: data,
      },
      {
        title: content.SkinColor,
        name: "skin_color_id",
        options: registerData?.skin_colors,
      },
      {
        title: content.BodyStructure,
        name: "physique_status_id",
        options: registerData?.physiques_statuses,
      },
      {
        title: content.EducationLevel,
        name: "educational_level_id",
        options: registerData?.educational_levels,
      },
      {
        title: content.FinancialStatus,
        name: "financial_status_id",
        options: registerData?.financial_statuses,
      },
      {
        title: content.ScopeOfWork,
        name: "employment_status_id",
        options: registerData?.employment_statuses,
      },
      {
        title: content.JobTital,
        name: "job_id",
        options: data2,
      },
      {
        title: content.MonthlyIncome,
        name: "monthly_income_range_id",
        options: registerData?.monthly_income_ranges,
      },
      {
        title: content.HealthStatus,
        name: "health_status_id",
        options: registerData?.healths_statuses,
      },
      {
        title: content.Smoking,
        name: "smoking_status_id",
        options: registerData?.smoking_statuses,
      },
      {
        title: !gender ? content.Hijab : content.Beard,
        name: !gender ? "hijab_status_id" : "beard_status_id",
        options: !gender
          ? registerData?.hijab_statuses
          : registerData?.beard_statuses,
      },
      {
        title: content.Prayer,
        name: "prayer_status_id",
        options: registerData?.prayer_statuses,
      },
      {
        title: content.Religiosity,
        name: "religiosity_status_id",
        options: registerData?.religiosity_statuses,
      },
    ]);
  }, [data, data2, registerData]);
  const quickSearch = [
    {
      title: content.Nationality,
      name: "nationality_id",
      options: registerData?.nationalities,
    },
    {
      title: content.Country,
      name: "country_id",
      options: registerData?.countries,
    },
    {
      title: content.FamilyStatus,
      name: "social_status_id",
      options: registerData?.socials_statuses,
    },
    {
      title: content.SortBy,
      name: "sort_by",
      options: [
        { id: "age", name: content.Age },
        { id: "last_active_at", name: content.LastSeen },
        { id: "created_at", name: content.DateOfRegistration },
      ],
    },
  ];
  return {
    listAges,
    listChildren,
    listWeight,
    listHeight,
    listPage2,
    listPage3,
    listPage4,
    updateProfile,
    advancedSearch,
    quickSearch,
  };
};
