import React from "react";
import { Link, NavLink } from "react-router-dom";

const IconeCircle = ({ Icone, link }) => {
  return (
    <NavLink to={link || ""}>
      <Icone
        size={25}
        className="hover:scale-125 transition-all hover:text-Main"
      />
    </NavLink>
  );
};

export default IconeCircle;
