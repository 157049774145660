import { useContextHook } from "../../Context/ContextOPen";
import { checkIsSearchSelect } from "../../Context/functions";
import useRegisterData from "../../Context/registerData";
import { content } from "../../Context/translate";
import { gender, usePOST } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import {
  ButtonMain,
  Cards,
  Input,
  Promotion,
  Selects,
  TextArea,
} from "../../components";
import { useNext } from "../Auth/SignUp/Page2";
function ContactUs() {
  const { registerData } = useRegisterData(sessionStorage.getItem("gender"));
 
  const { setListError } = useContextHook();
  const {
    handleSubmit,
    loading,
    handleChangeInput,
    formData,
    handleChangeSelect,
  } = usePOST({
    country_id: "",
    name: "",
    email: "",
    phone_number: "",
    title: "",
    message: "",
  });
  const { error } = useNext(formData);
  const handleSubmitFinal = () => {
    handleSubmit("v1/support/messages", "", "/");
  };

  if (loading) return <Loading />;
  return (
    <div>
      <Row className="mb-12 mt-12">
        <Col md={11} className="mx-auto ">
          <h1 className="text-3xl font-semibold text-center text-Main">
            {" "}
            {content.ContactUs}{" "}
          </h1>
        </Col>
      </Row>
      <Row>
        <Col md={11} className="mx-auto">
          <Cards>
            <Row>
              <Col md={6} className="mx-auto">
                <Input
                  onChange={handleChangeInput}
                  title={content.Name}
                  text={content.Name}
                  name="name"
                />
              </Col>
              <Col md={6} className="mx-auto">
                <Input
                  onChange={handleChangeInput}
                  title={content.Email}
                  text={content.Email}
                  name="email"
                />
              </Col>
              <Col md={6} className="mx-auto">
                <Selects
                  isSearch={checkIsSearchSelect("country_id")}
                  onChange={handleChangeSelect}
                  name="country_id"
                  title={content.Country}
                  options={registerData?.countries}
                  value={formData?.option_country_id?.value
                  }
                />
              </Col>
              <Col md={6} className="mx-auto">
                <Input
                  name="phone_number"
                  onChange={handleChangeInput}
                  title={content.PhoneNumber}
                  text={content.PhoneNumber}
                />
              </Col>
              <Col md={11} className="mx-auto">
                <div className="bg-[#CCE5FF] rounded-lg px-5 py-7 font-semibold my-5">
                  {content.WeWillRespond}
                </div>
              </Col>
              <Col className="mx-auto">
                <Input
                  text={content.MessageSubject}
                  name="title"
                  onChange={handleChangeInput}
                />
              </Col>
              <Col className="mx-auto">
                <TextArea
                  name="message"
                  onChange={handleChangeInput}
                  text={content.MessageContent}
                />
              </Col>

              <div className="flex justify-center">
                <ButtonMain
                  onClick={() => {
                    if (error.length > 0) {
                      setListError([...error]);
                    } else {
                      handleSubmitFinal();
                    }
                  }}
                  className="w-[150px] !my-3"
                  title={content.Send}
                />
              </div>
            </Row>
          </Cards>
        </Col>
      </Row>

      <Promotion />
    </div>
  );
}
export default ContactUs;
